<mat-card>
  <mat-card-header>
    <div class="flex-row justify-space-between full-width">
      <h3>Causable Admins</h3>
      <button mat-raised-button
              data-testid="add-causable-admin-button"
              color="primary"
              (click)="onInviteUserClick()"
              matTooltip="Invite New causable Admin"
              matTooltipShowDelay="500">Add User
        <mat-icon>add_circle</mat-icon>
      </button>
    </div>

  </mat-card-header>
  <mat-card-content>
    @if (uiState == uiStateForTemplate.ShowLoading) {
      <mat-spinner class="center-margin"></mat-spinner>
    } @else {
    <ag-grid-angular
      data-testid="causable-admin-user-table"
      id="causableAdminGrid"
      class="ag-theme-quartz margin-top-med margin-bottom-med"
      [tooltipShowDelay]=0
      domLayout='autoHeight'
      [rowHeight]="50"
      rowClass="table-row"
      (firstDataRendered)="onFirstDataRendered($event)"
      (gridSizeChanged)="gridSizeChanged($event)"
      overlayNoRowsTemplate="<span>No causable admins</span>"
      [rowData]="causableAdminUsers"
      [columnDefs]="causableAdminColDefs">
    </ag-grid-angular>
    }
  </mat-card-content>
</mat-card>
