<mat-card>
  <mat-card-header>
    <mat-card-title>Charity Date Range Reporting</mat-card-title>
    <mat-card-subtitle>Choose a date range for your report. Only games with a draw date within provided date
      range
      will be considered in the report
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>

    <form [formGroup]="charityDatesFromGroup" class="mt-3">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" formControlName="startDate"
                 data-testid="charity-date-range-report-start-date-input">
          <input matEndDate placeholder="End date" formControlName="endDate"
                 data-testid="charity-date-range-report-end-date-input">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"
                               data-testid="charity-date-range-report-datepicker-toggle"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </form>
    <div class="text-end">
      <button mat-raised-button (click)="onGenerateDateRangeReport()" class="margin-top-med"
              data-testid="charity-date-range-report-generate-button">Generate Reports
      </button>
    </div>

    @switch (uiState) {
      @case (uiStateForTemplate.ShowData) {
        @if (charityDateRangeReport) {
          <div class="margin-top-large">
            <div>
              <div class="report-label">Number of Supporters</div>
              <div
                class="report-value"
                data-testid="charity-date-range-total-supporters">{{ charityDateRangeReport.NumberOfSupporters }}
              </div>
            </div>

            <div>
              <div class="report-label">Total autoplay sales</div>
              <div
                class="report-value"
                data-testid="charity-date-range-autoplay-sales">{{ charityDateRangeReport.AutoplaySales | formatCurrency }}
              </div>
            </div>

            <div>
              <div class="report-label">Total manual sales</div>
              <div
                class="report-value"
                data-testid="charity-date-range-manual-sales">{{ charityDateRangeReport.ManualSales | formatCurrency }}
              </div>
            </div>
            <div>
              <div class="report-label">Total sales</div>
              <div
                class="report-value"
                data-testid="charity-date-range-total-sales">{{ charityDateRangeReport.TotalAmountRaised | formatCurrency }}
              </div>
            </div>

            <hr>
            <div class="report-label">Purchase Size Breakdown
              <mat-icon
                class="info-icon"
                matTooltipPosition="right"
                matTooltip="This is a breakdown of the number of purchases for each specified pricing tier.">
                info_outline
              </mat-icon>
            </div>
            <div>
              <div class="report-label">Purchase amount</div>
              <div
                class="report-label float-right">Number of Purchases
              </div>
            </div>

            @for (breakdown of charityDateRangeReport.PurchaseSizeBreakdown; track breakdown) {
              <div>
                <div class="report-label purchase-amount-for-breakdown">{{ breakdown.PurchaseAmount | formatCurrency }}
                </div>
                <div class="report-value number-of-purchases-for-breakdown">{{ breakdown.NumberOfPurchases }}
                </div>
              </div>
            }
          </div>

        }
      }
      @case (uiState == uiStateForTemplate.ShowLoading) {
        <mat-spinner class="center-margin"></mat-spinner>
      }
    }
  </mat-card-content>
</mat-card>
