import {inject} from "@angular/core";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {OidcService} from "../services/oidc.service";
import {ActiveUserService} from "../services/active-user.service";
import {PermissionsService} from "../services/permissions.service";
import {PermissionTypes} from "../enum/PermissionTypes";

export const CharityAdminGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const activeUserService = inject(ActiveUserService);
  const permissionsService = inject(PermissionsService);
  const oidcService = inject(OidcService);
  const router = inject(Router);

  const charityIdFromRoute = route.paramMap.get('charityId');
  const is_authenticated = oidcService.isAuthenticated();

  if (!is_authenticated) {
    return router.navigateByUrl('auth/login');
  }

  let activeUser = activeUserService.activeUser();
  let isCharityAdmin = false;
  let userHasGameCharityPermission =  false;

  if (activeUser?.Id) {
    userHasGameCharityPermission = permissionsService.userHasPermission(PermissionTypes.ViewCharities);
    if (activeUser.Charities?.length > 0) {
      isCharityAdmin = !!activeUser.Charities.find((charityP) => {
        return charityP.Id === charityIdFromRoute;
      })
    }

    if (!isCharityAdmin && !userHasGameCharityPermission) {
      router.navigateByUrl('not-authorized');
      return false;
    }
  }

  return isCharityAdmin || userHasGameCharityPermission;
};
