import {Component, OnInit} from '@angular/core';
import {IRoleWithPermissions} from "../../../interfaces/IRoleWithPermissions";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {IPermission} from "../../../interfaces/IPermission";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {PermissionsService} from "../../../services/permissions.service";
import {AdminUserTables} from "../../../table-definitions/admin-user-tables";
import {SnackbarService} from "../../../services/snackbar.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogFunctionService} from "../../../services/dialog-function.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {GameService} from "../../../services/game.service";
import {concatMap, forkJoin, tap} from "rxjs";
import {AddNewRoleComponent} from "../../../components/dialogs/add-new-role/add-new-role.component";
import {
  AddPermissionsToRoleComponent
} from "../../../components/dialogs/add-permissions-to-role/add-permissions-to-role.component";
import {IRemovePermissionFromRole} from "../../../interfaces/IRemovePermissionFromRole";
import {IDialogGenericData} from "../../../interfaces/IDialogGenericData";
import {
  ConfirmationActionDialogComponent
} from "../../../components/dialogs/confirmation-action-dialog/confirmation-action-dialog.component";
import {IUpdateRole} from "../../../interfaces/IUpdateRole";
import {FirstDataRenderedEvent, GridSizeChangedEvent} from "ag-grid-community";
import {AgGridAngular} from "ag-grid-angular";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {MatButton, MatIconButton} from "@angular/material/button";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow, MatRowDef, MatTable
} from "@angular/material/table";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatMenu, MatMenuContent, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {MatTooltip} from "@angular/material/tooltip";
import {ContainerComponent} from "@coreui/angular";

@Component({
  selector: 'app-manage-global-permissions',
  standalone: true,
  imports: [
    AgGridAngular,
    HasPermissionDirective,
    MatButton,
    MatCell,
    MatCellDef,
    MatCheckbox,
    MatColumnDef,
    MatError,
    MatFormField,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatMenu,
    MatMenuContent,
    MatMenuItem,
    MatProgressSpinner,
    MatRow,
    MatRowDef,
    MatTab,
    MatTabGroup,
    MatTable,
    MatTooltip,
    ReactiveFormsModule,
    MatMenuTrigger,
    MatHeaderCellDef,
    ContainerComponent
  ],
  templateUrl: './manage-global-permissions.component.html',
  styleUrl: './manage-global-permissions.component.css'
})
export class ManageGlobalPermissionsComponent implements OnInit {
  public rolesWithPermissions: IRoleWithPermissions[] = [];
  public displayedRolesColumns: string[] = ['roleName', 'description', 'active', 'edit'];
  public displayedPermissionsPerRoleColumns: string[] = ['value', 'name', 'active', 'remove'];
  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public uiStateEnumForTemplate = UIStateEnum;
  public chosenRole: IRoleWithPermissions | undefined;
  public allAvailablePermissions: IPermission[] = [];
  public roleNameControl: FormControl<string | null> = new FormControl<string>('', Validators.required);
  public descriptionControl: FormControl<string | null> = new FormControl<string>('', Validators.required);
  public isCrossGameRoleControl: FormControl<boolean | null> = new FormControl<boolean>(false);
  public userHasEditPermission = this.permissionsService.userHasPermission(PermissionTypes.ModifyRolesAndPermissions);
  public permissionTypes = PermissionTypes;
  public genericAdminColDefs = this.adminUserTables.genericAdminColDefs;

  public roleManagementSettingsFormGroup: FormGroup = new FormGroup<any>({
    roleName: this.roleNameControl,
    description: this.descriptionControl,
    isCrossGameRole: this.isCrossGameRoleControl
  })

  constructor(private permissionsService: PermissionsService,
              private adminUserTables: AdminUserTables,
              private snackBarService: SnackbarService,
              private matDialog: MatDialog,
              private dialogFunctionService: DialogFunctionService,
              private errorHandlingService: ErrorHandlingService) {
  }

  ngOnInit(): void {
    this.fetchRolesAndPermissions().subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }

  resetPermissions() {
    this.chosenRole = undefined;
  }

  private fetchRolesAndPermissions() {
    return forkJoin([
      this.permissionsService.getAllRolesAndPermissions(),
      this.permissionsService.fetchAllAvailablePermissions(),
    ]).pipe(tap(([rolesWithPermissions, allPermissions]) => {
      this.rolesWithPermissions = rolesWithPermissions;
      this.allAvailablePermissions = allPermissions;
      // reset chosen role if it is already set with updated version after role edit
      if (this.chosenRole) {
        this.chosenRole = this.rolesWithPermissions.find((rolesWithPermissionsP) => rolesWithPermissionsP.Role.Id === this.chosenRole?.Role.Id);
      }
    }))
  }

  onAddNewRoleClick() {
    const dialogRef = this.matDialog.open(AddNewRoleComponent, {
      width: '650px'
    });

    dialogRef.afterClosed().pipe(concatMap(() => this.fetchRolesAndPermissions()
    )).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }

  onAddPermissionsToRoleClick() {
    const dialogRef = this.matDialog.open(AddPermissionsToRoleComponent, {
      data: this.chosenRole,
      width: '650px'
    });

    dialogRef.afterClosed().pipe(concatMap(() => this.fetchRolesAndPermissions()
    )).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }

  viewRoleDetailsClick(chosenRoleP: IRoleWithPermissions) {
    this.chosenRole = chosenRoleP;
    this.roleNameControl.setValue(this.chosenRole.Role.Name);
    this.descriptionControl.setValue(this.chosenRole.Role.Description);
    this.isCrossGameRoleControl.setValue(this.chosenRole.Role.IsCrossGameRole);
  }

  onRemovePermissionFromRoleClick(chosenPermissionP: IPermission) {
    this.dialogFunctionService.setCallback(() => {
      let removePermissionCommand: IRemovePermissionFromRole = {
        roleId: this.chosenRole!.Role.Id,
        permissionValue: chosenPermissionP.Value
      };

      return this.permissionsService.removePermissionFromRole(removePermissionCommand)
    });

    this.removePermissionFromRole(chosenPermissionP.Name);
  }

  removePermissionFromRole(permissionNameP: string) {
    let deactivateDialogData: IDialogGenericData = {
      title: 'Confirm Removing Permission from Role',
      message: `Confirm that you wish to remove the ${permissionNameP} permission from the ${this.chosenRole?.Role.Name} role ? `,
      successMessage: 'Successfully removed the Permission',
      dataCyTag: 'confirm-deactivate-numbers'
    };

    const dialogRef = this.matDialog.open(ConfirmationActionDialogComponent, {
      data: deactivateDialogData,
      width: '650px'
    });

    dialogRef.afterClosed().pipe(concatMap(() => this.fetchRolesAndPermissions()
    )).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }

  updateRoleSettings() {
    if (this.roleManagementSettingsFormGroup.valid && this.chosenRole) {
      const updateRoleRequest: IUpdateRole = {
        description: this.descriptionControl.value ?? '',
        name: this.roleNameControl.value ?? '',
        roleId: this.chosenRole?.Role.Id,
        isCrossGameRole: this.isCrossGameRoleControl.value!
      };

      this.uiState = UIStateEnum.ShowLoading;
      this.roleManagementSettingsFormGroup.disable();
      this.permissionsService.updateUserRole(updateRoleRequest)
        .pipe(concatMap(() => this.fetchRolesAndPermissions()))
        .subscribe({
            next: () => {
              this.uiState = UIStateEnum.ShowData;
              this.roleManagementSettingsFormGroup.enable();
              this.snackBarService.openSuccessfulSnackBar('Role has been successfully updated')
            },
            error: (err) => {
              this.uiState = UIStateEnum.ShowData;
              this.roleManagementSettingsFormGroup.enable();
              this.errorHandlingService.displayPageLevelErrorMessage(err);
            }
          }
        )
    }
  }

  gridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

}
