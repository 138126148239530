import { Component } from '@angular/core';
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {MatButton} from "@angular/material/button";
import {MatDialogClose} from "@angular/material/dialog";

@Component({
  selector: 'app-should-hide-players-name-pdf',
  standalone: true,
  imports: [
    DialogBaseComponent,
    MatButton,
    MatDialogClose
  ],
  templateUrl: './should-hide-players-name-pdf.component.html',
  styleUrl: './should-hide-players-name-pdf.component.css'
})
export class ShouldHidePlayersNamePdfComponent {

}
