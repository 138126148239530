<mat-form-field appearance="outline" class="full-width">
  <input
    type="text"
    matInput
    [placeholder]="placeholder"
    [formControl]="myControl"
    [matAutocomplete]="auto" />
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onOptionSelected($event)">
    <mat-option *ngFor="let option of filteredOptions" [value]="option">
      {{ option.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
