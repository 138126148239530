import {Component} from '@angular/core';
import {GameInstanceService} from "../../services/game-instance.service";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {ColDef} from "ag-grid-community";
import {CopyValueComponent} from "../../components/cell-renderers/copy-value/copy-value.component";
import {IComprehensiveGameInstanceData} from "../../interfaces/IComprehensiveGameInstanceData";
import {DatePipe} from "@angular/common";
import {FormatBoolean} from "../../components/cell-renderers/show-played/format-boolean.component";
import {EntityViewRenderer} from "../../components/cell-renderers/entity-view-renderer/entity-view-renderer.component";
import {
  SubscriptionStatusRendererComponent
} from "../../components/cell-renderers/subscription-status-renderer/subscription-status-renderer.component";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IGameInstancePlayerNumberWithPayment} from "../../interfaces/IGameInstancePlayerNumberWithPayment";
import {IApiErrorResponse} from "../../interfaces/error/IApiErrorResponse";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {MatFormFieldModule, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatCard, MatCardContent} from "@angular/material/card";
import {MatTab, MatTabContent, MatTabGroup} from "@angular/material/tabs";
import {PipesModule} from "../../pipes/pipes.module";
import {MatTooltip} from "@angular/material/tooltip";
import {AgGridAngular} from "ag-grid-angular";
import {ContainerComponent} from "@coreui/angular";

@Component({
  selector: 'app-game-instances',
  standalone: true,
  templateUrl: './game-instances.component.html',
  imports: [
    MatFormFieldModule,
    MatLabel,
    FormsModule,
    MatInput,
    MatIconButton,
    MatIcon,
    MatProgressSpinner,
    MatCard,
    MatCardContent,
    MatTabGroup,
    MatTab,
    MatTabContent,
    PipesModule,
    DatePipe,
    ReactiveFormsModule,
    MatTooltip,
    MatButton,
    AgGridAngular,
    ContainerComponent
  ],
  styleUrls: ['./game-instances.component.css']
})
export class GameInstancesComponent {

  public uiStateEnum: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public showNoGameInstanceFound = false;
  public comprehensiveGameInstanceData: IComprehensiveGameInstanceData | null = null;
  public gameInstancePlayerNumbers: IGameInstancePlayerNumberWithPayment[] = [];
  public filteredGameInstancePlayerNumbers: IGameInstancePlayerNumberWithPayment[] = [];
  public gameInstanceId: string = '';
  public playerNumberFilterForm = new FormGroup(
    {
      playerNumberId: new FormControl(''),
      playerPaymentId: new FormControl(''),
      playerId: new FormControl('')
    }
  );
  public gameInstanceNumbersColDefs: ColDef[] = [
    {
      headerName: 'ID',
      field: 'Id',
      resizable: true,
      sortable: true,
      cellRenderer: CopyValueComponent,
      flex: 1,
      autoHeight: true
    },
    {
      headerName: 'Created On',
      resizable: true,
      sortable: true,
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y');
        return convertedDate ? convertedDate : '';
      },
      field: 'CreatedOn',
      wrapText: true,
      flex: 1,
      autoHeight: true
    },
    {
      headerName: 'Player Payment',
      resizable: true,
      cellRenderer: EntityViewRenderer,
      cellRendererParams: {
        type: 'PlayerPayment'
      },
      sortable: true,
      field: 'PlayerPayment.Id',
      flex: 1,
      autoHeight: true
    },
    {
      headerName: 'Player Number',
      resizable: true,
      cellRenderer: EntityViewRenderer,
      sortable: true,
      cellRendererParams: {
        type: 'PlayerNumber'
      },
      field: 'PlayerNumber.Id',
      flex: 1,
      autoHeight: true
    },
    {
      headerName: 'Auto Play Status',
      resizable: true,
      sortable: true,
      field: 'AutoplayStatusId',
      cellRenderer: SubscriptionStatusRendererComponent,
      flex: 1,
      autoHeight: true
    },
    {
      headerName: 'Played',
      resizable: true,
      cellRenderer: FormatBoolean,
      sortable: true,
      field: 'Played',
      width: 80,
      autoHeight: true
    },
    {
      headerName: 'Active',
      resizable: true,
      cellRenderer: FormatBoolean,
      sortable: true,
      field: 'Active',
      width: 80,
      autoHeight: true
    }
  ];

  constructor(private gameInstanceService: GameInstanceService,
              private datePipe: DatePipe,
              private errorHandlingService: ErrorHandlingService) {
  }

  onFormSubmit() {
    let filteredGameInstances = this.gameInstancePlayerNumbers;
    const playerNumberSearch = this.playerNumberFilterForm.value.playerNumberId;
    const playerIdSearch = this.playerNumberFilterForm.value.playerId;
    const paymentIdSearch = this.playerNumberFilterForm.value.playerPaymentId;

    if (playerNumberSearch) {
      filteredGameInstances = filteredGameInstances.filter((gipn) =>
        gipn.PlayerNumber?.Id === playerNumberSearch
      )
    }

    if (playerIdSearch) {
      filteredGameInstances = filteredGameInstances.filter((gipn) =>
        gipn.PlayerNumber?.PlayerId === playerIdSearch || gipn.PlayerPayment?.PlayerId === playerIdSearch
      )
    }

    if (paymentIdSearch) {
      filteredGameInstances = filteredGameInstances.filter((gipn) =>
        gipn.PlayerPayment?.Id === paymentIdSearch
      )
    }

    this.filteredGameInstancePlayerNumbers = filteredGameInstances;
  }

  onResetClick() {
    this.playerNumberFilterForm.reset();
    this.filteredGameInstancePlayerNumbers = this.gameInstancePlayerNumbers;
  }

  findGameInstance() {
    this.comprehensiveGameInstanceData = null;
    this.showNoGameInstanceFound = false;
    this.uiStateEnum = UIStateEnum.ShowLoading;

    let guid_regex = RegExp("^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$");

    if (!this.gameInstanceId.match(guid_regex)) {
      this.uiStateEnum = UIStateEnum.ShowError;
      this.errorHandlingService.displayPageLevelErrorMessage(null, "Please enter a valid GUID");
    }
    this.gameInstanceService.fetchComprehensiveGameInstance(this.gameInstanceId).subscribe({
      next: (dataP: IComprehensiveGameInstanceData) => {
        this.comprehensiveGameInstanceData = dataP;
        this.gameInstancePlayerNumbers = dataP.GameInstancePlayerNumbers;
        this.filteredGameInstancePlayerNumbers = dataP.GameInstancePlayerNumbers;
        this.uiStateEnum = UIStateEnum.ShowData;
      },
      error: (err: IApiErrorResponse) => {
        if (err.Status == 404) {
          this.showNoGameInstanceFound = true;
        }
        this.uiStateEnum = UIStateEnum.ShowData;
      }
    })
  }
}
