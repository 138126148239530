import {Component, Inject, OnInit} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {CharityService} from "../../../services/charity.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ICharityCategory} from "../../../interfaces/charity/ICharityCategory";
import {IMoveCharity} from "../../../interfaces/charity/IMoveCharity";
import {ICharityWithContributionTotals} from "../../../interfaces/charity/ICharityWithContributionTotals";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-move-charity-categories',
  standalone: true,
  templateUrl: './move-charity-categories.component.html',
  imports: [
    DialogBaseComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButton
  ],
  styleUrls: ['./move-charity-categories.component.css']
})
export class MoveCharityCategoriesComponent implements OnInit {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public moveCharityForm = new FormGroup({
    categoryControl: new FormControl<string>('', Validators.required),
  });
  public charityCategories: ICharityCategory[] = [];

  constructor(private charityService: CharityService,
              private snackBarService: SnackbarService,
              private errorHandlingService: ErrorHandlingService,
              private matDialogRef: MatDialogRef<MoveCharityCategoriesComponent>,
              @Inject(MAT_DIALOG_DATA) public charity: ICharityWithContributionTotals
  ) {
  }

  ngOnInit(): void {
    this.charityService.charityCategoriesByGame(this.charity.GameId).subscribe({
      next: (categories) => {
        this.charityCategories = categories.filter((category) => category.Id != this.charity.CharityCategoryId && category.Active);
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err);
      }
    });
  }

  submitNewCategoryChanges() {
    let newCharityCategoryId = this.moveCharityForm.controls.categoryControl.value;
    if (newCharityCategoryId) {
      this.uiState = UIStateEnum.ShowLoading;
      let newCategoryCommand: IMoveCharity = {
        gameId: this.charity.GameId,
        charityId: this.charity.Id,
        newCategoryId: newCharityCategoryId
      };

      this.charityService.moveCharity(newCategoryCommand).subscribe({
        next: () => {
          this.matDialogRef.close(true);
          this.snackBarService.openSuccessfulSnackBar('Successfully Moved Charity to New Category');
        },
        error: (err) => {
          this.uiState = UIStateEnum.ShowData;
          this.errorHandlingService.displayDialogLevelErrorMessage(err, true);
        }
      })
    }

  }

}
