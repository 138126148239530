import {Component, inject, Input} from '@angular/core';
import {PermissionTypes} from "../../enum/PermissionTypes";
import {DatePipe} from "@angular/common";
import {HasPermissionDirective} from "../../directives/permissions/has-permission";
import {
  LotteryLicencesTableComponent
} from "../lottery-licences/lottery-licences-table/lottery-licences-table.component";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {PipesModule} from "../../pipes/pipes.module";
import {TestimonialsTableComponent} from "../testimonials/testimonials-table/testimonials-table.component";
import {LotteryLicenceAddComponent} from "../lottery-licences/lottery-licence-add/lottery-licence-add.component";
import {AddTestimonialComponent} from "../testimonials/add-testimonial/add-testimonial.component";
import {EditGameDescriptionComponent} from "../dialogs/edit-game-description/edit-game-description.component";
import {
  EditGameMoreInformationComponent
} from "../dialogs/edit-game-more-information/edit-game-more-information.component";
import {concatMap, of} from "rxjs";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {AppConfigService} from "../../services/app-config.service";
import {ActiveUserService} from "../../services/active-user.service";
import {MatDialog} from "@angular/material/dialog";
import {GameService} from "../../services/game.service";
import {SnackbarService} from "../../services/snackbar.service";
import {Router} from "@angular/router";
import {
  ColComponent,
  ContainerComponent,
  ListGroupDirective,
  ListGroupItemDirective,
  RowComponent
} from "@coreui/angular";
import {GameSettingsScreenState} from "../../enum/GameSettingsScreenState";
import {MatCard, MatCardContent, MatCardHeader, MatCardTitle} from "@angular/material/card";

@Component({
  selector: 'app-game-settings',
  standalone: true,
  imports: [
    DatePipe,
    HasPermissionDirective,
    LotteryLicencesTableComponent,
    MatButton,
    MatIcon,
    MatTooltip,
    PipesModule,
    TestimonialsTableComponent,
    ContainerComponent,
    RowComponent,
    ColComponent,
    ListGroupDirective,
    ListGroupItemDirective,
    MatCard,
    MatCardHeader,
    MatCardContent,
    MatCardTitle
  ],
  templateUrl: './game-settings.component.html',
  styleUrl: './game-settings.component.scss'
})
export class GameSettingsComponent {
  @Input() activeGame: IGameQueryResult | null = null;

  private appConfigService: AppConfigService = inject(AppConfigService);
  private activeUserService: ActiveUserService = inject(ActiveUserService);
  private snackbarService: SnackbarService = inject(SnackbarService);
  private gameService: GameService = inject(GameService);
  private router: Router = inject(Router);
  private matDialog: MatDialog = inject(MatDialog);

  public cdnRoot = this.appConfigService.cdnRoot();
  public activeUser = this.activeUserService.activeUser();

  public screenState = GameSettingsScreenState.GeneralSettings;
  public screenStateEnum = GameSettingsScreenState;

  protected readonly permissionTypes = PermissionTypes;

  editGameRules() {
    this.router.navigateByUrl(`${this.activeGame?.Id}/edit-game-rules`);
  }

  editHowToPlay() {
    this.router.navigateByUrl(`${this.activeGame?.Id}/edit-how-to-play`)
  }

  openAddLicenceNumber() {
    this.matDialog.open(LotteryLicenceAddComponent, {
      data: this.activeGame,
      width: '650px'
    });
  }

  openAddTestimonial() {
    this.matDialog.open(AddTestimonialComponent, {
      data: this.activeGame,
      width: '650px'
    });
  }

  openEditGameDescriptionDialog() {
    this.matDialog.open(EditGameDescriptionComponent, {
      data: this.activeGame,
      width: '650px',
    }).afterClosed()
      .pipe(concatMap((res) => {
        if (res && this.activeGame) {
          return this.gameService.getGameByGameId(this.activeGame?.Id);
        }
        return of(null);
      })).subscribe({
      next: (res: IGameQueryResult | null) => {
        if (res) {
          this.activeGame = res;
        }
      },
      error: () => {
        this.snackbarService.openErrorSnackBar('Looks like there was an error retrieving your game info. Please try again or contact a system admin.')
      }
    })
  }

  openEditGameMoreInformationDialog() {
    this.matDialog.open(EditGameMoreInformationComponent, {
      data: this.activeGame,
      width: '650px',
    }).afterClosed()
      .pipe(concatMap((res) => {
        if (res && this.activeGame) {
          return this.gameService.getGameByGameId(this.activeGame?.Id);
        }
        return of(null);
      })).subscribe({
      next: (res: IGameQueryResult | null) => {
        if (res) {
          this.activeGame = res;
        }
      },
      error: () => {
        this.snackbarService.openErrorSnackBar('Looks like there was an error retrieving your game info. Please try again or contact a system admin.')
      }
    })
  }
}
