import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PermissionsService} from "../../../services/permissions.service";
import {IPermission} from "../../../interfaces/IPermission";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {IRoleWithPermissions} from "../../../interfaces/IRoleWithPermissions";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {map, Observable, of, startWith} from "rxjs";
import {IAddPermissionsToRole} from "../../../interfaces/IAddPermissionsToRole";
import {SnackbarService} from "../../../services/snackbar.service";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {MatChip, MatChipGrid, MatChipInput, MatChipListbox, MatChipRow} from "@angular/material/chips";
import {MatError, MatFormField, MatFormFieldModule} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from "@angular/material/autocomplete";
import {AsyncPipe} from "@angular/common";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-add-permissions-to-role',
  standalone: true,
  templateUrl: './add-permissions-to-role.component.html',
  imports: [
    DialogBaseComponent,
    MatChipListbox,
    MatChip,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatFormField,
    MatChipGrid,
    MatChipRow,
    MatIcon,
    MatChipInput,
    MatAutocompleteTrigger,
    MatAutocomplete,
    MatOption,
    AsyncPipe,
    MatError,
    MatButton
  ],
  styleUrls: ['./add-permissions-to-role.component.css']
})
export class AddPermissionsToRoleComponent implements OnInit {
  @ViewChild('permissionInput') permissionInput!: ElementRef<HTMLInputElement>;

  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public uiStateEnumForTemplate = UIStateEnum;
  public availablePermissions: IPermission[] = [];
  public filteredAvailablePermissions: Observable<IPermission[] | null> = of(null);
  public permissionsToAdd: IPermission[] = [];
  public newPermissionsFormControl: FormControl<string | null> = new FormControl<string | null>(
    null
  );
  public newPermissionsFormGroup: FormGroup = new FormGroup({
    newPermissions: this.newPermissionsFormControl
  })

  constructor(private permissionService: PermissionsService,
              private errorHandlingService: ErrorHandlingService,
              private snackBarService: SnackbarService,
              @Inject(MAT_DIALOG_DATA) public role: IRoleWithPermissions,
              private matDialogRef: MatDialogRef<AddPermissionsToRoleComponent>) {

    this.filteredAvailablePermissions = this.newPermissionsFormControl.valueChanges.pipe(
      startWith(null),
      map((role: string | null) => {
        return (role ? this._filter(role) : this.availablePermissions.slice());
      }),
    );

  }

  ngOnInit(): void {
    this.permissionService
      .fetchAllAvailablePermissions().subscribe({
        next: (resP) => {
          this.availablePermissions = resP.filter((availablePermission) =>
            !this.role.Permissions.some((existingPermission) => existingPermission.Value === availablePermission.Value))
          this.uiState = UIStateEnum.ShowData;
        },
        error: (err) => {
          this.errorHandlingService.displayDialogLevelErrorMessage(err);
          this.uiState = UIStateEnum.ShowData;
        }
      }
    )
  }

  private _filter(value: any): IPermission[] {
    let filterValue = '';
    if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    } else {
      filterValue = value.Name.toLowerCase();
    }
    return this.availablePermissions.filter(permission => permission.Name.toLowerCase().includes(filterValue));

  }

  remove(role: IPermission): void {
    const index = this.permissionsToAdd.indexOf(role);

    if (index >= 0) {
      this.permissionsToAdd.splice(index, 1);
    }
  }

  add(event: any): void {
    const value = (event.value || '').trim();

    if (value) {
      this.permissionsToAdd.push(value);
    }

    event.chipInput!.clear();
    this.newPermissionsFormControl.setValue(null);
  }

  selected(event: any): void {
    this.permissionsToAdd.push(event.option.value);
    this.permissionInput.nativeElement.value = '';
    this.newPermissionsFormControl.setValue(null);
  }

  public addPermissions() {
    if (this.permissionsToAdd.length === 0) {
      this.newPermissionsFormControl.markAllAsTouched();
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;
    const addPermissionsRequest: IAddPermissionsToRole = {
      permissionValues: this.permissionsToAdd.map((permission) => permission.Value),
      roleId: this.role.Role.Id
    }
    this.permissionService.addPermissionToRole(addPermissionsRequest).subscribe({
      next: () => {
        this.matDialogRef.close(true);
        this.snackBarService.openSuccessfulSnackBar('Successfully added new permissions to role');
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err, true);
      }
    })
  }
}
