<mat-card class="padding-large" data-testid="draw-page-card">
  <h2>Create your new Causable Game</h2>
  <p>to get started you need to provide a name and subdomain for your game. </p>
  <form [formGroup]="initGameForm">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Name</mat-label>
      <input formControlName="name" placeholder="enter name for your game" matInput data-testid="game-name-input">
      <mat-hint>this is the name of your game</mat-hint>
      @if(initGameForm.controls.name.errors && (initGameForm.controls.name.touched || initGameForm.controls.name.dirty)) {
        <mat-error data-testid="game-name-error" >
          @if(initGameForm.controls.name.hasError('required')) {
            game name is required
          }
        </mat-error>
      }
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width margin-top-med">
      <mat-label>Subdomain</mat-label>
      <input formControlName="subdomain" placeholder="enter subdomain for your game" matInput data-testid="game-subdomain-input">
      <mat-hint>this is the subdomain for your game Ex: if your desired url is https://mygame.goldrush.causable.io, the
        subdomain would be "mygame"
      </mat-hint>
      @if(initGameForm.controls.subdomain.errors && (initGameForm.controls.subdomain.touched || initGameForm.controls.subdomain.dirty)) {
        <mat-error data-testid="game-subdomain-error" >
        @if(initGameForm.controls.subdomain.hasError('required')) {
          game subdomain is required
        } @else if(initGameForm.controls.subdomain.hasError('pattern')){
          subdomain cannot contain spaces
        }
        </mat-error>
      }
    </mat-form-field>
    <mat-form-field appearance="outline" class="full-width margin-top-med">
      <mat-label>Country</mat-label>
      <mat-select formControlName="country" data-testid="game-country-select">
        @for (country of (countries); track country) {
          <mat-option [value]="country.Id" data-testid="game-country-option">{{country.Name}}</mat-option>
        }
      </mat-select>
      <mat-hint>this is the country your game is in</mat-hint>
    </mat-form-field>
  </form>
  <div class="text-end margin-top-med">
    <button mat-stroked-button
            data-testid="start-creating-button"
            class="margin-right-med"
            [disabled]="uiState === uiStateEnumForTemplate.ShowLoading"
            color="primary"
            (click)="startGameCreation()">
      @if (uiState === uiStateEnumForTemplate.ShowLoading) {
        <mat-spinner></mat-spinner>
      } @else {
        Start Creating your Game
      }
    </button>
  </div>
</mat-card>

