<c-container class="mt-5">
  <h2>Manage Game Settings
    <button mat-icon-button
            data-testid="open-manage-game-options-menu-button"
            id="charity-menu-button"
            *hasPermission="permissionTypes.CausableAdminOnly"
            [matMenuTriggerFor]="menu">
      <mat-icon matTooltipPosition="right"
                matTooltip="open admin user menu"
      >more_vertical
      </mat-icon>
    </button>
  </h2>

  <app-choose-your-game (gameChosenEvent)="onGameChange($event)"
                        [message]="'Choose a game in order to see the admin users for that game'"></app-choose-your-game>
  @if (chosenGame) {
    <app-game-settings [activeGame]="chosenGame"></app-game-settings>
  }
</c-container>

<mat-menu #menu="matMenu">
  <button mat-menu-item
          (click)="onRecalculateGameClick()"
          id="recalculate-game-totals-button"
          *hasPermission="permissionTypes.RecalculateGameTotals">
    <mat-icon class="dark-grey menu-icon">edit</mat-icon>
    <span class="dark-grey menu-text">Recalculate Game Totals</span>
  </button>

  @if (allowCreationOfAzureResources) {
    <button mat-menu-item
            (click)="onCreateAzureResourcesClick()"
            id="create-azure-resources-button"
            *hasPermission="permissionTypes.CausableAdminOnly">
      <mat-icon class="dark-grey menu-icon">edit</mat-icon>
      <span class="dark-grey menu-text">Create Azure Resources</span>
    </button>
  }
</mat-menu>
