<c-row>
  <c-col>
    <mat-card class="mb-4">
      <mat-card-content>
        <p>{{message}}</p>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Choose Game</mat-label>
          <mat-select [(ngModel)]="chosenGame" (ngModelChange)="onGameChange($event)" data-testid="select-game">
            @for (game of adminGames; track game) {
              <mat-option data-testid="game-option" [value]="game" class="choose-game-option">
                {{ game.ShortName ? game.ShortName : game.Name }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </c-col>
  <c-col>
    @if (gameLogo) {
      <div class="p-3 text-center"
           [style]="chosenGame?.GameThemeTypeId == gameThemeType.Dark ? 'background-color: #222222; border-radius: 4px; height: 150px' : ''">
        <img alt="game logo" style="height: 100%" [src]="gameLogo"/>
      </div>
    }
  </c-col>
</c-row>
