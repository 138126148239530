import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ISendGridEvent} from "../../../interfaces/ISendGridEvent";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {FormatterComponent} from "../../json-formatter/json-formatter.component";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-send-grid-event-metadata',
  standalone: true,
  imports: [
    DialogBaseComponent,
    FormatterComponent,
    DatePipe,
  ],
  templateUrl: './send-grid-event-metadata.component.html',
  styleUrl: './send-grid-event-metadata.component.css'
})
export class SendGridEventMetadataComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public event: ISendGridEvent) {
  }
}
