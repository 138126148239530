<c-container>
  <h1 class="mt-5">Manage Admin Users</h1>
  @if (enableGameSearch) {
    <app-choose-your-game (gameChosenEvent)="onGameChange($event)"
                          [message]="'Choose a game in order to see the admin users for that game'"></app-choose-your-game>
  }

  @if (chosenGame) {
    <c-row>
      <c-col md="3">
        <div cListGroup>
          <button cListGroupItem
                  data-testid="view-game-admins-info-tab"
                  *hasCrossGamePermission="permissionTypes.ViewAdminUsers"
                  (click)="manageUsersScreenUIState = manageUsersScreenUIStateForTemplate.GameAdmins"
                  [active]="manageUsersScreenUIState === manageUsersScreenUIStateForTemplate.GameAdmins">
            <div>
              <h5 class="mb-1">View Game Admins</h5>
              <p>here are the admins that have access to specified games</p>
            </div>
          </button>

          <button cListGroupItem
                  *userHasCausableAdminAccess
                  data-testid="view-causable-admins-info-tab"
                  (click)="manageUsersScreenUIState = manageUsersScreenUIStateForTemplate.CausableAdmins"
                  [active]="manageUsersScreenUIState === manageUsersScreenUIStateForTemplate.CausableAdmins">
            <div>
              <h5 class="mb-1">View Causable Admins</h5>
              <p>here are the highest level of admins. Those with permissions to do everything in the app</p>
            </div>
          </button>

          <button cListGroupItem
                  *userHasCausableAdminAccess
                  data-testid="view-cross-game-admins-info-tab"
                  (click)="manageUsersScreenUIState = manageUsersScreenUIStateForTemplate.CrossGameAdmins"
                  [active]="manageUsersScreenUIState === manageUsersScreenUIStateForTemplate.CrossGameAdmins">
            <div>
              <h5 class="mb-1">View Cross Game Admins</h5>
              <p>here are admins that can be given roles that span across all games</p>
            </div>
          </button>

          <button cListGroupItem
                  *hasCrossGamePermission="permissionTypes.ViewAdminUsers"
                  data-testid="view-charity-admins-info-tab"
                  (click)="manageUsersScreenUIState = manageUsersScreenUIStateForTemplate.CharityAdmins"
                  [active]="manageUsersScreenUIState === manageUsersScreenUIStateForTemplate.CharityAdmins">
            <div>
              <h5 class="mb-1">View Charity Admins</h5>
              <p>here are charity admins for the chosen game</p>
            </div>
          </button>

        </div>
      </c-col>

      <c-col>
        @switch (manageUsersScreenUIState) {

          @case (manageUsersScreenUIStateForTemplate.GameAdmins) {
            <app-game-admin-users-view [gameSetter]="chosenGame"></app-game-admin-users-view>
          }
          @case (manageUsersScreenUIStateForTemplate.CausableAdmins) {
            <app-causable-admin-user-view [gameSetter]="chosenGame"></app-causable-admin-user-view>
          }
          @case (manageUsersScreenUIStateForTemplate.CrossGameAdmins) {
            <app-cross-game-admin-users-view [gameSetter]="chosenGame"></app-cross-game-admin-users-view>
          }
          @case (manageUsersScreenUIStateForTemplate.CharityAdmins) {
            <app-charity-admin-users-view [gameSetter]="chosenGame"></app-charity-admin-users-view>
          }
        }
      </c-col>
    </c-row>
  }
</c-container>
