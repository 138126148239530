<c-container class="mt-5">
  <h2>SendGrid Events</h2>
  @if (uiState === uiStateForTemplate.ShowData) {
    <div>
      <h3>Filter</h3>
      <form [formGroup]="sendGridEventFilterFormGroup">
        <div class="flex-row-wrap justify-space-between">
          <mat-form-field appearance="outline" class="flex-50">
            <mat-label>Player Id</mat-label>
            <input type="text" formControlName="playerId" matInput placeholder="Enter Player Id"
                   data-testid="sendgrid-player-id-input">
          </mat-form-field>

          <mat-form-field appearance="outline" class="flex-50">
            <mat-label>Template Name</mat-label>
            <mat-select placeholder="Select Template" formControlName="templateId"
                        data-testid="sendgrid-template-name-select">
              @for (type of emailTemplateTypes; track type.value) {
                <mat-option [value]="type.value" data-testid="sendgrid-template-name-option">{{ type.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="flex-50">
            <mat-label>Game</mat-label>
            <mat-select placeholder="Select Game" formControlName="gameId" data-testid="sendgrid-game-name-select">
              @for (game of games; track game; ) {
                <mat-option [value]="game.Id" data-testid="sendgrid-game-name-option">{{ game.Name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="flex-50">
            <mat-label>Event</mat-label>
            <mat-select placeholder="Select Event" formControlName="event" data-testid="sendgrid-event-select">
              @for (event of eventTypes; track event) {
                <mat-option [value]="event.toLocaleLowerCase()" data-testid="sendgrid-event-option">{{ event }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="flex-50">
            <mat-label>Email</mat-label>
            <input type="text" formControlName="email" matInput placeholder="Enter Email"
                   data-testid="sendgrid-email-input">
          </mat-form-field>

          <mat-form-field class="flex-50" appearance="outline">
            <mat-label>Date Range</mat-label>
            <mat-date-range-input
              [rangePicker]="rangePicker">
              <input matStartDate placeholder="From" formControlName="fromDate" data-testid="from-date-input">
              <input matEndDate placeholder="To" formControlName="toDate" data-testid="to-date-input">
            </mat-date-range-input>
            <mat-hint>MM-DD-YYYY – MM-DD-YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #rangePicker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div class="text-end">
      <button mat-raised-button
              class="margin-right-small"
              color="primary"
              (click)="onFormSubmit();"
              data-testid="sendgrid-filter-button">
        Filter
      </button>
      <button mat-raised-button
              (click)="onResetClick();"
              data-testid="sendgrid-reset-filter-button">
        Reset
      </button>
    </div>

    <ag-grid-angular
      class="ag-theme-quartz margin-top-med"
      data-testid="sendgrid-event-table"
      [tooltipShowDelay]="0"
      [pagination]="true"
      [rowData]="events$ | async"
      (gridReady)="onGridReady($event)"
      (rowClicked)="openDetailDialog($event)"
      [columnDefs]="columnDefs"
      domLayout="autoHeight"
      [suppressPaginationPanel]="true"
      overlayNoRowsTemplate="<span>No events to display</span>">
    </ag-grid-angular>

    <div class="custom-paginator">
      <span>{{ fromNumber }}</span> to <span>{{ toNumber }}</span> of <span
      class="margin-right-large">{{ totalRecords }}</span>
      <mat-icon (click)="onBtFirst()" [ngClass]="pageNumber === 1 ? 'disabled' : ''">first_page</mat-icon>
      <mat-icon (click)="onBtPrevious()" [ngClass]="pageNumber === 1 ? 'disabled' : ''">navigate_before</mat-icon>
      Page <span>{{ pageNumber }}</span> of <span>{{ totalPages }}</span>
      <mat-icon (click)="onBtNext()" [ngClass]="pageNumber === pagedEventResponse?.TotalPages ? 'disabled' : ''">
        navigate_next
      </mat-icon>
      <mat-icon (click)="onBtLast()" [ngClass]="pageNumber === pagedEventResponse?.TotalPages ? 'disabled' : ''">
        last_page
      </mat-icon>
    </div>
  }
</c-container>


