import {Component, inject, OnInit} from '@angular/core';
import {CharityService} from "../../services/charity.service";
import {ActivatedRoute} from "@angular/router";
import {concatMap, Observable, of} from "rxjs";
import {ICharityWithContributionTotals} from "../../interfaces/charity/ICharityWithContributionTotals";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {GameService} from "../../services/game.service";
import {CharityScreenState} from "../../enum/CharityScreenState";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {AsyncPipe} from "@angular/common";
import {
  CharityDateRangeReportingComponent
} from "../../components/charity/charity-date-range-reporting/charity-date-range-reporting.component";
import {
  CharityDrawReportingComponent
} from "../../components/charity/charity-draw-reporting/charity-draw-reporting.component";
import {
  CharityGeneralSettingsComponent
} from "../../components/charity/charity-general-settings/charity-general-settings.component";
import {CharityQrCodeComponent} from "../../components/charity/charity-qr-code/charity-qr-code.component";
import {
  CharitySupporterDetailsComponent
} from "../../components/charity/charity-supporter-details/charity-supporter-details.component";
import {
  ColComponent,
  ContainerComponent,
  ListGroupDirective,
  ListGroupItemDirective,
  RowComponent
} from "@coreui/angular";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-admin-charity',
  standalone: true,
  imports: [
    AsyncPipe,
    CharityDateRangeReportingComponent,
    CharityDrawReportingComponent,
    CharityGeneralSettingsComponent,
    CharityQrCodeComponent,
    CharitySupporterDetailsComponent,
    ColComponent,
    ContainerComponent,
    ListGroupDirective,
    ListGroupItemDirective,
    MatProgressSpinner,
    RowComponent
  ],
  templateUrl: './charity.component.html',
  styleUrl: './charity.component.css'
})
export class CharityComponent implements OnInit {
  protected charityService: CharityService = inject(CharityService);
  protected activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  protected gameService: GameService = inject(GameService);
  protected errorHandlingService: ErrorHandlingService = inject(ErrorHandlingService);
  protected game: IGameQueryResult | null = null;
  uiState: UIStateEnum = UIStateEnum.ShowLoading;
  uiStateEnumForTemplate = UIStateEnum;
  public charity: ICharityWithContributionTotals | undefined = undefined;

  public currentScreenState: CharityScreenState = CharityScreenState.GeneralInfo;
  protected readonly screenStateEnum = CharityScreenState;

  protected activeCharity$: Observable<ICharityWithContributionTotals | undefined> = this.charityService.activeCharity$;

  ngOnInit() {
    this.initializeCharityData();
  }

  private initializeCharityData() {
    this.activatedRoute.paramMap.pipe(
      concatMap((paramMapP) => {
        let charityIdFromUrl = paramMapP.get('charityId');
        if (charityIdFromUrl) {
          return this.charityService.fetchCharitiesById(charityIdFromUrl).pipe(concatMap((charityRes) => this.gameService.getGameByGameId(charityRes.GameId)))
        }
        return of(null);
      })
    ).subscribe({
      next: (gameP) => {
        if (gameP == null) {
          this.errorHandlingService.displayPageLevelErrorMessage(`Looks like there is an issue with the charity you are trying to reach.`);
        } else {
          this.game = gameP;
        }
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err);
        this.uiState = UIStateEnum.ShowData;
      }
    })
  }
}
