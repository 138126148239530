import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {GameThemeType} from "../../enum/GameThemeType";
import {MatCard, MatCardContent} from "@angular/material/card";
import {ColComponent, RowComponent} from "@coreui/angular";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {FormsModule} from "@angular/forms";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {GameService} from "../../services/game.service";
import {AppConfigService} from "../../services/app-config.service";

@Component({
  selector: 'app-choose-your-game',
  standalone: true,
  imports: [
    MatCard,
    ColComponent,
    RowComponent,
    MatCardContent,
    MatFormField,
    MatSelect,
    MatLabel,
    FormsModule,
    MatOption
  ],
  templateUrl: './choose-your-game.component.html',
  styleUrl: './choose-your-game.component.css'
})
export class ChooseYourGameComponent {
  @Output() public gameChosenEvent: EventEmitter<IGameQueryResult> = new EventEmitter();
  @Input() message: string = 'Please choose a game';

  private gameService: GameService = inject(GameService);
  private appConfigService: AppConfigService = inject(AppConfigService);

  protected readonly gameThemeType = GameThemeType;
  public chosenGame: IGameQueryResult | null = null;
  public gameLogo: string = "";
  // in order for this array to be populated you must have the route that uses this component to use the GetGamesForUserResolver.
  // this will ensure that the games are loaded
  public adminGames: IGameQueryResult[] = this.gameService.getAllAvailableGames();

  onGameChange(chosenGame: IGameQueryResult) {
    this.gameLogo = `${this.appConfigService.cdnRoot()}/assets/${chosenGame.Subdomain}/game-logo.svg`;
    this.gameChosenEvent.emit(chosenGame);
  }
}
