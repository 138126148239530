<c-container class="mt-5">
  <h2>Testing Area</h2>
  <app-choose-your-game [message]="'Choose a game to start some testing'" (gameChosenEvent)="onGameChange($event)"></app-choose-your-game>
  @if(activeGame) {
    <button mat-raised-button (click)="generateTestEmails()">Generate Emails</button>
    <button mat-raised-button (click)="testSentryException()">Causable API - Test Sentry Exception</button>
  }

</c-container>

