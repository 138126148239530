import {AfterViewInit, Component, Inject, signal, WritableSignal} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SnackbarService} from "../../../services/snackbar.service";
import {IPlayerPerGameQueryResult} from "../../../interfaces/player/IPlayerPerGameQueryResult";
import {PlayersService} from "../../../services/players.service";
import {IAdminUpdatePlayerBasicInfo} from "../../../interfaces/player/IAdminUpdatePlayerBasicInfo";
import {PhoneFormatPipe} from "../../../pipes/phone-format.pipe";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-update-basic-player-info',
  standalone: true,
  templateUrl: './update-basic-player-info.component.html',
  imports: [
    DialogBaseComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInput,
    MatButton
  ],
  styleUrls: ['./update-basic-player-info.component.css']
})
export class UpdateBasicPlayerInfoComponent implements AfterViewInit {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  protected readonly Object = Object;
  protected areChanges: WritableSignal<boolean> = signal(false);
  public uiStateEnumForTemplate = UIStateEnum;
  public firstNameControl: FormControl<string | null> = new FormControl<string | null>('', Validators.required);
  public lastNameControl: FormControl<string | null> = new FormControl<string | null>('', Validators.required);
  public phoneNumberControl: FormControl<string | null> = new FormControl<string | null>('', Validators.required);
  public emailControl: FormControl<string | null> = new FormControl<string>('', [
    Validators.required,
    Validators.email
  ]);

  public updateBasicInfoForm = new FormGroup({
    firstName: this.firstNameControl,
    lastName: this.lastNameControl,
    phoneNumber: this.phoneNumberControl,
    email: this.emailControl
  });

  public changes: any = {
    FirstName: '',
    LastName: '',
    Phone: '',
    Email: ''
  };

  constructor(private playerService: PlayersService,
              private errorHandlingService: ErrorHandlingService,
              private phoneFormatPipe: PhoneFormatPipe,
              private matDialogRef: MatDialogRef<UpdateBasicPlayerInfoComponent>,
              @Inject(MAT_DIALOG_DATA) public updateBasicInfoData: {
                playerData: IPlayerPerGameQueryResult;
                gameId: string;
              },
              private snackBarService: SnackbarService) {
    this.setFormData();
  }

  private listenForFormChanges() {
    this.updateBasicInfoForm.valueChanges.subscribe({
      next: () => {
        const updateField = (field: string, control: FormControl, label: string) => {
          let chosenPlayerValue = this.updateBasicInfoData.playerData[field as keyof IPlayerPerGameQueryResult];
          if (chosenPlayerValue !== control.value) {
            this.changes[field as keyof any] = `<span class="bold-text">${label}:</span> from <span class="bold-text">${chosenPlayerValue}</span> to <span class="bold-text">${control.value}</span>`;
          } else {
            this.changes[field as keyof any] = '';
          }
        };

        updateField('FirstName', this.firstNameControl, 'First name');
        updateField('LastName', this.lastNameControl, 'Last name');
        updateField('Email', this.emailControl, 'Email');
        updateField('Phone', this.phoneNumberControl, 'Phone Number');

        const areChanges = Object.values(this.changes).some(value => value !== '');
        this.areChanges.set(areChanges);
      }
    })
  }

  setFormData(): void {
    this.firstNameControl.setValue(this.updateBasicInfoData.playerData.FirstName);
    this.lastNameControl.setValue(this.updateBasicInfoData.playerData.LastName);
    this.phoneNumberControl.setValue(this.updateBasicInfoData.playerData.Phone);
    this.emailControl.setValue(this.updateBasicInfoData.playerData.Email);
  }

  updateBasicInfo() {
    if (!this.updateBasicInfoForm.valid) {
      this.updateBasicInfoForm.markAsDirty();
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;

    let charityUpdate: IAdminUpdatePlayerBasicInfo = {
      Phone: <string>this.phoneNumberControl.value,
      Email: <string>this.emailControl.value,
      FirstName: <string>this.firstNameControl.value,
      LastName: <string>this.lastNameControl.value,
      GameId: this.updateBasicInfoData.gameId,
      PlayerId: this.updateBasicInfoData.playerData.Id
    };

    this.playerService.updateBasicPlayerInfo(charityUpdate).subscribe({
      next: () => {
        this.snackBarService.openSuccessfulSnackBar('Successfully updated Player Basic Info');
        this.matDialogRef.close(true);
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err, true);
      }
    })
  }

  ngAfterViewInit(): void {
    this.phoneNumberControl.valueChanges.subscribe((value: string | null) => {
      if (value) {
        const formattedValue = this.phoneFormatPipe.transform(value);
        this.phoneNumberControl.setValue(formattedValue, {emitEvent: false});
        return;
      }
    });
    this.listenForFormChanges();
  }

}
