import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {IDateRangeReportingData} from "../../../interfaces/reporting/IDateRangeReportingData";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatButton} from "@angular/material/button";
import {MatTabsModule} from "@angular/material/tabs";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {
  ComprehensiveReportByDateRangeComponent
} from "../comprehensive-report-by-date-range/comprehensive-report-by-date-range.component";
import {LotteryLicenseReportComponent} from "../lottery-license-report/lottery-license-report.component";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";

@Component({
  selector: 'app-date-range-reporting',
  standalone: true,
  templateUrl: './date-range-reporting.component.html',
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatButton,
    MatTabsModule,
    HasPermissionDirective,
    ComprehensiveReportByDateRangeComponent,
    LotteryLicenseReportComponent
  ],
  styleUrls: ['./date-range-reporting.component.scss', '../../../pages/reports/reports.scss',]
})
export class DateRangeReportingComponent {

  @Input() set gameSetter(gameP: IGameQueryResult) {
    this.activeGame = gameP;
    // if the user is already looking at the reports for one game, and they change the game, then we want to just fetch the new data
    // for the new game
    if(this.showReports) {
      this.onSubmit();
    }
  }

  public comprehensiveDatesFromGroup = new FormGroup({
    startDate: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required),
  });

  public dateRanges!: IDateRangeReportingData;
  public showReports = false;
  public activeGame: IGameQueryResult | null = null;
  public permissionTypes = PermissionTypes;

  onSubmit() {
    this.dateRanges = {
      StartDate: this.comprehensiveDatesFromGroup.controls.startDate.value!,
      EndDate: this.comprehensiveDatesFromGroup.controls.endDate.value!,
      GameId: this.activeGame?.Id!
    }
    this.showReports = true;
  }

}
