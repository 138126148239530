@if (activeCharity$ | async; as activeCharity) {
  <mat-card data-testid="charity-detail-section">
    <mat-card-content>
      <div>
        <div class="detail-label">Name</div>
        <div class="detail-value" data-testid="charity-details-name">{{ activeCharity.Name }}</div>
      </div>

      <div class="detail-label">Belongs to Category</div>
      <div class="detail-value"
           data-testid="charity-category-name">{{ activeCharity.CharityCategoryName }}
      </div>

      <div class="detail-label">Status</div>
      <div class="detail-value" data-testid="charity-active-status">
        {{ activeCharity.Active ? 'Active' : 'Deactivated' }}
      </div>

      <div class="detail-label">Created On</div>
      <div class="detail-value"
           data-testid="charity-created-on">{{ activeCharity.CreatedOn | date: 'yyyy-MM-dd h:mm a' }}
      </div>

      <div class="detail-label">Total Amount Raised</div>
      <div class="detail-value"
           data-testid="charity-total-amount-raised">{{ activeCharity.TotalCharityContributions | formatCurrency }}
      </div>

      <div class="detail-label">Year to Date Amount Raised</div>
      <div class="detail-value"
           data-testid="charity-year-to-date-raised">{{ activeCharity.YearToDateContributions | formatCurrency }}
      </div>

      <div class="detail-label">Total QR Code Scans</div>
      <div class="detail-value"
           data-testid="charity-total-qr-code-inquiries">{{ activeCharity.TotalQrCodeInquiries }}</div>

      <div class="detail-label">Total Pledges Via QR Code</div>
      <div class="detail-value"
           data-testid="charity-users-who-pledged-via-qr">{{ activeCharity.PledgesViaQrCode }}</div>

      <div>
        <div class="flex-col mb-3">
          <button mat-stroked-button
                  *hasPermission="PermissionTypes.ModifyCharities"
                  (click)="openChangeNameDialog()"
                  data-testid="change-charity-name-button"
                  class="margin-top-med">
            <mat-icon>edit</mat-icon>
            Change Charity Name
          </button>
          <button class="green-text margin-top-med"
                  *hasPermission="PermissionTypes.ModifyCharities"
                  (click)="onChangeCategoriesClick(activeCharity)"
                  data-testid="change-charity-categories-button"
                  mat-stroked-button>
            <mat-icon>compare_arrows</mat-icon>
            Change categories
          </button>

          @if (activeCharity.Active) {
            <button class="red-text margin-top-med"
                    *hasPermission="PermissionTypes.DeactivateCharities"
                    (click)="onDeactivateCharityClick(activeCharity)"
                    data-testid="deactivate-charity-button"
                    mat-stroked-button>
              <mat-icon>do_disturb</mat-icon>
              Deactivate Charity
            </button>
          } @else {
            <button class="green-text margin-top-med"
                    *hasPermission="PermissionTypes.ReactivateCharities"
                    (click)="onReactivateCharityClick(activeCharity)"
                    data-testid="reactivate-charity-button"
                    mat-stroked-button>
              <mat-icon>refresh</mat-icon>
              Reactivate Charity
            </button>
          }
        </div>
      </div>

    </mat-card-content>
  </mat-card>
}
