<c-container class="mt-5">
  <c-row>
    <c-col md="3" class="mb-3">
      <div cListGroup>
        <button cListGroupItem
                data-testid="general-info-tab"
                (click)="chosenSupportDeskUIType = supportDeskUITypes.MyRequests"
                [active]="chosenSupportDeskUIType === supportDeskUITypes.MyRequests">
          <div>
            <h5 class="mb-1">View My Support Requests</h5>
            <p>list of support requests that are assigned to you</p>
          </div>
        </button>

        <button cListGroupItem
                data-testid="causable-suspensions-tab"
                (click)="getFilteredRequests()"
                [active]="chosenSupportDeskUIType === supportDeskUITypes.FilterAll">
          <div>
            <h5 class="mb-1">View and Filter All Requests</h5>
            <p>view all support requests with the ability to filter</p>
          </div>
        </button>
        @if (isCrossGameSearch) {
          <button cListGroupItem
                  *hasPermission="permissionTypes.HandleEscalatedSupportRequests"
                  data-testid="notes-tab"
                  (click)="getEscalatedRequests()"
                  [active]="chosenSupportDeskUIType === supportDeskUITypes.Escalated">
            <div>
              <h5 class="mb-1">Escalated Support Requests</h5>
              <p>view requests that have been escalated to causable admins</p>
            </div>
          </button>
        }
      </div>
    </c-col>
    <c-col md="9">
      @switch (chosenSupportDeskUIType) {
        @case (supportDeskUITypes.MyRequests) {
          <c-row>
            <h3>My Support Requests</h3>
            @if (assignedSupportRequestsCount$ | async; as myRequests) {
              @if (myRequests.length > 0) {
                <div cListGroup>
                  @for (request of myRequests; track request) {
                    <button cListGroupItem
                            data-testid="charity-qr-settings-tab"
                            (click)="onRowClicked(request)">
                      <div class="margin-top-small">
                        <h5 class="bold-text">{{ request.PlayerName }} ({{ request.PlayerEmail }})</h5>
                        @if (request.Resolved || request.Escalated) {
                          <div>
                            @if (request.Resolved) {
                              <span class="resolved-icon float-right">Resolved</span>
                            }
                            @if (request.Escalated) {
                              <span class="escalated-icon float-right">Escalated</span>
                            }
                          </div>
                        }
                        <div class="margin-bottom-small">{{ displayErrorType(request.ErrorType) }}</div>
                        <div>Created on: {{ request.CreatedOn | date: 'yyyy-MM-dd' }}</div>
                      </div>
                    </button>
                  }
                </div>
              } @else {
                Looks like there aren't any requests assigned to you
              }
            }
          </c-row>
        }
        @case (supportDeskUITypes.FilterAll) {
          <c-row>
            <div class="flex-row justify-space-between align-center">
              <h3 class="margin-top-med margin-bottom-med">Support Requests</h3>
              <button mat-stroked-button data-testid="support-request-go-to-player"
                      (click)="showFilter = !showFilter">
                {{ showFilter ? 'Hide Filter ' : 'Show Filter' }}
                <mat-icon>{{ showFilter ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
              </button>
            </div>
            @if (showFilter) {
              <form [formGroup]="filterForm" class="margin-bottom-med">
                <div class="flex-row-wrap justify-space-between">
                  <div class="flex-50">
                    <app-filterable-dropdown
                      #assigneeDropdownFilter
                      [placeholder]="'Assignee'"
                      [optionsSetter]="assigneeDropDownOptions"
                      (selectionChange)="assigneeId = $event"></app-filterable-dropdown>
                  </div>

                  <mat-form-field appearance="outline" class="flex-50">
                    <mat-label>Player Name</mat-label>
                    <input matInput formControlName="playerName" data-testid="support-desk-player-name-input">
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="flex-50">
                    <mat-label>Player Email</mat-label>
                    <input matInput formControlName="playerEmail" data-testid="support-desk-player-email-input">
                  </mat-form-field>

                  @if (games && games.length > 0) {
                    <mat-form-field appearance="outline" class="flex-50">
                      <mat-label>Game</mat-label>
                      <mat-select formControlName="gameId" data-testid="support-desk-id-game-input">
                        @for (game of games; track game.Id) {
                          <mat-option [value]="game.Id">{{ game.Name }}</mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                  }

                  <mat-form-field appearance="outline" class="flex-50">
                    <mat-label>Error Type</mat-label>
                    <mat-select formControlName="errorType" data-testid="support-desk-error-type-select">
                      @for (error_type of errorTypes; track error_type.value) {
                        <mat-option [value]="error_type.value"
                                    data-testid="support-desk-error-type-option">{{ error_type.name }}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="flex-50">
                    <mat-label>Escalated to Causable Admin</mat-label>
                    <mat-select formControlName="escalated" data-testid="support-desk-escalated-select">
                      <mat-option data-testid="support-desk-escalated-option-all" [value]="null">All</mat-option>
                      <mat-option data-testid="support-desk-escalated-option-escalated" [value]="true">Escalated
                      </mat-option>
                      <mat-option data-testid="support-desk-escalated-option-not-escalated" [value]="false">Not
                        Escalated
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="flex-50">
                    <mat-label>Date Range</mat-label>
                    <mat-date-range-input
                      [rangePicker]="picker">
                      <input matStartDate placeholder="From" formControlName="fromDate" data-testid="from-date-input">
                      <input matEndDate placeholder="To" formControlName="toDate" data-testid="to-date-input">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field>
                </div>

              </form>

              <div class="text-end">
                <button mat-raised-button type="submit" class="primary-button margin-right-small"
                        (click)="onFormSubmit()" data-testid="submit-button">Filter
                </button>
                <button mat-stroked-button (click)="onResetClick()" data-testid="reset-button">
                  Reset
                </button>
              </div>
            }
          </c-row>
          <c-row>
            @if (supportRequests$ | async; as filteredRequests) {
              @if (filteredRequests.length > 0) {
                <div cListGroup>
                  @for (request of (supportRequests$ | async); track request) {
                    <button cListGroupItem
                            data-testid="charity-qr-settings-tab"
                            (click)="onRowClicked(request)">
                      <div class="margin-top-small">
                        <h5 class="bold-text">{{ request.PlayerName }} ({{ request.PlayerEmail }})</h5>

                        @if (request.Resolved || request.Escalated) {
                          <div>
                            @if (request.Resolved) {
                              <span class="resolved-icon float-right">Resolved</span>
                            }
                            @if (request.Escalated) {
                              <span class="escalated-icon float-right">Escalated</span>
                            }
                          </div>
                        }
                        <div class="margin-bottom-small">{{ displayErrorType(request.ErrorType) }}</div>
                        <div>Created on: {{ request.CreatedOn | date: 'yyyy-MM-dd' }}</div>
                      </div>
                    </button>
                  }
                </div>
                <div class="custom-paginator">
                  <span>{{ fromNumber }}</span> to <span>{{ toNumber }}</span> of <span
                  class="margin-right-large">{{ totalRecords }}</span>
                  <mat-icon (click)="onBtFirst()" [ngClass]="pageNumber === 1 ? 'disabled' : ''">first_page</mat-icon>
                  <mat-icon (click)="onBtPrevious()" [ngClass]="pageNumber === 1 ? 'disabled' : ''">navigate_before
                  </mat-icon>
                  Page <span>{{ pageNumber }}</span> of <span>{{ totalPages }}</span>
                  <mat-icon (click)="onBtNext()"
                            [ngClass]="pageNumber === pagedSupportRequestResponse?.TotalPages ? 'disabled' : ''">
                    navigate_next
                  </mat-icon>
                  <mat-icon (click)="onBtLast()"
                            [ngClass]="pageNumber === pagedSupportRequestResponse?.TotalPages ? 'disabled' : ''">
                    last_page
                  </mat-icon>
                </div>
              } @else {
                Looks like there are no requests that match the filter parameters
              }
            }

          </c-row>
        }
        @case (supportDeskUITypes.Escalated) {
          <c-row>
            <h3>Escalated Requests</h3>
            <div cListGroup>
              @for (request of (supportRequests$ | async); track request) {
                <button cListGroupItem
                        data-testid="charity-qr-settings-tab"
                        (click)="onRowClicked(request)">
                  <div class="margin-top-small">
                    <div class="flex justify-space-between margin-bottom-small">
                      <h5 class="bold-text">{{ request.PlayerName }} ({{ request.PlayerEmail }})</h5>
                    </div>
                    @if (request.Resolved || request.Escalated) {
                      <div>
                        @if (request.Resolved) {
                          <span class="resolved-icon float-right">Resolved</span>
                        }
                        @if (request.Escalated) {
                          <span class="escalated-icon float-right">Escalated</span>
                        }
                      </div>
                    }
                    <div class="margin-bottom-small">{{ displayErrorType(request.ErrorType) }}</div>
                    <div>Created on: {{ request.CreatedOn | date: 'yyyy-MM-dd' }}</div>
                  </div>
                </button>
              }
            </div>
            <div class="custom-paginator">
              <span>{{ fromNumber }}</span> to <span>{{ toNumber }}</span> of <span
              class="margin-right-large">{{ totalRecords }}</span>
              <mat-icon (click)="onBtFirst()" [ngClass]="pageNumber === 1 ? 'disabled' : ''">first_page</mat-icon>
              <mat-icon (click)="onBtPrevious()" [ngClass]="pageNumber === 1 ? 'disabled' : ''">navigate_before
              </mat-icon>
              Page <span>{{ pageNumber }}</span> of <span>{{ totalPages }}</span>
              <mat-icon (click)="onBtNext()"
                        [ngClass]="pageNumber === pagedSupportRequestResponse?.TotalPages ? 'disabled' : ''">
                navigate_next
              </mat-icon>
              <mat-icon (click)="onBtLast()"
                        [ngClass]="pageNumber === pagedSupportRequestResponse?.TotalPages ? 'disabled' : ''">
                last_page
              </mat-icon>
            </div>
          </c-row>
        }
      }
    </c-col>
  </c-row>
</c-container>

