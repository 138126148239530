import {Component, inject, OnInit} from '@angular/core';
import {GameService} from "../../services/game.service";
import {ActiveUserService} from "../../services/active-user.service";
import {GameInstanceService} from "../../services/game-instance.service";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {
  ColComponent,
  ContainerComponent,
  ListGroupDirective,
  ListGroupItemDirective,
  RowComponent
} from "@coreui/angular";
import {IAdminQueryResult} from "../../interfaces/IAdminQueryResult";
import {
  IGameInstancePlayerEmailsGroupedByGameInstance
} from "../../interfaces/reporting/GetAllGameInstancePlayerEmails";
import {SnackbarService} from "../../services/snackbar.service";
import {LoadingCardContentComponent} from "../../shared/loading-card-content/loading-card-content.component";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import Chart, {ChartConfiguration} from "chart.js/auto";
import {DatePipe} from "@angular/common";
import {GameInstancePlayerEmailTypes} from "../../enum/GameInstancePlayerEmailTypes";
import {ChooseYourGameComponent} from "../../components/choose-your-game/choose-your-game.component";

@Component({
  selector: 'app-game-instance-player-emails',
  standalone: true,
  imports: [
    ListGroupDirective,
    ListGroupItemDirective,
    ColComponent,
    ContainerComponent,
    RowComponent,
    LoadingCardContentComponent,
    MatProgressSpinner,
    ChooseYourGameComponent
  ],
  templateUrl: './game-instance-player-emails.component.html',
  styleUrl: './game-instance-player-emails.component.css'
})
export class GameInstancePlayerEmailsComponent implements OnInit {

  public drawResultsEmailsLineChart!: Chart;
  public pendingDrawNotificationEmailsLineChart!: Chart;

  private gameService: GameService = inject(GameService);
  private gameInstanceService: GameInstanceService = inject(GameInstanceService);
  private datePipe: DatePipe = inject(DatePipe);
  private activeUserService: ActiveUserService = inject(ActiveUserService);
  private snackbarService: SnackbarService = inject(SnackbarService);

  protected uiState: UIStateEnum = UIStateEnum.ShowLoading;
  protected uiStateForTemplate = UIStateEnum;
  protected chosenGame: IGameQueryResult | null = null;
  protected user: IAdminQueryResult | null = null;

  protected gameInstancePlayerEmailByGameInstance: IGameInstancePlayerEmailsGroupedByGameInstance[] = [];

  ngOnInit(): void {
    this.gameService.clearActiveGame();
    this.user = this.activeUserService.activeUser();
  }

  updateActiveGameAndGetEmails(gameP: IGameQueryResult) {
    this.uiState = UIStateEnum.ShowRequestProcessing;
    this.chosenGame = gameP;
    this.gameInstanceService.getGameInstancePlayerEmails(gameP.Id, this.user!.Id).subscribe({
      next: (res) => {
        this.gameInstancePlayerEmailByGameInstance = res;
        this.uiState = UIStateEnum.ShowData;
        this.generateDrawResultsEmailsOverTime();
      },
      error: (err) => {
        this.snackbarService.openErrorSnackBar('looks like there was error finding the emails, or the chosen game has none');
        this.uiState = UIStateEnum.ShowData;
      }
    })
  }

  private generateDrawResultsEmailsOverTime() {
    if (this.drawResultsEmailsLineChart) {
      this.drawResultsEmailsLineChart.destroy();
    }

    if (this.pendingDrawNotificationEmailsLineChart) {
      this.pendingDrawNotificationEmailsLineChart.destroy();
    }

    this.gameInstancePlayerEmailByGameInstance = this.gameInstancePlayerEmailByGameInstance
      .sort((a, b) => a.DrawDate < b.DrawDate ? -1 : 1);

    let pendingDrawNotificationsEmailsGrouped: IGameInstancePlayerEmailsGroupedByGameInstance[] = [];
    let drawResultsEmailsGrouped: IGameInstancePlayerEmailsGroupedByGameInstance[] = [];

    this.gameInstancePlayerEmailByGameInstance.forEach((group) => {
      const pendingDrawNotificationEmails = group.GameInstancePlayerEmails.filter(email => email.GameInstancePlayerEmailType === GameInstancePlayerEmailTypes.PendingDrawNotification);
      const drawResultsEmails = group.GameInstancePlayerEmails.filter(email => email.GameInstancePlayerEmailType === GameInstancePlayerEmailTypes.DrawResultsNotification);

      pendingDrawNotificationsEmailsGrouped.push({
        GameInstanceId: group.GameInstanceId,
        DrawDate: group.DrawDate,
        GameInstancePlayerEmails: pendingDrawNotificationEmails
      });


      drawResultsEmailsGrouped.push({
        GameInstanceId: group.GameInstanceId,
        DrawDate: group.DrawDate,
        GameInstancePlayerEmails: drawResultsEmails
      });

    })

    let drawResultsCntxt = document.getElementById('drawResultsEmailsLineChart') as HTMLCanvasElement;
    let pendingDrawCntxt = document.getElementById('pendingDrawNotificationsEmailsLineChart') as HTMLCanvasElement;

    const drawResultsData = {
      labels: drawResultsEmailsGrouped.map((instance) => this.datePipe.transform(instance.DrawDate, 'MMM d')),
      datasets: [
        {
          label: 'number of draw result emails sent',
          data: drawResultsEmailsGrouped.map(x => x.GameInstancePlayerEmails.length),
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }
      ]
    };

    const pendingDrawNotificationsData = {
      labels: pendingDrawNotificationsEmailsGrouped.map((instance) => this.datePipe.transform(instance.DrawDate, 'MMM d')),
      datasets: [
        {
          label: 'number of draw notification emails sent',
          data: pendingDrawNotificationsEmailsGrouped.map(x => x.GameInstancePlayerEmails.length),
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }
      ]
    };

    const drawResultsConfig: ChartConfiguration = {
      type: 'line',
      data: drawResultsData,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Draw Results Emails Sent Past 10 draws'
          }
        }
      }
    };

    const pendingDrawConfig: ChartConfiguration = {
      type: 'line',
      data: pendingDrawNotificationsData,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Draw Notification Emails Sent Past 10 draws'
          }
        }
      }
    };

    if (drawResultsCntxt) {
      this.drawResultsEmailsLineChart = new Chart(drawResultsCntxt, drawResultsConfig)
    }

    if (pendingDrawCntxt) {
      this.pendingDrawNotificationEmailsLineChart = new Chart(pendingDrawCntxt, pendingDrawConfig)
    }
  }

}
