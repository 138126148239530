<c-container>
  <c-row class="mt-5">
    <c-col><h2 data-testid="active-game-title">Active Causable draws</h2></c-col>
  </c-row>
  <app-choose-your-game
    (gameChosenEvent)="onGameChange($event)"
    [message]="'Choose a game in order to view its active draw'">
  </app-choose-your-game>
</c-container>

@if (uiState == uiStateForTemplate.ShowData && chosenGamesActiveDraw) {
  <c-container>
    <c-row>
      <c-col>
        <h2 data-testid="active-game-title">Current {{ chosenGame?.Name }} Draw
          <button
            mat-mini-fab
            data-testid="open-manage-game-options-menu-button"
            *userHasCausableAdminAccess
            class="float-right primary-background-color"
            [matMenuTriggerFor]="menu">
            <mat-icon matTooltipPosition="right"
                      matTooltip="open game settings"
            >more_vertical
            </mat-icon>
          </button>
        </h2>

        <div class="flex-row-wrap gap-normal justify-space-between">
          <div class="full-width">
            <app-display-active-game-instance
              [setSelectedGameInstance]="chosenGamesActiveDraw"></app-display-active-game-instance>
          </div>
        </div>
      </c-col>
    </c-row>
  </c-container>
}

<mat-menu #menu="matMenu">
  <button mat-menu-item
          (click)="openEndGameInstance()"
          data-testid="end-game-instance-button">
    <mat-icon class="dark-grey menu-icon">edit</mat-icon>
    <span class="dark-grey menu-text">End Game Instance</span>
  </button>
  <button mat-menu-item *hasPermission="permissionTypes.SendEmailBlast"
          (click)="openEmailBlast()"
          data-testid="send-message-to-all-users">
    <mat-icon class="dark-grey menu-icon">email</mat-icon>
    <span class="dark-grey menu-text">Send Message to All Users</span>
  </button>
</mat-menu>
