<c-container class="mt-5">
  <h2>How To Play Editor</h2>

  <form [formGroup]="howToPlayForm">
    <mat-form-field appearance="outline" class="full-width">
      <mat-hint>How To Play Text</mat-hint>
      <textarea matInput formControlName="howToPlay" data-testid="how-to-play-input"></textarea>
      <mat-error *ngIf="howToPlayControl.errors">How To Play Text cannot be empty</mat-error>
    </mat-form-field>

    <div class="margin-top-large markdown-preview">
      <span>How To Play Preview</span>
      <markdown [data]="formatMarkdown(howToPlayControl.value!)"></markdown>
    </div>
  </form>

  <button mat-flat-button color="primary"
          data-testid="how-to-play-submit-button"
          [disabled]="uiState === uiStateEnumForTemplate.ShowLoading"
          (click)="submit()">Submit
  </button>
</c-container>
