import {Injectable} from "@angular/core";
import {ActiveUserService} from "./active-user.service";
import {map, Observable} from "rxjs";
import {PermissionTypes} from "../enum/PermissionTypes";
import {GameService} from "./game.service";
import {APIService} from "./api.service";
import {IRoleWithPermissions} from "../interfaces/IRoleWithPermissions";
import {IRemoveRoleCommand} from "../interfaces/IRemoveRoleCommand";
import {IApiResponseBase} from "../interfaces/IApiResponseBase";
import {IRole} from "../interfaces/IRole";
import {IAddNewRoleCommand} from "../interfaces/IAddNewRoleCommand";
import {IUpdateRole} from "../interfaces/IUpdateRole";
import {IRemovePermissionFromRole} from "../interfaces/IRemovePermissionFromRole";
import {IPermission} from "../interfaces/IPermission";
import {IAddPermissionsToRole} from "../interfaces/IAddPermissionsToRole";
import {IAddRole} from "../interfaces/IAddRole";
import {IUpgradeAdminToCausableAdmin} from "../interfaces/IUpgradeAdminToCausableAdmin";
import {IUpgradeAdminToCrossGame} from "../interfaces/IUpgradeAdminToCrossGame";

@Injectable(
  {providedIn: 'root'}
)
export class PermissionsService {
  constructor(private activeUserService: ActiveUserService,
              private apiService: APIService,
              private gameService: GameService) {
  }

  public userHasPermission(lookupPermissionP: PermissionTypes): boolean {
    const user = this.activeUserService.activeUser();

    if (user.CausableAdmin) {
      return true;
    }

    if(user.CrossGamePermissions && user.CrossGamePermissions?.includes(lookupPermissionP)) {
      return true;
    }

    return this.activeUserService.activeUser().AdminPermissions.some(
      (permissionP) =>
        permissionP.PermissionType === lookupPermissionP && permissionP.GameId === this.gameService.activeGame().Id
    )
  }

  public userHasCrossGamePermission(lookupPermissionP: PermissionTypes): boolean {
    const user = this.activeUserService.activeUser();

    if (user.CausableAdmin) {
      return true;
    }

    return !!(user.CrossGamePermissions && user.CrossGamePermissions?.includes(lookupPermissionP));

  }

  public hasCausableAdminPermissions(): Observable<boolean> {
    return this.activeUserService.selectActiveUser().pipe(map((activeUserP) => activeUserP.CausableAdmin));
  }

  public getAllRolesAndPermissions(gameIdP: string = ""): Observable<IRoleWithPermissions[]> {
    let apiUrl = `role-permission/all-permissions-by-role`;

    if(gameIdP) {
      apiUrl += `/${gameIdP}`;
    }

    return this.apiService.MakeGetRequest<IRoleWithPermissions[]>(apiUrl);
  }

  public removeUserRole(removeUserPermissionsP: IRemoveRoleCommand): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<any>('role-permission/remove-user-roles', removeUserPermissionsP);
  }

  public removePermissionFromRole(removePermissionsP: IRemovePermissionFromRole): Observable<IApiResponseBase> {
    return this.apiService.MakeDeleteRequest<any>('role-permission/permission-from-role', removePermissionsP);
  }

  public addPermissionToRole(addPermissionsP: IAddPermissionsToRole): Observable<IApiResponseBase> {
    return this.apiService.MakePostRequest<any>('role-permission/permission-to-role', addPermissionsP);
  }

  public addNewRole(addRoleP: IAddRole): Observable<IApiResponseBase> {
    return this.apiService.MakePostRequest<any>('role-permission/role', addRoleP);
  }

  public updateUserRole(updateRoleP: IUpdateRole): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<any>('role-permission/role', updateRoleP);
  }

  public fetchPossibleRoles(gameIdP: string = ""): Observable<IRole[]> {
    let apiUrl = `role-permission/all-roles`;

    if(gameIdP) {
      apiUrl += `/${gameIdP}`;
    }

    return this.apiService.MakeGetRequest<any>(apiUrl);
  }

  public fetchAllAvailablePermissions(gameIdP: string = ""): Observable<IPermission[]> {
    let apiUrl = `role-permission/permissions`;

    if(gameIdP) {
      apiUrl += `/${gameIdP}`;
    }

    return this.apiService.MakeGetRequest<any>(apiUrl);
  }

  public upgradeToCausableAdmin(upgradeToCausableAdminP: IUpgradeAdminToCausableAdmin): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<any>('admin-user/upgrade-admin-to-causable-admin', upgradeToCausableAdminP);
  }

  public upgradeToCrossGameAdmin(upgradeToCrossGameAdminP: IUpgradeAdminToCrossGame): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<any>('admin-user/upgrade-admin-to-cross-game-admin', upgradeToCrossGameAdminP);
  }

  public addNewUserRole(newUserPermissionsP: IAddNewRoleCommand): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<any>('role-permission/add-user-roles', newUserPermissionsP);
  }
}
