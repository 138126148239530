import {Component, inject} from '@angular/core';
import {CharityManagementComponent} from "../charity-management.component";
import {ChooseYourGameComponent} from "../../../components/choose-your-game/choose-your-game.component";
import {ContainerComponent} from "@coreui/angular";
import {GameService} from "../../../services/game.service";

@Component({
  selector: 'app-game-admin-charity-management',
  standalone: true,
  imports: [CharityManagementComponent, ChooseYourGameComponent, ContainerComponent],
  templateUrl: './game-admin-charity-management.component.html',
  styleUrl: './game-admin-charity-management.component.css'
})
export class GameAdminCharityManagementComponent {
  private gameService: GameService = inject(GameService);

  protected activeGame =  this.gameService.activeGame();
}
