import {Component, inject} from '@angular/core';
import {ChooseYourGameComponent} from "../../../components/choose-your-game/choose-your-game.component";
import {ContainerComponent} from "@coreui/angular";
import {ReportsComponent} from "../reports.component";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";
import {GameService} from "../../../services/game.service";

@Component({
  selector: 'app-game-admin-reporting',
  standalone: true,
  imports: [
    ChooseYourGameComponent,
    ContainerComponent,
    ReportsComponent
  ],
  templateUrl: './game-admin-reporting.component.html',
  styleUrl: './game-admin-reporting.component.css'
})
export class GameAdminReportingComponent {
  private gameService: GameService = inject(GameService);

  protected game: IGameQueryResult = this.gameService.activeGame();
}
