<mat-nav-list>
  <mat-list-item>
    <div matListItemLine>Causable Admin</div>
  </mat-list-item>
  <hr class="white-text">
  <mat-list-item [routerLink]="['testing-page']"
                 *userHasCausableAdminAccess
                 data-testid="testing-page-link">
    <mat-icon matListItemIcon>checklist</mat-icon>
    <div matListItemLine>Testing</div>
  </mat-list-item>

  <mat-list-item [routerLink]="['cross-game-view-draw-landing']"
                 data-testid="view-active-draw-page-link">
    <mat-icon matListItemIcon>sports_esports</mat-icon>
    <div matListItemLine data-testid="sidenav-current-draw">View Active Draws</div>
  </mat-list-item>

  <mat-list-item [routerLink]="['cross-game-admin-reporting']"
                 data-testid="view-cross-game-reporting-page-link">
    <mat-icon matListItemIcon>request_page</mat-icon>
    <div matListItemLine data-testid="sidenav-current-draw">Reporting</div>
  </mat-list-item>

  <mat-list-item [routerLink]="['cross-game-charities']"
                 *hasPermission="permissionTypes.ViewCharities"
                 data-testid="view-cross-game-charity-page-link">
    <mat-icon matListItemIcon>volunteer_activism</mat-icon>
    <div matListItemLine data-testid="sidenav-current-draw">Charities</div>
  </mat-list-item>

  <mat-list-item [routerLink]="['cross-game-notification-preferences']"
                 data-testid="notification-preferences-page-link">
    <mat-icon matListItemIcon>volunteer_activism</mat-icon>
    <div matListItemLine data-testid="sidenav-notification-preferences">Notification Preferences
    </div>
  </mat-list-item>

  <mat-list-item [routerLink]="['cross-game-game-management']"
                 data-testid="view-active-draw-page-link">
    <mat-icon matListItemIcon>settings</mat-icon>
    <div matListItemLine data-testid="sidenav-current-draw">Manage Game Settings</div>
  </mat-list-item>

  <mat-list-item [routerLink]="['frequently-asked-questions']"
                 *hasPermission="permissionTypes.ModifyFrequentlyAskedQuestions"
                 data-testid="current-draw-page-link">
    <mat-icon matListItemIcon>question_mark</mat-icon>
    <div matListItemLine data-testid="sidenav-current-draw">Frequently Asked Questions</div>
  </mat-list-item>

  <mat-list-item [routerLink]="['view-player-emails']"
                 *userHasCausableAdminAccess
                 data-testid="game-instance-player-emails-link">
    <mat-icon matListItemIcon>email</mat-icon>
    <div matListItemLine data-testid="sidenav-current-draw">Game Instance Player Emails</div>
  </mat-list-item>
  <mat-list-item [routerLink]="['sendgrid-events']"
                 *userHasCausableAdminAccess
                 data-testid="sendgrid-events-link">
    <mat-icon matListItemIcon>email</mat-icon>
    <div matListItemLine data-testid="sidenav-sendgrid-events">SendGrid Events</div>
  </mat-list-item>
  <mat-list-item [routerLink]="['new-game']"
                 *userHasCausableAdminAccess
                 data-testid="new-game-page-link">
    <mat-icon matListItemIcon>add</mat-icon>
    <div matListItemLine data-testid="sidenav-new-game">New Game</div>
  </mat-list-item>
  <mat-list-item [routerLink]="['game-instances']"
                 data-testid="game-instances-page-link"
                 *userHasCausableAdminAccess>
    <mat-icon matListItemIcon>supervised_user_circle</mat-icon>
    <div matListItemLine data-testid="sidenav-game-instances">Game Instances</div>
  </mat-list-item>
  @if (helpDeskEnabled) {
    <mat-list-item [routerLink]="['cross-game-support-desk-search']"
                   *hasPermission="permissionTypes.ViewSupportRequests"
                   data-testid="causable-admin-support-desk-link">
      <mat-icon matListItemIcon
                [matBadge]="(assignedSupportRequestsCount$ | async)?.length"
                matBadgeColor="warn"
                [matBadgeHidden]="(assignedSupportRequestsCount$ | async)?.length == 0">support_agent
      </mat-icon>
      <div matListItemLine data-testid="causable-admin-support-desk">Global Support Desk</div>
    </mat-list-item>
  }
  <mat-list-item [routerLink]="['profanity-filter-manager']"
                 data-testid="profanity-filter-manager-page-link"
                 *userHasCausableAdminAccess>
    <mat-icon matListItemIcon>lock</mat-icon>
    <div matListItemLine data-testid="sidenav-profanity-filter-manager">Profanity Filter Manager</div>
  </mat-list-item>

  @if (hangfireDashboardLink) {
    <a [href]="hangfireDashboardLink" target="_blank" style="text-decoration: none;">
      <mat-list-item data-testid="hangfire-dashboard-link" *userHasCausableAdminAccess>
        <mat-icon matListItemIcon>dashboard</mat-icon>
        <div matListItemLine data-testid="sidenav-hangfire-dashboard">Hangfire Dashboard</div>
      </mat-list-item>
    </a>
  }

  <mat-list-item [routerLink]="['global-player-search']"
                 *hasPermission="permissionTypes.ViewGlobalPlayerSearch"
                 data-testid="global-player-search">
    <mat-icon matListItemIcon>settings</mat-icon>
    <div matListItemLine data-testid="sidenav-global-search">Global Player Search</div>
  </mat-list-item>

  <mat-list-item [routerLink]="['auditing']"
                 *hasPermission="permissionTypes.ViewAuditing"
                 data-testid="auditing-page-link">
    <mat-icon matListItemIcon>edit</mat-icon>
    <div matListItemLine data-testid="sidenav-auditing">Auditing</div>
  </mat-list-item>

  <mat-list-item [routerLink]="['manage-cross-game-users']"
                 data-testid="manage-users-page-link"
                 *hasPermission="permissionTypes.ViewAdminUsers">
    <mat-icon matListItemIcon>supervised_user_circle</mat-icon>
    <div matListItemLine data-testid="sidenav-admin-user">Admin Users</div>
  </mat-list-item>

  <mat-list-item [routerLink]="['manage-global-permissions']"
                 data-testid="manage-permissions-page-link"
                 *hasPermission="permissionTypes.ViewRolesAndPermissions">
    <mat-icon matListItemIcon>supervised_user_circle</mat-icon>
    <div matListItemLine data-testid="sidenav-manage-permissions">Manage Global Permissions</div>
  </mat-list-item>
</mat-nav-list>
