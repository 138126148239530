import {Component, inject, Input} from '@angular/core';
import {AgGridAngular} from "ag-grid-angular";
import {ColDef, GridApi, GridReadyEvent, SizeColumnsToFitGridStrategy} from "ag-grid-community";
import {AsyncPipe, DatePipe} from "@angular/common";
import {IGameLotteryLicence} from "../../../interfaces/IGameLotteryLicence";
import {FormatBoolean} from "../../cell-renderers/show-played/format-boolean.component";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {
  LotteryLicenceSettingsComponent
} from "../../cell-renderers/lottery-licence-settings/lottery-licence-settings.component";
import {LotteryLicenceService} from "../../../services/lottery-licence.service";
import {Observable} from "rxjs";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";

@Component({
  selector: 'app-lottery-licences-table',
  standalone: true,
  imports: [
    AgGridAngular,
    AsyncPipe
  ],
  templateUrl: './lottery-licences-table.component.html',
  styleUrl: './lottery-licences-table.component.css'
})
export class LotteryLicencesTableComponent {
  @Input() set gameSetter(gameP: IGameQueryResult) {
    this.fetchLotteryLicences(gameP);
  }

  private datePipe: DatePipe = inject(DatePipe);
  private lotteryLicenceService: LotteryLicenceService = inject(LotteryLicenceService);
  private errorHandlingService: ErrorHandlingService = inject(ErrorHandlingService);

  public uiState = UIStateEnum.ShowLoading;
  public uiStateForTemplate = UIStateEnum;
  public lotteryLicencesForGame$: Observable<IGameLotteryLicence[]> = this.lotteryLicenceService.selectLotteryLicences$();
  public lotteryGridApi!: GridApi<IGameLotteryLicence>;
  public autoSizeStrategy: SizeColumnsToFitGridStrategy = {
    type: 'fitGridWidth'
  };

  public lotteryLicencesDefs: ColDef[] = [
    {
      headerName: 'Id',
      flex: 1,
      field: 'Id',
    },
    {
      headerName: 'Licence Number',
      field: 'LicenceNumber',
    },
    {
      headerName: 'Start Date',
      maxWidth: 140,
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y', 'GMT');
        return convertedDate ? convertedDate : '';
      },
      field: 'StartDate'
    },
    {
      headerName: 'End Date',
      maxWidth: 140,
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y', 'GMT');
        return convertedDate ? convertedDate : '';
      },
      field: 'EndDate'
    },
    {
      headerName: 'Created On Date',
      maxWidth: 140,
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y');
        return convertedDate ? convertedDate : '';
      },
      field: 'CreatedOn'
    },
    {
      headerName: 'Active',
      maxWidth: 80,
      cellRenderer: FormatBoolean,
      field: 'Active',
    },
    {
      maxWidth: 80,
      cellRenderer: LotteryLicenceSettingsComponent
    }
  ];

  fetchLotteryLicences(gameP: IGameQueryResult): void {
    this.lotteryLicenceService.fetchLotteryLicences(gameP.Id!).subscribe({
      next: (res) => {
        this.uiState = UIStateEnum.ShowData;
      }, error: (error) => {
        this.errorHandlingService.displayPageLevelErrorMessage(error);
        this.uiState = UIStateEnum.ShowData;
      }
    })
  }

  onLotteryGridReady(params: GridReadyEvent<IGameLotteryLicence>) {
    this.lotteryGridApi = params.api;
    this.lotteryGridApi.sizeColumnsToFit({
      defaultMinWidth: 90,
    });
  }
}
