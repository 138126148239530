import {Component} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {SnackbarService} from "../../../services/snackbar.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MatDialogRef} from "@angular/material/dialog";
import {PermissionsService} from "../../../services/permissions.service";
import {IAddRole} from "../../../interfaces/IAddRole";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";
import {MatCheckbox} from "@angular/material/checkbox";

@Component({
  selector: 'app-add-new-role',
  standalone: true,
  templateUrl: './add-new-role.component.html',
  imports: [
    DialogBaseComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButton,
    MatInput,
    MatCheckbox
  ],
  styleUrls: ['./add-new-role.component.scss']
})
export class AddNewRoleComponent {
  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public roleNameControl: FormControl<string | null> = new FormControl<string | null>('', Validators.required);
  public roleDescriptionControl: FormControl<string | null> = new FormControl<string | null>('', Validators.required);
  public isCrossGameRoleControl: FormControl<boolean | null> = new FormControl<boolean>(false);

  public createRoleForm = new FormGroup({
    roleName: this.roleNameControl,
    roleDescription: this.roleDescriptionControl,
    isCrossGameRole: this.isCrossGameRoleControl
  });

  constructor(private permissionService: PermissionsService,
              private snackBarService: SnackbarService,
              private errorHandlingService: ErrorHandlingService,
              private matDialogRef: MatDialogRef<AddNewRoleComponent>,) {
  }

  submitNewRole() {
    if (!this.createRoleForm.valid) {
      this.createRoleForm.markAsDirty();
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;
    let newRoleCommand: IAddRole = {
      name: this.roleNameControl.value!,
      description: this.roleDescriptionControl.value!,
      isCrossGame: this.isCrossGameRoleControl.value!
    };

    this.permissionService.addNewRole(newRoleCommand).subscribe({
      next: () => {
        this.matDialogRef.close();
        this.snackBarService.openSuccessfulSnackBar('Successfully Added new Role');
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err, true);
      }
    })
  }
}
