import {Component, inject} from '@angular/core';
import {MatListOption, MatSelectionList} from "@angular/material/list";
import {MatButton} from "@angular/material/button";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {NotificationPreferencesBase} from "../notificaton-preferences-base.component";
import {ChooseYourGameComponent} from "../../../components/choose-your-game/choose-your-game.component";
import {GameService} from "../../../services/game.service";
import {ContainerComponent} from "@coreui/angular";

@Component({
  selector: 'app-game-admin-preferences',
  standalone: true,
  imports: [
    MatButton,
    MatListOption,
    MatProgressSpinner,
    MatSelectionList,
    ChooseYourGameComponent,
    ContainerComponent
  ],
  templateUrl: '../notification-preferences.html',
  styleUrl: './game-admin-preferences.component.css'
})
export class GameAdminPreferencesComponent extends NotificationPreferencesBase {
  private gameService: GameService = inject(GameService);

  constructor() {
    super();
    this.game = this.gameService.activeGame();
    this.fetchNotificationPreferenceData();
  }

}
