import {FormControl, Validators} from "@angular/forms";
import {Directive, OnInit} from "@angular/core";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {ISupportRequest} from "../../../interfaces/player/ISupportRequest";
import {IAdminQueryResult} from "../../../interfaces/IAdminQueryResult";
import {SupportRequestService} from "../../../services/support-request.service";
import {GameService} from "../../../services/game.service";
import {ActiveUserService} from "../../../services/active-user.service";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {SnackbarService} from "../../../services/snackbar.service";
import {concatMap} from "rxjs/operators";
import {EMPTY, map} from "rxjs";
import {IResolveSupportRequest} from "../../../interfaces/player/IResolveSupportRequest";
import {IAssignAdminToSupportRequest} from "../../../interfaces/player/IAssignAdminToSupportRequest";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {IAdminWithRolesAsString} from "../../../interfaces/IAdminWithRolesAsString";

@Directive()
export abstract class BaseSupportRequestViewComponent implements OnInit {
  public uiState = UIStateEnum.ShowLoading;
  public uiStateEnumForTemplate = UIStateEnum;
  public supportRequest: ISupportRequest | null = null;
  public adminsToAssignRequestTo: IAdminWithRolesAsString[] = [];
  public readyToResolve: boolean = false;
  public assigneeId: string = '';
  public resolutionDescriptionControl = new FormControl<string | null>('', Validators.required);
  permissionTypes = PermissionTypes;
  // Optionally store a gameId (if available from the route)
  public gameId: string | null = null;

  constructor(
    protected supportRequestService: SupportRequestService,
    protected gameService: GameService,
    protected activeUserService: ActiveUserService,
    protected activatedRoute: ActivatedRoute,
    protected snackBarService: SnackbarService,
    protected router: Router
  ) {
  }

  ngOnInit(): void {
    // Set initial UI state to loading.
    this.uiState = UIStateEnum.ShowLoading;

    this.activatedRoute.paramMap.pipe(
      concatMap((paramMap: ParamMap) => {
        // Retrieve supportId (required) and gameId (optional) from route parameters.
        const supportId = paramMap.get('supportId');
        this.gameId = paramMap.get('gameId');

        if (!supportId) {
          this.snackBarService.openErrorSnackBar(
            'Looks like the support desk you are trying to get to does not exist.'
          );
          this.uiState = UIStateEnum.ShowData;
          return EMPTY;
        }

        return this.supportRequestService.fetchSupportRequestById(supportId, this.gameId)
          .pipe(concatMap((supportRequestRes) => this.supportRequestService.fetchAdminsToAssignToSupportRequest(supportRequestRes.GameId)
            .pipe(map((admins) => {
              // Set the assigneeId if the support request already has an assignee.
              if (supportRequestRes.Assignee) {
                const admin = admins.find(x => x.Id === supportRequestRes.Assignee);
                if (admin) {
                  this.assigneeId = admin.Id;
                }
              }
              this.adminsToAssignRequestTo = admins;
              return supportRequestRes;
            }))))
      })
    ).subscribe({
      next: (supportRequest) => {
        // If games are available, update the support request's GameId property to the game's short name.
        const availableGames = this.gameService.getAllAvailableGames();
        if (availableGames.length > 0) {
          const game = availableGames.find(x => x.Id === supportRequest.GameId);
          if (game) {
            supportRequest.GameId = `${game.ShortName}`;
          }
        }
        this.supportRequest = supportRequest;
        this.uiState = UIStateEnum.ShowData;
      },
      error: () => {
        this.uiState = UIStateEnum.ShowData;
        this.snackBarService.openErrorSnackBar('An error occurred while loading data.');
      }
    });
  }

  updateRequestResolvedState(resolved: boolean): void {
    // If resolving the request, validate the resolution description.
    if (resolved && !this.resolutionDescriptionControl.valid) {
      this.resolutionDescriptionControl.markAsTouched();
      return;
    }

    this.uiState = UIStateEnum.ShowRequestProcessing;

    const command: IResolveSupportRequest = {
      RequestId: this.supportRequest!.Id,
      AdminId: this.activeUserService.activeUser().Id,
      Resolved: resolved,
      // Use the resolution description only when resolving; otherwise, leave it empty.
      ResolutionDescription: resolved ? this.resolutionDescriptionControl.value! : ''
    };

    // If a gameId exists, include it in the command.
    if (this.gameId) {
      command.GameId = this.gameId;
    }

    this.supportRequestService.resolveSupportRequest(command).subscribe({
      next: (updatedSupportRequest) => {
        this.supportRequest = updatedSupportRequest;

        this.snackBarService.openSuccessfulSnackBar(
          resolved
            ? "Successfully resolved support request"
            : "Successfully unresolved support request"
        );
        this.readyToResolve = false;
        this.uiState = UIStateEnum.ShowData;
      },
      error: () => {
        this.snackBarService.openErrorSnackBar(
          'An error occurred while attempting to resolve this request. Please try again or contact a system admin.'
        );
        this.uiState = UIStateEnum.ShowData;
      }
    });
  }

  assignToMe(): void {
    this.assigneeId = this.activeUserService.activeUser().Id;
    this.assign();
  }

  assign(): void {
    const command: IAssignAdminToSupportRequest = {
      AdminId: this.activeUserService.activeUser().Id,
      RequestId: this.supportRequest!.Id,
      AssigneeId: this.assigneeId
    };

    if (this.gameId) {
      command.GameId = this.gameId;
    }

    this.supportRequestService.assignAdminToSupportRequest(command).subscribe({
      next: () => {
        this.snackBarService.openSuccessfulSnackBar("Successfully assigned admin to support request");
      },
      error: () => {
        this.snackBarService.openErrorSnackBar("Failed to assign admin to support request");
      }
    });
  }

  deEscalateSupportRequest(): void {
    // TODO: Add the code to de escalate. Being done as part of another MR. 2024/02/13
  }
}
