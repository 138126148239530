<c-container class="mt-5">
  <h2>Profanity Filter Management</h2>
  <div>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Black List</mat-label>
      <textarea matInput [formControl]="blackListControl" data-testid="profanity-black-list" [value]="blackListValue"></textarea>
      <mat-hint>Please enter the values you wish to be filtered within round brackets, separated by the | character.</mat-hint>
    </mat-form-field>
    <br>
    <button mat-flat-button
            data-testid="update-profanity-black-list-button"
            (click)="updateBlackList()">Update
    </button>
  </div>
</c-container>
