<c-container class="mt-5">
  <h2>Player Search</h2>
  <form [formGroup]="playerSearchForm">
    <mat-form-field class="player-id-form-field" appearance="outline">
      <mat-label>Player Id</mat-label>
      <input matInput placeholder="enter player id" formControlName="playerId" data-testid="player-search-id">
      @if(playerIdControl.hasError('pattern')) {
        <mat-error data-testid="role-description-error">
          incorrect guid formatting
        </mat-error>
      }
    </mat-form-field>

    <div class="flex-row-wrap justify-space-between align-center">
      <mat-form-field class="flex-25" appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput placeholder="enter first name" formControlName="firstName"
               data-testid="player-search-first-name">
      </mat-form-field>
      <mat-form-field class="flex-25" appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput placeholder="enter last name" formControlName="lastName" data-testid="player-search-last-name">
      </mat-form-field>
      <mat-form-field class="flex-25" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput placeholder="enter email" formControlName="email" type="email" email
               data-testid="player-search-email">

        @if (playerSearchForm.controls.email.invalid) {
          <mat-error>please enter a valid email address</mat-error>
        }
      </mat-form-field>
      <mat-form-field class="flex-25" appearance="outline">
        <mat-label>Phone Number</mat-label>
        <input matInput placeholder="enter phone number" formControlName="phoneNumber"
               data-testid="player-search-phone-number">
        @if (playerSearchForm.controls.phoneNumber.invalid) {
          <mat-error>please enter a valid phone number</mat-error>
        }
      </mat-form-field>
    </div>
    <div class="text-end margin-bottom-med">
      <button matTooltip="search players"
              data-testid="search-players-button"
              [disabled]="!playerSearchForm.valid"
              mat-raised-button
              type="submit"
              color="primary"
              class="margin-right-small"
              (click)="onFormSubmit()">Search Players
        <mat-icon>search</mat-icon>
      </button>
      <button matTooltip="reset filters"
              data-testid="reset-players-button"
              mat-raised-button
              color="warn"
              (click)="onResetClick()">Reset Parameters
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </form>

  <mat-card>
    <ag-grid-angular
      data-testid="player-search-player-table"
      id="playerSearchGrid"
      style="width: 100%; height: 500px;"
      class="ag-theme-quartz large-table"
      (rowClicked)="onRowSelected($event)"
      [tooltipShowDelay]=0
      rowClass="table-row table-row-hover"
      overlayNoRowsTemplate="<span>Click Search to see all players you have access to, or fill in the form then search for more refined search results.</span>"
      (gridReady)="onGridReady($event)"
      [rowData]="players"
      [pagination]="true"
      [paginationPageSize]="20"
      [columnDefs]="columnDefs">
    </ag-grid-angular>
  </mat-card>

</c-container>
