import {Injectable} from '@angular/core';
import {createStore, select, setProp, withProps} from "@ngneat/elf";
import {ISupportRequest} from "../interfaces/player/ISupportRequest";
import {ISupportRequestPagedResponse} from "../interfaces/player/ISupportRequestPagedResponse";
import {APIService} from "./api.service";
import {concatMap, forkJoin, map, Observable, tap} from "rxjs";
import {ISupportRequestFilter} from "../interfaces/ISupportRequestFilter";
import {HttpParams} from "@angular/common/http";
import {IAssignAdminToSupportRequest} from "../interfaces/player/IAssignAdminToSupportRequest";
import {IResolveSupportRequest} from "../interfaces/player/IResolveSupportRequest";
import {selectAllEntities, setEntities, withEntities} from "@ngneat/elf-entities";
import {IEscalateSupportRequest} from "../interfaces/IEscalateSupportRequest";
import {PermissionsService} from "./permissions.service";
import {PermissionTypes} from "../enum/PermissionTypes";
import {UsersService} from "./users.service";
import {IAdminQueryResult} from "../interfaces/IAdminQueryResult";
import {IAdminWithRolesAsString} from "../interfaces/IAdminWithRolesAsString";

@Injectable({
  providedIn: 'root'
})
export class SupportRequestService {

  private defaultSupportRequestResponse: ISupportRequestPagedResponse = {
    Data: [],
    Errors: [],
    FirstPage: 1,
    LastPage: 1,
    Message: '',
    NextPage: '',
    PageNumber: 1,
    PageSize: 1,
    PreviousPage: '',
    Succeeded: false,
    TotalPages: 1,
    TotalRecords: 0
  }

  private supportRequestStore = createStore(
    {name: 'support-request-response'},
    withProps<{
      supportRequestResponse: ISupportRequestPagedResponse
    }>({supportRequestResponse: this.defaultSupportRequestResponse}),
    withEntities<ISupportRequest, "Id">({idKey: "Id"})
  );

  private mySupportRequestsStore = createStore(
    {name: 'my-support-requests'},
    withEntities<ISupportRequest, "Id">({idKey: "Id"})
  );

  public searchedSupportRequests$ = this.supportRequestStore.pipe(select(state => state.supportRequestResponse));
  public mySupportRequests$ = this.mySupportRequestsStore.pipe(selectAllEntities());

  constructor(private apiService: APIService,
              private permissionsService: PermissionsService,
              private usersService: UsersService) {
  }

  public getSupportRequests(paramsP: ISupportRequestFilter): Observable<ISupportRequest[]> {
    let query_params = new HttpParams();
    if (paramsP.PlayerName) {
      query_params = query_params.append('playerName', paramsP.PlayerName);
    }

    if (paramsP.PlayerId) {
      query_params = query_params.append("playerId", paramsP.PlayerId);
    }

    if (paramsP.GameId) {
      query_params = query_params.append("gameId", paramsP.GameId);
    }

    if (paramsP.PlayerEmail) {
      query_params = query_params.append("playerEmail", paramsP.PlayerEmail);
    }

    if (paramsP.ErrorType) {
      query_params = query_params.append("errorType", paramsP.ErrorType);
    }

    if (paramsP.Assignee) {
      query_params = query_params.append("assignee", paramsP.Assignee);
    }

    if (paramsP.FromDate) {
      query_params = query_params.append("fromDate", paramsP.FromDate);
    }

    if (paramsP.ToDate) {
      query_params = query_params.append("toDate", paramsP.ToDate);
    }

    if (paramsP.Escalated != null) {
      query_params = query_params.append("escalated", paramsP.Escalated);
    }

    if (paramsP.PageNumber) {
      query_params = query_params.append("pageNumber", paramsP.PageNumber);
    }

    if (paramsP.PageSize) {
      query_params = query_params.append("pageSize", paramsP.PageSize);
    }

    return this.apiService.MakeGetRequest<ISupportRequestPagedResponse>("support-desk/support-requests", query_params).pipe(map((response) => {
      this.supportRequestStore.update(setProp('supportRequestResponse', response));
      this.supportRequestStore.update(setEntities(response.Data));
      return response.Data;
    }));
  }


  public assignAdminToSupportRequest(commandP: IAssignAdminToSupportRequest) {
    return this.apiService.MakePostRequest("support-desk/assign-admin-to-support-request", commandP).pipe(concatMap(() => {
      // after updating the assignee, get your assigned requests again to see if you still have any assigned
      return this.getSupportRequests({
        GameId: commandP.GameId,
        Assignee: commandP.AssigneeId
      });
    }));
  }

  public fetchMyAssignedRequests(adminIdP: string, gameIdP: string | null): Observable<ISupportRequest[]> {
    let apiUrl = `support-desk/assigned-support-requests/${adminIdP}`;

    if (gameIdP) {
      apiUrl += `/${gameIdP}`;
    }

    return this.apiService.MakeGetRequest<ISupportRequest[]>(apiUrl).pipe(tap((res) => {
      this.mySupportRequestsStore.update(setEntities(res));
    }));
  }

  public fetchSupportRequestById(supportRequestId: string, gameIdP: string | null): Observable<ISupportRequest> {
    let apiUrl = `support-desk/support-request/${supportRequestId}`;

    if (gameIdP) {
      apiUrl += `/${gameIdP}`;
    }

    return this.apiService.MakeGetRequest<ISupportRequest>(apiUrl);
  }

  public resolveSupportRequest(commandP: IResolveSupportRequest) {
    return this.apiService.MakePostRequest("support-desk/resolve-support-request", commandP).pipe(concatMap(() => {
      return this.fetchSupportRequestById(commandP.RequestId, commandP.GameId ?? null);
    }));
  }

  public escalateSupportRequest(commandP: IEscalateSupportRequest) {
    return this.apiService.MakePostRequest("support-desk/escalate-support-request", commandP).pipe(concatMap(() => {
      return this.fetchSupportRequestById(commandP.RequestId, commandP.GameId ?? null);
    }));
  }

  public fetchAdminsToAssignToSupportRequest(gameIdP: string): Observable<IAdminWithRolesAsString[]> {

    if (this.permissionsService.userHasPermission(PermissionTypes.HandleEscalatedSupportRequests)) {
      return this.getAllAdminsWhoCanBeAssignedToSupportRequestsForGame(gameIdP);
    } else {
      return this.usersService.getAllGameAdminUsers(gameIdP).pipe(map((result) => {
        return result.GameAdmins;
      }));
    }
  }

  private getAllAdminsWhoCanBeAssignedToSupportRequestsForGame(gameIdP: string): Observable<IAdminWithRolesAsString[]> {
    return forkJoin([this.usersService.getAllGameAdminUsers(gameIdP),
      this.usersService.getAllGameAdminsWithHandleEscalatedRequestPermissions(gameIdP)]).pipe(
      map(([gameAdminResponse, escalatedGameAdminResponse]) => {
        return gameAdminResponse.GameAdmins.concat(escalatedGameAdminResponse.filter(
          escalatedAdmin => gameAdminResponse.GameAdmins.every(gameAdmin => gameAdmin.Id !== escalatedAdmin.Id)));
      }));
  }
}
