import {Directive, OnInit} from '@angular/core';
import {UIStateEnum} from "../../enum/UIStateEnum";
import {UserDetailsScreenUIState} from "../../enum/UserDetailsScreenUIState";
import {IRole} from "../../interfaces/IRole";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {IAdminQueryResult} from "../../interfaces/IAdminQueryResult";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {DialogFunctionService} from "../../services/dialog-function.service";
import {PermissionsService} from "../../services/permissions.service";
import {SnackbarService} from "../../services/snackbar.service";
import {UsersService} from "../../services/users.service";
import {concatMap, EMPTY, forkJoin, Observable, of} from "rxjs";
import {
  ConfirmationActionDialogComponent
} from "../../components/dialogs/confirmation-action-dialog/confirmation-action-dialog.component";
import {switchMap} from "rxjs/operators";
import {MatSelectionListChange} from "@angular/material/list";
import {PermissionTypes} from "../../enum/PermissionTypes";

@Directive()
export abstract class UserDetailsComponent implements OnInit {

  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public uiStateForTemplate = UIStateEnum;
  protected screenState: UserDetailsScreenUIState = UserDetailsScreenUIState.ViewDetails;
  protected possibleRolesForChosenGame: IRole[] = [];
  protected existingRolesForGame: IRole[] = [];
  protected selectedRoles: IRole[] = [];
  protected chosenGame: IGameQueryResult | null = null;
  protected currentUser: IAdminQueryResult | null = null;
  protected userHasEditAccess: boolean = this.permissionsService.userHasPermission(PermissionTypes.ModifyAdminUsers);
  protected readonly screenStateEnum = UserDetailsScreenUIState;
  protected readonly displayedColumns: string[] = ['name', 'description', 'deactivate'];
  protected enableChoosingGame = false;

  constructor(
    protected matDialog: MatDialog,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected errorHandlingService: ErrorHandlingService,
    protected dialogFunctionService: DialogFunctionService,
    protected permissionsService: PermissionsService,
    protected snackBarService: SnackbarService,
    protected userService: UsersService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.paramMap
      .pipe(concatMap(paramMap => {
        const userId = paramMap.get('userId');

        if (userId) {
          this.currentUser = null;
          this.uiState = UIStateEnum.ShowLoading;
          return this.userService.fetchAdminByAdminId(userId);
        }
        return of(null);
      }))
      .subscribe({
        next: (user) => {
          this.currentUser = user;
          this.uiState = UIStateEnum.ShowData;
        },
        error: (err) => {
          this.uiState = UIStateEnum.ShowData;
          this.errorHandlingService.displayPageLevelErrorMessage(err);
        }
      });
  }

  onSelectionChange(event: MatSelectionListChange) {
    this.selectedRoles = event.source.selectedOptions.selected.map(option => option.value);
  }

  isRoleSelected(role: IRole): boolean {
    return this.selectedRoles.some(selectedRole => selectedRole.Id === role.Id);
  }

  onRemoveUserRoleClick(roleToRemoveP: IRole) {
    this.dialogFunctionService.setCallback(() => {
      let request = {
        roleIds: [roleToRemoveP.Id],
        targetAdminId: this.currentUser?.Id!,
        gameId: this.chosenGame?.Id
      };

      return this.permissionsService.removeUserRole(request);
    });

    const dialogData = {
      title: 'Remove Role',
      message: `Are you sure you want to remove the ${roleToRemoveP.Name} role from ${this.currentUser?.FirstName} ${this.currentUser?.LastName}?`,
      successMessage: 'Successfully removed user role',
    };

    const dialogRef = this.matDialog.open(ConfirmationActionDialogComponent, {
      data: dialogData,
      width: '650px'
    });

    dialogRef.afterClosed()
      .pipe(concatMap(updateSuccessful => updateSuccessful ? this.refreshUserAndRoles() : EMPTY))
      .subscribe({
        next: ([adminResult, possibleRoles]) => {
          this.recalculateUserAndRolesAfterUpdate(adminResult, possibleRoles);
          this.snackBarService.openSuccessfulSnackBar('Successfully removed user role(s)');
        },
        error: () => {
          this.uiState = UIStateEnum.ShowData;
          this.snackBarService.openErrorSnackBar('Failed to remove user role(s). Please try again or contact a system admin');
        }
      });
  }

  onUpdateUserRolesClick() {
    if (!this.currentUser || !this.chosenGame) {
      this.snackBarService.openErrorSnackBar("Invalid user or game selection.");
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;

    const payload = {
      targetAdminId: this.currentUser.Id,
      roleIds: this.selectedRoles.map(role => role.Id),
      gameId: this.chosenGame.Id,
      adminEmail: this.currentUser.Email,
      adminFirstName: this.currentUser.FirstName,
      adminLastName: this.currentUser.LastName,
      gameName: this.chosenGame.Name ?? '',
      redirectUrl: `${window.location.origin}/login`
    };

    this.permissionsService.addNewUserRole(payload).pipe(
      switchMap(() => this.refreshUserAndRoles())
    ).subscribe({
      next: ([adminResult, allPossibleRoles]) => {
        this.snackBarService.openSuccessfulSnackBar('Successfully added new user role(s)');
        this.recalculateUserAndRolesAfterUpdate(adminResult, allPossibleRoles);
      },
      error: () => {
        this.uiState = UIStateEnum.ShowData;
        this.snackBarService.openErrorSnackBar('Failed to add new user role(s). Please try again or contact a system admin');
      }
    });
  }

  private refreshUserAndRoles(): Observable<[IAdminQueryResult, IRole[]]> {
    return forkJoin([this.userService.fetchAdminByAdminId(this.currentUser!.Id), this.permissionsService.fetchPossibleRoles(this.chosenGame?.Id)]);
  }

  protected updateUiForChosenGame() {
    this.existingRolesForGame = this.currentUser?.Roles.find(role => role.GameId == this.chosenGame?.Id)?.GameRoles ?? [];
    this.possibleRolesForChosenGame = [];

    this.uiState = UIStateEnum.ShowRequestProcessing;

    this.permissionsService.fetchPossibleRoles(this.chosenGame?.Id).subscribe({
      next: (res) => {
        // filter out roles that are in this.existingRolesForGame
        this.possibleRolesForChosenGame = res.filter(role => !this.existingRolesForGame.some(existingRole => existingRole.Id === role.Id));
        let chosenGameRoles = this.currentUser?.Roles.find((res) => res.GameId == this.chosenGame?.Id);

        if (chosenGameRoles && chosenGameRoles.GameRoles?.length > 0) {
          this.selectedRoles = chosenGameRoles.GameRoles;
        }

        this.uiState = UIStateEnum.ShowData;
      },
      error: () => {
        this.snackBarService.openErrorSnackBar('Looks like there was an error fetching your available roles.');
        this.uiState = UIStateEnum.ShowData;
      }
    })
  }

  private recalculateUserAndRolesAfterUpdate(updatedAdminP: IAdminQueryResult, possibleRolesP: IRole[]) {
    this.currentUser = updatedAdminP;
    this.existingRolesForGame = this.currentUser?.Roles.find(role => role.GameId == this.chosenGame?.Id)?.GameRoles ?? [];
    this.possibleRolesForChosenGame = possibleRolesP.filter(role => !this.existingRolesForGame.some(existingRole => existingRole.Id === role.Id));
    this.selectedRoles = [];
    this.uiState = UIStateEnum.ShowData;
  }
}
