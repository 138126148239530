import {Directive, OnInit, ViewChild} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

import {Router} from "@angular/router";
import {ISupportRequestPagedResponse} from "../../../interfaces/player/ISupportRequestPagedResponse";
import {ISupportRequest} from "../../../interfaces/player/ISupportRequest";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {IAdminQueryResult} from "../../../interfaces/IAdminQueryResult";
import {SupportRequestService} from "../../../services/support-request.service";
import {DateService} from "../../../services/date.service";
import {GameService} from "../../../services/game.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {SupportDeskColDefs} from "../support-desk-column-defs";
import {ActiveUserService} from "../../../services/active-user.service";
import {ISupportRequestFilter} from "../../../interfaces/ISupportRequestFilter";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";
import {DateTime} from "luxon";
import {IDropdownOption} from "../../../interfaces/IDropDownOptions";
import {FilterableDropdownComponent} from "../../../components/filterable-dropdown/filterable-dropdown.component";
import {SupportDeskUIType} from "../../../enum/SupportDeskUIType";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {IAdminWithRolesAsString} from "../../../interfaces/IAdminWithRolesAsString";

@Directive()
export abstract class BaseSupportDeskComponent implements OnInit {

  @ViewChild(FilterableDropdownComponent) assigneeDropdownFilter!: FilterableDropdownComponent;

  public supportDeskUITypes = SupportDeskUIType;
  public chosenSupportDeskUIType: SupportDeskUIType = SupportDeskUIType.MyRequests;
  public showFilter = false;
  public assignedSupportRequestsCount$ = this.supportRequestService.mySupportRequests$;
  public pagedSupportRequestResponse: ISupportRequestPagedResponse | null = null;
  public supportRequests$: Observable<ISupportRequest[]> = this.supportRequestService.searchedSupportRequests$.pipe(
    map((response) => {
      this.pagedSupportRequestResponse = response;
      this.updatePaginator();
      return response.Data;
    })
  );

  public assigneeDropDownOptions: IDropdownOption[] = [];
  public assigneeId: string = '';

  public uiState = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public gameAdmins: IAdminWithRolesAsString[] = [];
  public games: IGameQueryResult[] = this.gameService.getAllAvailableGames();

  public columnDefs = this.supportDeskColumnDefs.supportRequestColDefs;
  public errorTypes = this.supportDeskColumnDefs.errorTypes;

  public playerNameControl = new FormControl<string | null>("");
  public playerEmailControl = new FormControl<string | null>("");
  public playerIdControl = new FormControl<string | null>("");
  public gameIdControl = new FormControl<string | null>("");
  public errorTypeControl = new FormControl<number | null>(null);
  public escalatedControl = new FormControl<boolean | null>(null);
  public fromDateControl = new FormControl("");
  public toDateControl = new FormControl("");
  protected isCrossGameSearch = false;
  protected readonly permissionTypes = PermissionTypes;

  public filterForm = new FormGroup({
    playerName: this.playerNameControl,
    playerEmail: this.playerEmailControl,
    playerId: this.playerIdControl,
    gameId: this.gameIdControl,
    errorType: this.errorTypeControl,
    escalated: this.escalatedControl,
    fromDate: this.fromDateControl,
    toDate: this.toDateControl,
  });

  fromNumber: number = 0;
  toNumber: number = 0;
  totalRecords: number = 0;
  pageNumber: number = 0;
  totalPages: number = 0;

  protected abstract fetchAdmins(): void;

  protected abstract onRowClicked(paramsP: ISupportRequest): void;

  constructor(
    protected supportRequestService: SupportRequestService,
    protected dateService: DateService,
    protected gameService: GameService,
    protected router: Router,
    protected errorHandlingService: ErrorHandlingService,
    protected snackBarService: SnackbarService,
    protected supportDeskColumnDefs: SupportDeskColDefs,
    protected activeUserService: ActiveUserService
  ) {
  }

  ngOnInit(): void {
  }

  getEscalatedRequests(){
    this.resetForm();
    this.getPaginatedResponse(1, true);
    this.chosenSupportDeskUIType = SupportDeskUIType.Escalated;
  }

  getFilteredRequests(){
    this.resetForm();
    this.getPaginatedResponse(1);
    this.chosenSupportDeskUIType = SupportDeskUIType.FilterAll;
  }

  onFormSubmit() {
    if (!this.filterForm.valid) {
      this.filterForm.markAllAsTouched();
      return;
    }
    this.getPaginatedResponse(1);
  }

  onResetClick() {
    this.resetForm();
    this.getPaginatedResponse(1);
  }

  resetForm() {
    this.filterForm.reset({
      playerName: "",
      playerEmail: "",
      playerId: "",
      gameId: this.isCrossGameSearch ? this.gameIdControl.value! : this.gameService.activeGame().Id,
      errorType: null,
      escalated: null,
      fromDate: DateTime.now().minus({week: 2}).toString(),
      toDate: DateTime.now().toString(),
    });

    this.assigneeId = '';

    if(this.assigneeDropdownFilter) {
      this.assigneeDropdownFilter.resetSelection();
      this.updateDropDownOptions();
    }
  }

  getPaginatedResponse(pageNumberP: number, escalationOverrideP: boolean = false) {
    let filter: ISupportRequestFilter = {
      PlayerName: this.playerNameControl.value!,
      PlayerEmail: this.playerEmailControl.value!,
      PlayerId: this.playerIdControl.value!,
      // if cross game use game id from form, otherwise used stored
      GameId: this.isCrossGameSearch ? this.gameIdControl.value! : this.gameService.activeGame().Id,
      ErrorType: this.errorTypeControl.value!,
      Assignee: this.assigneeId,
      FromDate: this.fromDateControl.value!,
      ToDate: this.toDateControl.value!,
      Escalated: escalationOverrideP ? true : this.escalatedControl.value!,
      PageNumber: pageNumberP,
      PageSize: 10,
    };

    this.uiState = UIStateEnum.ShowLoading;
    this.supportRequestService.getSupportRequests(filter).subscribe({
      next: (res) => {
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      },
    });
  }

  updatePaginator() {
    if (!this.pagedSupportRequestResponse) return;

    this.pageNumber = this.pagedSupportRequestResponse.PageNumber;
    this.totalPages = this.pagedSupportRequestResponse.TotalPages;
    this.totalRecords = this.pagedSupportRequestResponse.TotalRecords;

    let toNumber = this.pageNumber * this.pagedSupportRequestResponse.PageSize;
    this.toNumber = Math.min(toNumber, this.totalRecords);
    this.fromNumber = Math.max(1, this.toNumber - this.pagedSupportRequestResponse.PageSize + 1);
  }

  fillWithActiveUserId() {
    this.assigneeId = this.activeUserService.activeUser().Id;
  }

  updateDropDownOptions() {
    this.assigneeDropDownOptions = this.gameAdmins.map((res) => {
      return {
        name: `${res.FirstName} ${res.LastName}`,
        id: res.Id
      };
    })
  }

  onBtFirst() {
    if (this.pageNumber === 1) {
      return;
    }

    this.getPaginatedResponse(1);
  }

  onBtLast() {
    if ((this.pageNumber + 1) > this.totalPages) {
      return;
    }

    this.getPaginatedResponse(this.pagedSupportRequestResponse?.TotalPages!);
  }

  onBtNext() {
    if ((this.pageNumber + 1) > this.totalPages) {
      return;
    }

    this.getPaginatedResponse(this.pageNumber + 1);
  }

  onBtPrevious() {
    if (this.pageNumber === 1) {
      return;
    }

    this.getPaginatedResponse(this.pageNumber - 1);
  }

  displayErrorType(errorTypeP: number) {
    return this.supportDeskColumnDefs.errorTypes[errorTypeP - 1].name;
  }
}
