import {Component} from '@angular/core';
import {UIStateEnum} from "../../../../enum/UIStateEnum";
import {ReactiveFormsModule} from "@angular/forms";
import {SupportRequestService} from "../../../../services/support-request.service";
import {DateService} from "../../../../services/date.service";
import {GameService} from "../../../../services/game.service";
import {Router} from "@angular/router";
import {ErrorHandlingService} from "../../../../services/error-handling.service";
import {SnackbarService} from "../../../../services/snackbar.service";
import {SupportDeskColDefs} from "../../support-desk-column-defs";
import {ActiveUserService} from "../../../../services/active-user.service";
import {BaseSupportDeskComponent} from "../base-support-desk-search.component";
import {AgGridAngular} from "ag-grid-angular";
import {AsyncPipe, DatePipe, NgClass} from "@angular/common";
import {MatButton, MatIconButton} from "@angular/material/button";
import {
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
  MatEndDate,
  MatStartDate
} from "@angular/material/datepicker";
import {MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {MatTooltip} from "@angular/material/tooltip";
import {ISupportRequest} from "../../../../interfaces/player/ISupportRequest";
import {DateTime} from "luxon";
import {
  ColComponent,
  ContainerComponent,
  ListGroupDirective,
  ListGroupItemDirective,
  RowComponent
} from "@coreui/angular";
import {FilterableDropdownComponent} from "../../../../components/filterable-dropdown/filterable-dropdown.component";
import {MatTab, MatTabContent, MatTabGroup} from "@angular/material/tabs";
import {HasPermissionDirective} from "../../../../directives/permissions/has-permission";

@Component({
  selector: 'app-game-admin-cross-game-support-desk-search',
  standalone: true,
  imports: [
    MatTabGroup,
    MatTab,
    MatTabContent,
    AgGridAngular,
    AsyncPipe,
    MatButton,
    MatDateRangeInput,
    MatDateRangePicker,
    MatDatepickerToggle,
    MatEndDate,
    MatFormField,
    MatHint,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MatStartDate,
    MatSuffix,
    MatTooltip,
    ReactiveFormsModule,
    NgClass,
    ListGroupDirective,
    ListGroupItemDirective,
    DatePipe,
    ContainerComponent,
    RowComponent,
    ColComponent,
    FilterableDropdownComponent,
    HasPermissionDirective
  ],
  templateUrl: '../shared-support-desk-search.html',
  styleUrl: './game-admin-support-desk-search.component.css'
})
export class GameAdminSupportDeskSearchComponent extends BaseSupportDeskComponent {
  constructor(
    supportRequestService: SupportRequestService,
    dateService: DateService,
    gameService: GameService,
    router: Router,
    errorHandlingService: ErrorHandlingService,
    snackBarService: SnackbarService,
    supportDeskColumnDefs: SupportDeskColDefs,
    activeUserService: ActiveUserService
  ) {
    super(supportRequestService, dateService, gameService, router, errorHandlingService, snackBarService, supportDeskColumnDefs, activeUserService);
  }

  override ngOnInit() {
    this.fromDateControl.setValue(DateTime.now().minus({week: 2}).toString())
    this.toDateControl.setValue(DateTime.now().toString())

    this.fetchAdmins();
    this.getPaginatedResponse(1);
  }

  onRowClicked(paramsP: ISupportRequest) {
    if (!paramsP) {
      this.snackBarService.openErrorSnackBar("Error retrieving data. Please try again.");
      return;
    }
    const gameId = this.gameService.activeGame().Id;

    this.router.navigateByUrl(`${gameId}/game-admin-view-support-request/${paramsP.Id}`);
  }

  fetchAdmins() {
    this.uiState = UIStateEnum.ShowLoading;
    this.supportRequestService.fetchAdminsToAssignToSupportRequest(this.gameService.activeGame().Id).subscribe({
      next: (result) => {
        this.uiState = UIStateEnum.ShowData;
        this.gameAdmins = result;
        this.updateDropDownOptions();
      },
      error: () => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage("Failed to fetch admins");
      },
    });
  }
}
