@if (activeGame) {
  <c-row>
    <c-col md="3">
      <div cListGroup>
        <button cListGroupItem
                data-testid="general-info-tab"
                (click)="updateReportingScreen(reportingScreenUIState.MultipleDrawReporting)"
                [active]="chosenReportingView === reportingScreenUIState.MultipleDrawReporting">
          <div>
            <h5 class="mb-1">Multiple Draw Reporting</h5>
            <p>generate a comprehensive financial report that spans multiple draws</p>
          </div>
        </button>

        <button cListGroupItem
                data-testid="causable-suspensions-tab"
                (click)="updateReportingScreen(reportingScreenUIState.SingleDrawReporting)"
                [active]="chosenReportingView === reportingScreenUIState.SingleDrawReporting">
          <div>
            <h5 class="mb-1">Single Draw Reporting</h5>
            <p>generate a report for one specific draw</p>
          </div>
        </button>

        <button cListGroupItem
                *hasPermission="permissionTypes.ViewGameCharitySupporterBreakdown"
                data-testid="player-numbers-tab"
                (click)="updateReportingScreen(reportingScreenUIState.CharityReport)"
                [active]="chosenReportingView === reportingScreenUIState.CharityReport">
          <div>
            <h5 class="mb-1">Charity Support Reporting</h5>
            <p>generate a breakdown of supports per charity for {{ activeGame?.Name }}</p>
          </div>
        </button>
      </div>
    </c-col>
    <c-col>
      @switch (chosenReportingView) {
        @case (reportingScreenUIState.MultipleDrawReporting) {

          <mat-card>
            <mat-card-header>
              <mat-card-title>Multiple Draw Reporting</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <app-date-range-reporting [gameSetter]="activeGame"></app-date-range-reporting>
            </mat-card-content>
          </mat-card>
        }
        @case (reportingScreenUIState.CharityReport) {
          <mat-card>
            <mat-card-header>
              <mat-card-title>Charity Support Breakdown Report</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="margin-bottom-med">This is a report stating the current supporter breakdown
                for {{ activeGame?.Name }} charities.
              </div>
              <ul>
                <li><span class="text-underline">Active Supporters</span> are players who currently are pledged to the
                  charity.
                </li>
                <li><span class="text-underline">Inactive Supporters</span> are players who were pledged to the charity,
                  but are no longer pledged.
                </li>
                <li><span class="text-underline">Autoplay Supporters</span> are players who are pledged to the charity
                  and
                  have their numbers autoplayed.
                </li>
              </ul>

              <app-game-charity-supporter-breakdown [gameSetter]="activeGame"></app-game-charity-supporter-breakdown>
            </mat-card-content>
          </mat-card>
        }
        @case (reportingScreenUIState.SingleDrawReporting) {
          <mat-card>
            <mat-card-header>
              <mat-card-title>Reporting by Draw</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <mat-stepper #stepper>
                <mat-step data-testid="per-game-report-step-one">
                  <ng-template matStepLabel>Choose draw to generate reports</ng-template>
                  <form>
                    <app-choose-game-instance
                      (chosenGameInstanceEvent)="updateChosenGameInstance($event, stepper)"
                      (resetGameInstanceEvent)="resetActiveGameInstance(stepper)"
                      [gameIdSetter]="activeGame.Id"></app-choose-game-instance>
                  </form>
                </mat-step>
                <mat-step data-testid="per-game-report-step-two">
                  <ng-template matStepLabel>View reports</ng-template>
                  <button mat-raised-button (click)="resetActiveGameInstance(stepper)"
                          data-testid="per-game-report-view-report-back-button">
                    <mat-icon>arrow_back_ios</mat-icon>
                    Back
                  </button>

                  @if (chosenGameInstance) {
                    <div class="margin-top-med">
                      <ng-container>
                        <mat-tab-group [(selectedIndex)]="selectedTab" dynamicHeight disableRipple
                                       data-testid="report-tab-group">
                          @if (chosenGameInstance.State === gameInstanceStatusesForTemplate.WinnerAnnounced) {
                            <mat-tab label="Standard Game Report"
                                     labelClass="standard-report-tab"
                                     data-testid="per-game-report-standard-report-tab">
                              <div class="margin-top-large">
                                <app-standard-game-report
                                  [updateChosenGameInstance]="chosenGameInstance"
                                  [gameSetter]="activeGame"></app-standard-game-report>
                              </div>
                            </mat-tab>
                          }

                          <mat-tab label="Auditing Report"
                                   labelClass="auditing-report-tab"
                                   *hasPermission="permissionTypes.ViewAuditingReport"
                                   data-testid="per-game-report-auditing-report-tab">
                            <div class="margin-top-large">
                              <app-auditing-report [updateChosenGameInstance]="chosenGameInstance"
                                                   [gameSetter]="activeGame"></app-auditing-report>
                            </div>
                          </mat-tab>
                          <mat-tab
                            labelClass="sales-per-game-report-tab"
                            label="Sales Per Game"
                            *hasPermission="permissionTypes.ViewGameSummaryReport"
                            data-testid="per-game-report-sales-per-game-tab">
                            <ng-template matTabContent>
                              <div class="margin-top-large">
                                <app-sales-per-game
                                  [setDefaultGameInstanceDrawDate]="chosenGameInstance"></app-sales-per-game>
                              </div>
                            </ng-template>
                          </mat-tab>
                          <mat-tab
                            labelClass="ticket-sales-hourly-report-tab"
                            label="Hourly Ticket Sales"
                            *hasPermission="permissionTypes.ViewHourlyTicketSalesReport"
                            data-testid="per-game-report-hourly-sales-tab">
                            <div class="margin-top-large">
                              <app-hourly-ticket-sales
                                [setDefaultGameInstanceDrawDate]="chosenGameInstance"></app-hourly-ticket-sales>
                            </div>
                          </mat-tab>
                        </mat-tab-group>
                      </ng-container>
                    </div>

                  } @else {
                    <div>
                      No draw was chosen, please select a draw to view reports
                    </div>
                  }
                </mat-step>
              </mat-stepper>
            </mat-card-content>
          </mat-card>
        }
      }
    </c-col>
  </c-row>
}
