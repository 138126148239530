import {Component, inject} from '@angular/core';
import {ActiveUserService} from "../../services/active-user.service";
import {MatListModule} from "@angular/material/list";
import {AsyncPipe} from "@angular/common";
import {RouterLink} from "@angular/router";
import {MatIcon} from "@angular/material/icon";
import {SidebarModule} from "@coreui/angular";
import {CrossGameAdminSideNav} from "./cross-game-admin-side-nav/cross-game-admin-side-nav.component";
import {GameAdminSideNavComponent} from "./game-admin-side-nav/game-admin-side-nav.component";

@Component({
  selector: 'app-side-nav',
  standalone: true,
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  imports: [
    MatListModule,
    AsyncPipe,
    RouterLink,
    MatIcon,
    SidebarModule,
    CrossGameAdminSideNav,
    GameAdminSideNavComponent
  ]
})
export class SideNavComponent {

  private activeUserService: ActiveUserService = inject(ActiveUserService);
  public userIsLoggedIn$ = this.activeUserService.userIsLoggedIn();
  public activeUser$ = this.activeUserService.selectActiveUser();

}
