<c-container class="mt-5">
  <h2>Game Instance Player Emails</h2>

  <app-choose-your-game [message]="'Choose a game to view last 10 game instance emails'"
                        (gameChosenEvent)="updateActiveGameAndGetEmails($event)"></app-choose-your-game>
  @if (chosenGame) {

    <c-row class="mb-4">
      <c-col>
        @if (uiState == uiStateForTemplate.ShowRequestProcessing) {
          <mat-spinner diameter="32" color="accent" class="center-margin"></mat-spinner>
        }

        <h3>{{ chosenGame.Name }}<br>Emails</h3>
        <canvas data-testid="draw-results-emails-line-chart" id="drawResultsEmailsLineChart"></canvas>
        <canvas data-testid="draw-results-emails-line-chart" id="pendingDrawNotificationsEmailsLineChart"></canvas>

      </c-col>
    </c-row>
  }
</c-container>
