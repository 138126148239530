import {Component, Input} from '@angular/core';
import {IDateRangeReportingData} from "../../../interfaces/reporting/IDateRangeReportingData";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {ILotteryLicenseReport} from "../../../interfaces/reporting/ILotteryLicenseReport";
import {ReportingService} from "../../../services/reporting.service";
import {
  ComponentLevelErrorMessageComponent
} from "../../display-errors/component-level-error-message/component-level-error-message.component";
import {DatePipe} from "@angular/common";
import {LoadingCardContentComponent} from "../../../shared/loading-card-content/loading-card-content.component";
import {PipesModule} from "../../../pipes/pipes.module";
import {MatListModule} from "@angular/material/list";
import {MatButton} from "@angular/material/button";
import {Content, TDocumentDefinitions} from "pdfmake/interfaces";
import * as pdfMake from "pdfmake/build/pdfmake";
import {FormatCurrencyPipe} from "../../../pipes/format-currency.pipe";
import {PdfUtilityService} from "../../../services/pdf-utility.service";
import {GameTypePipe} from "../../../pipes/game-type.pipe";

@Component({
  selector: 'app-lottery-license-report',
  standalone: true,
  templateUrl: './lottery-license-report.component.html',
  imports: [
    ComponentLevelErrorMessageComponent,
    DatePipe,
    LoadingCardContentComponent,
    PipesModule,
    MatListModule,
    MatButton
  ],
  styleUrls: ['./lottery-license-report.component.scss', '../../../pages/reports/reports.scss',]
})
export class LotteryLicenseReportComponent {
  @Input() set dateRangesSetter(valueP: IDateRangeReportingData) {
    this.dateRanges = valueP;
    this.reportingService.getLotteryLicenseReport(
      valueP.GameId,
      valueP.StartDate,
      valueP.EndDate
    ).subscribe({
      next: (comprehensiveReportResP) => {
        this.lotteryLicenseReport = comprehensiveReportResP;
        this.uiState = UIStateEnum.ShowData;
      },
      error: () => {
        this.errorMessage = 'There was an issue retrieving your lottery license report. ' +
          'Please try refreshing the page or reaching out to a system administrator.';
        this.uiState = UIStateEnum.ShowData;
      }
    })
  }

  public uiState = UIStateEnum.ShowLoading;
  public uiStateForTemplate = UIStateEnum;
  public errorMessage = '';
  public lotteryLicenseReport!: ILotteryLicenseReport;
  public dateRanges!: IDateRangeReportingData;

  constructor(private reportingService: ReportingService,
              private currencyPipe: FormatCurrencyPipe,
              private gameTypePipe: GameTypePipe,
              private pdfUtilityService: PdfUtilityService,
              private datePipe: DatePipe) {
  }

  generatePDF() {
    const contentP: Content[] = [];
    if (this.lotteryLicenseReport) {

      contentP.push({
        text: `Date Range: ${this.datePipe.transform(this.dateRanges?.StartDate, 'yyyy-MM-dd')} - ${this.datePipe.transform(this.dateRanges?.EndDate
          , 'yyyy-MM-dd')}`,
        style: this.pdfUtilityService.PdfStyles.pageTitle,
        margin: [0, 0, 0, 8]
      });

      contentP.push(this.getFinancialDetails());
      contentP.push(this.pdfUtilityService.AddLineBreak());
      contentP.push(this.getLotteryLicenses());
      contentP.push(this.pdfUtilityService.AddLineBreak());
      contentP.push(this.getWinners());
      contentP.push(this.pdfUtilityService.AddLineBreak());

      const docDef: TDocumentDefinitions = {
        pageMargins: [40, 40, 40, 40],
        footer: (currentPage, pageCount) => {
          return {
            text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
            style: this.pdfUtilityService.PdfStyles.footer
          };
        },
        content: contentP
      };

      const pdf = pdfMake.createPdf(docDef);

      pdf.open();
    }
  }

  private getFinancialDetails(): Content[] {
    const financialDetails: Content[] = [];
    financialDetails.push(this.pdfUtilityService.generateSectionTitle('Lottery License Report'));
    const columnOne = [
      this.pdfUtilityService.generateLabelValueRow('Game Name: ', `${this.lotteryLicenseReport.GameName}`),
      this.pdfUtilityService.generateLabelValueRow('Game Type: ', `${this.gameTypePipe.transform(this.lotteryLicenseReport.GameType)}`),
      this.pdfUtilityService.generateLabelValueRow('Tickets Sold #: ', `${this.lotteryLicenseReport.TicketsSold}`),
      this.pdfUtilityService.generateLabelValueRow('Total Gross Sales: ', `${this.currencyPipe.transform(this.lotteryLicenseReport.TotalGrossSales)}`),
      this.pdfUtilityService.generateLabelValueRow('Total Expenses: ', `${this.currencyPipe.transform(this.lotteryLicenseReport.TotalExpenses)}`),
      this.pdfUtilityService.generateLabelValueRow('Net Proceeds Raised for Charity: ', `${this.currencyPipe.transform(this.lotteryLicenseReport.NetProceedsForCharity)}`),
      this.pdfUtilityService.generateLabelValueRow('Total Amount Awarded To Winners (*includes any rollover funds in first winning pot of this date range.)', `${this.currencyPipe.transform(this.lotteryLicenseReport.TotalPrizesAwarded)}`),
      this.pdfUtilityService.generateLabelValueRow('Total Amount Allocated to Player Winnings (*does not include rollover amount. This is 50% of gross sales in this date range): ', `${this.currencyPipe.transform(this.lotteryLicenseReport.AmountAllocatedToPlayerWinnings)}`),
    ];

    const gameColumns: Content = {
      columns: [
        columnOne
      ]
    };
    financialDetails.push(gameColumns);
    return financialDetails;
  }

  private getLotteryLicenses(): Content[] {
    const licenseRows: Content[] = [];

    licenseRows.push(this.pdfUtilityService.generateSectionTitle('Lottery Licenses'));

    const licensesContent: Content = {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        body: [
          [
            {
              text: 'License #',
              fontSize: 10
            },
            {
              text: 'Start Date',
              fontSize: 10
            },
            {
              text: 'End Date',
              fontSize: 10
            }

          ]
        ]
      },
      margin: [0, 16, 0, 16]
    };

    if (this.lotteryLicenseReport.LicenceNumbers && this.lotteryLicenseReport.LicenceNumbers?.length > 0) {
      this.lotteryLicenseReport.LicenceNumbers.forEach((licenseP) => {
        licensesContent.table.body.push([
          licenseP.LicenceNumber, `${this.datePipe.transform(licenseP.StartDate, 'yyyy-MM-dd')}`, `${this.datePipe.transform(licenseP.EndDate, 'yyyy-MM-dd')}`
        ]);
      });
    }

    licenseRows.push(licensesContent);
    return licenseRows;
  }

  private getWinners(): Content[] {
    const winnerRows: Content[] = [];
    let winners = this.lotteryLicenseReport.Winners;

    winnerRows.push(this.pdfUtilityService.generateSectionTitle('Winners'));

    const winnerContent: Content = {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        body: [
          [
            {
              text: 'Name',
              fontSize: 10
            },
            {
              text: 'Phone',
              fontSize: 10
            },
            {
              text: 'Winnings',
              fontSize: 10
            },
            {
              text: 'Draw Date',
              fontSize: 10
            },
          ]
        ]
      },
      margin: [0, 16, 0, 16]
    };

    if (winners && winners?.length > 0) {
      winners.forEach((winnerP) => {
        winnerContent.table.body.push([
          winnerP.FirstName + ' ' + winnerP.LastName, winnerP.Phone, `${this.currencyPipe.transform(winnerP.PlayerWinnings)}`, `${this.datePipe.transform(winnerP.EndedOn, 'yyyy-MM-dd')}`
        ]);
      });
    }

    winnerRows.push(winnerContent);
    return winnerRows;
  }

}
