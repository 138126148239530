import {Component, inject} from '@angular/core';
import {MatCardModule} from "@angular/material/card";
import {AsyncPipe, DatePipe} from "@angular/common";
import {PipesModule} from "../../../pipes/pipes.module";
import {ICharityWithContributionTotals} from "../../../interfaces/charity/ICharityWithContributionTotals";
import {CharityService} from "../../../services/charity.service";
import {MatIcon} from "@angular/material/icon";
import {MatMenu, MatMenuItem} from "@angular/material/menu";
import {MatButton} from "@angular/material/button";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {ChangeCharityNameComponent} from "../../dialogs/change-charity-name/change-charity-name.component";
import {MatDialog} from "@angular/material/dialog";
import {MoveCharityCategoriesComponent} from "../../dialogs/move-charity-categories/move-charity-categories.component";
import {DialogFunctionService} from 'src/app/services/dialog-function.service';
import {GameService} from 'src/app/services/game.service';
import {IDialogGenericData} from "../../../interfaces/IDialogGenericData";
import {
  ConfirmationActionDialogComponent
} from "../../dialogs/confirmation-action-dialog/confirmation-action-dialog.component";
import {Observable} from "rxjs";

@Component({
  selector: 'app-charity-general-settings',
  standalone: true,
  imports: [
    MatCardModule,
    DatePipe,
    PipesModule,
    MatIcon,
    MatMenu,
    MatMenuItem,
    MatButton,
    HasPermissionDirective,
    AsyncPipe
  ],
  templateUrl: './charity-general-settings.component.html',
  styleUrl: './charity-general-settings.component.css'
})
export class CharityGeneralSettingsComponent {

  private charityService: CharityService = inject(CharityService);
  private matDialog: MatDialog = inject(MatDialog);
  private dialogFunctionService: DialogFunctionService = inject(DialogFunctionService);
  
  protected activeCharity$: Observable<ICharityWithContributionTotals | undefined> = this.charityService.activeCharity$;
  protected readonly PermissionTypes = PermissionTypes;

  public openChangeNameDialog() {
    this.matDialog.open(ChangeCharityNameComponent, {
      width: '650px'
    });
  }

  public onChangeCategoriesClick(chosenCategoryP: ICharityWithContributionTotals) {
    this.matDialog.open(MoveCharityCategoriesComponent, {
      width: '650px',
      data: chosenCategoryP
    });
  }

  public onDeactivateCharityClick(charityP: ICharityWithContributionTotals) {
    this.dialogFunctionService.setCallback(() => {
      return this.charityService.deactivateCharity({
        charityId: charityP.Id,
        gameId: charityP.GameId,
        redirectUrl: `${window.location.origin}/login`,
      })
    });

    let reactivateCharityDialogData: IDialogGenericData = {
      title: 'Confirm Charity Deactivation',
      message: `Please confirm that you would like to deactivate <span class="bold-text">${charityP.Name}</span>`,
      dataCyTag: 'confirm-deactivate-charity-button',
      successMessage: 'Successfully deactivated charity',
      extraDetails: 'After deactivating this charity, all players with active charity pledges using this charity, will have their pledges deactivated. These players will receive a notification of these changes and how to proceed.'
    };

    this.matDialog.open(ConfirmationActionDialogComponent, {
      data: reactivateCharityDialogData,
      width: '650px'
    });
  }

  public onReactivateCharityClick(charityP: ICharityWithContributionTotals) {
    this.dialogFunctionService.setCallback(() => {
      return this.charityService.reactivateCharity({
        charityId: charityP.Id,
        gameId: charityP.GameId
      })
    });

    let reactivateCharityDialogData: IDialogGenericData = {
      title: 'Confirm Charity Reactivation',
      message: `Please confirm that you would like to reactivate <span class="bold-text">${charityP.Name}</span>`,
      dataCyTag: 'confirm-reactivate-charity-button',
      successMessage: 'Successfully reactivated charity',
      extraDetails: 'Reactivated charities will immediately appear under the associated category in the game.'
    };

    this.matDialog.open(ConfirmationActionDialogComponent, {
      data: reactivateCharityDialogData,
      width: '650px'
    });
  }
}

