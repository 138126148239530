import {Component} from '@angular/core';
import {MatListOption, MatSelectionList} from "@angular/material/list";
import {MatButton} from "@angular/material/button";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {ChooseYourGameComponent} from "../../../components/choose-your-game/choose-your-game.component";
import {NotificationPreferencesBase} from "../notificaton-preferences-base.component";
import {ContainerComponent} from "@coreui/angular";

@Component({
  selector: 'app-cross-game-preferences',
  standalone: true,
  imports: [
    MatButton,
    MatListOption,
    MatProgressSpinner,
    MatSelectionList,
    ChooseYourGameComponent,
    ContainerComponent
  ],
  templateUrl: '../notification-preferences.html',
  styleUrl: './cross-game-preferences.component.css'
})
export class CrossGamePreferencesComponent extends NotificationPreferencesBase {
    constructor() {
      super();
      this.isCrossGame = true;
    }
}
