<c-container class="mt-5">

  <h2>Winner Payouts</h2>

  <app-choose-your-game [message]="'Choose a game to view its winner payouts'" (gameChosenEvent)="onChosenGameChange($event)"></app-choose-your-game>
  @if(chosenGame) {
    <h3 data-testid="active-game-name">{{ chosenGame.Name }} - Winners</h3>
    <ag-grid-angular
      class="ag-theme-quartz"
      rowClass="payout-row"
      [tooltipShowDelay]=0
      domLayout='autoHeight'
      data-testid="payout-table"
      [loading]="uiState == UIStateEnum.ShowLoading"
      overlayNoRowsTemplate="<span>No charities for this game</span>"
      (gridReady)="onWinnerPayoutGridReady($event)"
      [rowData]="winnerPayouts$ | async"
      [columnDefs]="winnerPayoutDefs">
    </ag-grid-angular>
  }

</c-container>
