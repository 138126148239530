import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserIsAdminGuard} from "./guards/user-is-admin-guard";
import {LogoutPage} from "./pages/auth/logout/logout.page";
import {UserIsNotAdminPageComponent} from "./pages/user-is-not-admin-page/user-is-not-admin-page.component";
import {DrawWinnerPageComponent} from "./pages/draw-winner/draw-winner-page.component";
import {
  GameAdminManageGameComponent
} from "./pages/manage-game/game-admin-manage-game/game-admin-manage-game.component";
import {PlayerSearchComponent} from "./pages/player-search/game/player-search.component";
import {GameSelectedGuard} from "./guards/game-selected-guard";
import {WinnerPayoutsComponent} from "./pages/winner-payouts/winner-payouts.component";
import {
  CrossGameManageUsersComponent
} from "./pages/manage-users/cross-game-manage-users/cross-game-manage-users.component";
import {RecoverPasswordComponent} from "./pages/recover-password/recover-password.component";
import {UpdatePasswordComponent} from "./pages/update-password/update-password.component";
import {UpdateEmailAddressComponent} from "./pages/update-email-address/update-email-address.component";
import {AuditingComponent} from "./pages/auditing/auditing.component";
import {PermissionGuard} from "./guards/permission-guard";
import {PermissionTypes} from "./enum/PermissionTypes";
import {NotAuthorizedComponent} from "./pages/not-authorized/not-authorized.component";
import {TestingPageComponent} from "./pages/testing-page/testing-page.component";
import {GameInstancesComponent} from "./pages/game-instances/game-instances.component";
import {NewGameComponent} from "./pages/new-game/new-game.component";
import {GameRulesEditorComponent} from "./pages/game-rules-editor/game-rules-editor.component";
import {HowToPlayEditorComponent} from "./pages/how-to-play-editor/how-to-play-editor.component";
import {ActiveGameInstancesComponent} from "./pages/active-game-instances/active-game-instances.component";
import {LoginGuard} from "./guards/login.guard";
import {AdminAuthCallbackPage} from "./pages/auth/admin-auth-callback/admin-auth-callback.page";
import {LoginComponent} from "./pages/auth/login/login.component";
import {CausableAdminGuard} from "./guards/causable-admin-guard";
import {
  FrequentlyAskedQuestionsComponent
} from "./pages/frequently-asked-questions/frequently-asked-questions.component";
import {LandingComponent} from "./pages/landing/landing.component";
import {CharityAdminGuard} from "./guards/charity-admin.guard";
import {
  CrossGameSupportDeskSearchComponent
} from "./pages/support-desk/search/cross-game-support-desk-search/cross-game-support-desk-search.component";
import {ManagePlayerComponent} from "./pages/manage-player/manage-player.component";
import {CheckForSingleGameAccessResolver} from "./resolvers/check-for-single-game-access.resolver";
import {ProfanityFilterManagerComponent} from "./pages/profanity-filter-manager/profanity-filter-manager.component";
import {SendGridEventTableComponent} from "./pages/send-grid-event-table/send-grid-event-table.component";
import {
  GameInstancePlayerEmailsComponent
} from "./pages/game-instance-player-emails/game-instance-player-emails.component";
import {GlobalPlayerSearchComponent} from "./pages/player-search/global/global-player-search.component";
import {
  GameAdminViewSupportRequestComponent
} from "./pages/support-desk/view/game-admin-view-support-request/game-admin-view-support-request.component";
import {
  GameAdminSupportDeskSearchComponent
} from "./pages/support-desk/search/game-admin-support-desk-search/game-admin-support-desk-search.component";
import {
  CrossGameViewSupportRequestComponent
} from "./pages/support-desk/view/cross-game-view-support-request/cross-game-view-support-request.component";
import {
  ManageGlobalPermissionsComponent
} from "./pages/manage-roles-permission/manage-global-permissions/manage-global-permissions.component";
import {
  CrossGameActiveDrawLandingComponent
} from "./pages/cross-game-active-draw-landing/cross-game-active-draw-landing.component";
import {
  CrossGameManageGameComponent
} from "./pages/manage-game/cross-game-manage-game/cross-game-manage-game.component";
import {
  GameAdminPreferencesComponent
} from "./pages/notification-preferences/game-admin-preferences/game-admin-preferences.component";
import {
  CrossGamePreferencesComponent
} from "./pages/notification-preferences/cross-game-preferences/cross-game-preferences.component";
import {
  CrossGameCharityManagementComponent
} from "./pages/manage-charities/cross-game-charity-management/cross-game-charity-management.component";
import {
  GameAdminCharityManagementComponent
} from "./pages/manage-charities/game-admin-charity-management/game-admin-charity-management.component";
import {CharityComponent} from "./pages/charity/charity.component";
import {GameAdminReportingComponent} from "./pages/reports/game-admin-reporting/game-admin-reporting.component";
import {CrossGameReportingComponent} from "./pages/reports/cross-game-reporting/cross-game-reporting.component";
import {
  CrossGameAdminUserDetailsComponent
} from "./pages/user-details/cross-game-admin-user-details/cross-game-admin-user-details.component";
import {
  GameAdminUserDetailsComponent
} from "./pages/user-details/game-admin-user-details/game-admin-user-details.component";
import {
  GameAdminManageUsersComponent
} from "./pages/manage-users/game-admin-manage-users/game-admin-manage-users.component";
import {LandingPageRedirectGuard} from "./guards/landing-page-redirect.guard";

const routes: Routes = [
  {
    path: '',
    title: 'Landing',
    canActivate: [LandingPageRedirectGuard],
    resolve: [CheckForSingleGameAccessResolver],
    component: LandingComponent
  },
  {
    path: 'not-admin',
    title: 'Not Admin',
    component: UserIsNotAdminPageComponent
  },
  {
    path: 'not-authorized',
    title: 'Not Authorized',
    component: NotAuthorizedComponent
  },
  {
    path: 'forgot-my-password',
    title: 'Recover Password',
    component: RecoverPasswordComponent
  },
  {
    path: 'auth/admin-auth-callback',
    canActivate: [LoginGuard],
    component: AdminAuthCallbackPage
  },
  {
    path: 'auth/login',
    canActivate: [LoginGuard],
    component: LoginComponent
  },
  {
    path: 'logout',
    title: 'Logout',
    canActivate: [UserIsAdminGuard],
    component: LogoutPage
  },
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },

  // admin required routes
  {
    path: '',
    canActivate: [UserIsAdminGuard],
    children: [
      {
        path: 'frequently-asked-questions',
        title: 'Frequently Asked Questions',
        canActivate: [() => PermissionGuard(PermissionTypes.ModifyFrequentlyAskedQuestions)],
        component: FrequentlyAskedQuestionsComponent
      },
      {
        path: 'cross-game-charities',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewCharities)],
        title: 'Charity Management',
        component: CrossGameCharityManagementComponent
      },
      {
        path: 'view-player-emails',
        title: 'Game Instance Player Emails',
        canActivate: [CausableAdminGuard],
        component: GameInstancePlayerEmailsComponent
      },
      {
        path: 'game-instance-game-emails',
        title: 'Game Instance Player Emails',
        canActivate: [CausableAdminGuard],
        component: GameInstancePlayerEmailsComponent
      },
      {
        path: 'game-instances',
        title: 'Game Instances',
        canActivate: [CausableAdminGuard],
        component: GameInstancesComponent
      },
      {
        path: 'new-game',
        title: 'New Game',
        canActivate: [CausableAdminGuard],
        component: NewGameComponent,
        children: [
          {
            path: ':/newGameId',
            component: NewGameComponent
          },
        ]
      },
      {
        path: 'profanity-filter-manager',
        title: 'Profanity Filter Manager',
        canActivate: [CausableAdminGuard],
        component: ProfanityFilterManagerComponent
      },
      {
        path: 'cross-game-user-details/:userId',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewAdminUsers)],
        title: 'User',
        component: CrossGameAdminUserDetailsComponent
      },
      {
        path: 'testing-page',
        title: 'Testing Page',
        component: TestingPageComponent,
        canActivate: [CausableAdminGuard]
      },
      {
        path: 'sendgrid-events',
        title: 'SendGrid Events',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewSendgridData)],
        component: SendGridEventTableComponent
      },
      {
        path: 'cross-game-game-management',
        title: 'Manage Game',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewGameSettings)],
        component: CrossGameManageGameComponent
      },
      {
        path: 'auditing',
        title: 'Auditing',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewAuditing)],
        component: AuditingComponent
      },
      {
        path: 'cross-game-support-desk-search',
        title: 'Support Desk Search',
        component: CrossGameSupportDeskSearchComponent,
        canActivate: [() => PermissionGuard(PermissionTypes.ViewSupportRequests)]
      },
      {
        path: 'cross-game-view-support-request/:supportId',
        title: 'Support Request',
        component: CrossGameViewSupportRequestComponent,
        canActivate: [() => PermissionGuard(PermissionTypes.ViewSupportRequests)]
      },
      {
        path: 'winner-payouts',
        title: 'Winner Payouts',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewWinnerPayouts)],
        component: WinnerPayoutsComponent
      },
      {
        path: 'update-password',
        title: 'Update Password',
        component: UpdatePasswordComponent
      },
      {
        path: 'update-email-address',
        title: 'Update Email Address',
        component: UpdateEmailAddressComponent
      },
      {
        path: 'charity/:charityId',
        title: 'Charity',
        canActivate: [CharityAdminGuard],
        component: CharityComponent
      },
      {
        path: 'cross-game-admin-reporting',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewReports)],
        title: 'Reports',
        component: CrossGameReportingComponent
      },
      {
        path: 'global-player-search',
        title: 'System Player Search',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewGlobalPlayerSearch)],
        component: GlobalPlayerSearchComponent
      },
      {
        path: 'manage-cross-game-users',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewAdminUsers)],
        title: 'Manage Users',
        component: CrossGameManageUsersComponent
      },
      {
        path: 'cross-game-view-draw-landing',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewAdminUsers)],
        title: 'Active Draws',
        component: CrossGameActiveDrawLandingComponent
      },
      {
        path: 'cross-game-notification-preferences',
        title: 'Notification Preferences',
        component: CrossGamePreferencesComponent
      },
      {
        path: 'manage-player/:playerId',
        title: 'Manage Game Global Player',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewGlobalPlayerSearch)],
        component: ManagePlayerComponent
      },
      {
        path: 'manage-global-permissions',
        canActivate: [CausableAdminGuard],
        title: 'Manage Global Permissions',
        component: ManageGlobalPermissionsComponent
      },
    ]
  },
  // game and admin required routes
  {
    path: ':gameId',
    canActivate: [UserIsAdminGuard, GameSelectedGuard],
    children: [
      {
        path: 'manage-game-admin-users',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewAdminUsers)],
        title: 'Manage Users',
        component: GameAdminManageUsersComponent
      },
      {
        path: 'game-admin-user-details/:userId',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewAdminUsers)],
        title: 'User',
        component: GameAdminUserDetailsComponent
      },
      {
        path: 'game-admin-notification-preferences',
        title: 'Notification Preferences',
        component: GameAdminPreferencesComponent
      },
      {
        path: 'charity/:charityId',
        title: 'Charity',
        canActivate: [CharityAdminGuard],
        component: CharityComponent
      },
      {
        path: 'game-admin-reporting',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewReports)],
        title: 'Reports',
        component: GameAdminReportingComponent
      },
      {
        path: 'draw-winner',
        title: 'Draw Winner',
        canActivate: [() => PermissionGuard(PermissionTypes.DrawGameWinner)],
        component: DrawWinnerPageComponent
      },
      {
        path: 'manage-game',
        title: 'Manage Game',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewGameSettings)],
        component: GameAdminManageGameComponent
      },
      {
        path: 'manage-player/:playerId',
        title: 'Manage Game Players',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewPlayerInfo)],
        component: ManagePlayerComponent
      },
      {
        path: 'manage-charities',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewCharities)],
        title: 'Charity Management',
        component: GameAdminCharityManagementComponent
      },
      {
        path: 'active-game-instances',
        title: 'Current Draw',
        component: ActiveGameInstancesComponent
      },
      {
        path: 'player-search',
        title: 'Player Search',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewPlayerInfo)],
        component: PlayerSearchComponent
      },
      {
        path: 'edit-game-rules',
        title: 'Edit Game Rules',
        component: GameRulesEditorComponent,
        canActivate: [() => PermissionGuard(PermissionTypes.ModifyGameSettings)]
      },
      {
        path: 'edit-how-to-play',
        title: 'Edit How To Play',
        component: HowToPlayEditorComponent,
        canActivate: [() => PermissionGuard(PermissionTypes.ModifyGameSettings)]
      },
      {
        path: 'game-admin-support-desk-search',
        title: 'Support Desk Search',
        component: GameAdminSupportDeskSearchComponent,
        canActivate: [() => PermissionGuard(PermissionTypes.ViewSupportRequests)]
      },
      {
        path: 'game-admin-view-support-request/:supportId',
        title: 'Support Request',
        component: GameAdminViewSupportRequestComponent,
        canActivate: [() => PermissionGuard(PermissionTypes.ViewSupportRequests)]
      },
    ]
  }
];

// initialNavigation: "disabled" stops navigation until we are ready (re-enabled in app component when org and auth config have been set up)
@NgModule({
  imports: [RouterModule.forRoot(routes, {initialNavigation: "disabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
