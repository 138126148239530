import {Component, inject} from '@angular/core';
import {AgGridAngular} from "ag-grid-angular";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {MatCard} from "@angular/material/card";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatTooltip} from "@angular/material/tooltip";
import {PlayersService} from "../../../services/players.service";
import {PhoneFormatPipe} from "../../../pipes/phone-format.pipe";
import {Router} from "@angular/router";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {IPlayerSearchParams} from "../../../interfaces/player/IPlayerSearchParams";
import {BasePlayerSearchComponent} from "../base-search.component";
import {GameService} from "../../../services/game.service";
import {ContainerComponent} from "@coreui/angular";

@Component({
  selector: 'app-global-game-search',
  standalone: true,
  imports: [
    AgGridAngular,
    FormsModule,
    MatButton,
    MatCard,
    MatError,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    MatTooltip,
    ReactiveFormsModule,
    ContainerComponent
  ],
  templateUrl: './global-player-search.component.html',
  styleUrl: './global-player-search.component.css'
})
export class GlobalPlayerSearchComponent extends BasePlayerSearchComponent {
  private gameService: GameService = inject(GameService);

  constructor(
    playerService: PlayersService,
    phoneFormatPipe: PhoneFormatPipe,
    errorHandlingService: ErrorHandlingService,
    router: Router
  ) {
    super(playerService, phoneFormatPipe, errorHandlingService, router);
    this.gameService.clearActiveGame();
  }

  protected getSearchParams(): IPlayerSearchParams | undefined {
    return {
      email: this.playerSearchForm.controls.email.value || '',
      lastName: this.playerSearchForm.controls.lastName.value || '',
      firstName: this.playerSearchForm.controls.firstName.value || '',
      phoneNumber: this.phoneNumberControl.value?.replace(/-/g, '') || '',
      playerId: this.playerSearchForm.controls.playerId.value || '',
    };
  }

  protected isGlobalSearch(): boolean {
    return true;
  }

  protected getPlayerManagementUrl(playerId: string): string {
    return `/manage-player/${playerId}`;
  }
}
