@if(charity) {
  <mat-card data-testid="charity-detail-section">
    <mat-card-content>
      <div class="detail-label">QR Code URL for {{ charity.Name }}</div>
      <div class="detail-value" data-testid="charity-year-to-date-raised">
        <mat-icon [matTooltip]="'click to copy'" [cdkCopyToClipboard]="charityQrUrl" class="copy-icon">
          content_copy
        </mat-icon>
        {{ charityQrUrl }}
      </div>

      <qrcode [qrdata]="charityQrUrl" [width]="256" [errorCorrectionLevel]="'M'" #qrcode></qrcode>

      <button mat-raised-button (click)="downloadQRCode(charity.Name)"
              data-testid="generate-qr-code-image">
        <mat-icon>download</mat-icon>
        Download QR Code Image
      </button>
    </mat-card-content>
  </mat-card>
}
