import {Component, inject, OnInit} from '@angular/core';
import {MatCardModule} from "@angular/material/card";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {
  MatDatepickerModule
} from "@angular/material/datepicker";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatButton} from "@angular/material/button";
import {ICharityDateRangeReport} from "../../../interfaces/charity/ICharityDateRangeReport";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {CharityService} from "../../../services/charity.service";
import {ICharityWithContributionTotals} from "../../../interfaces/charity/ICharityWithContributionTotals";
import {SnackbarService} from "../../../services/snackbar.service";
import {PipesModule} from "../../../pipes/pipes.module";
import {AsyncPipe, DatePipe} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-charity-date-range-reporting',
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    PipesModule,
    MatProgressSpinner,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCardModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatProgressSpinner,
    MatButton,
    PipesModule,
    MatIcon,
    MatTooltip
  ],
  templateUrl: './charity-date-range-reporting.component.html',
  styleUrl: './charity-date-range-reporting.component.css'
})
export class CharityDateRangeReportingComponent implements OnInit {

  private charityService: CharityService = inject(CharityService);
  private snackBarService: SnackbarService = inject(SnackbarService);
  private datePipe: DatePipe = inject(DatePipe);

  protected uiState = UIStateEnum.ShowData;
  protected uiStateForTemplate = UIStateEnum;
  protected charityDateRangeReport: ICharityDateRangeReport | null = null;
  protected charity: ICharityWithContributionTotals | undefined = this.charityService.activeCharity();

  protected startDateControl = new FormControl('', Validators.required);
  protected endDateControl = new FormControl('', Validators.required);
  public charityDatesFromGroup = new FormGroup({
    startDate: this.startDateControl,
    endDate: this.endDateControl
  });

  ngOnInit(): void {
    this.initDates();
    this.onGenerateDateRangeReport();
  }

  private initDates() {
    this.startDateControl.setValue('2020-01-01');

    // this will put the end date 2 weeks from today, which will allow the most current draw to be included
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 14);

    this.endDateControl.setValue(this.datePipe.transform(endDate, 'yyyy-MM-ddTHH:mm:ss.SSS', 'UTC'));
  }

  protected onGenerateDateRangeReport() {
    this.uiState = UIStateEnum.ShowLoading;
    this.charityDateRangeReport = null;

    this.charityService.fetchCharityDateRangeReport(this.charity!.Id, this.startDateControl.value!, this.endDateControl.value!).subscribe({
      next: (res) => {
        this.charityDateRangeReport = res;
        this.uiState = UIStateEnum.ShowData;
      },
      error: () => {
        this.snackBarService.openErrorSnackBar('Looks like there was a problem loading your report. Please try again or contact a system administrator');
        this.uiState = UIStateEnum.ShowData;
      }
    })
  }
}
