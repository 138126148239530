import {Injectable} from '@angular/core';
import {APIService} from "./api.service";
import {ISendGridEventFilter} from "../interfaces/ISendGridEventFilter";
import {HttpParams} from "@angular/common/http";
import {ISendGridEventPagedResponse} from "../interfaces/ISendGridEventPagedResponse";
import {createStore, select, setProp, withProps} from "@ngneat/elf";
import {map} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SendGridService {

  constructor(private apiService: APIService) {
  }

  private defaultSendGridEventResponse: ISendGridEventPagedResponse = {
    Data: [],
    Errors: [],
    FirstPage: 1,
    LastPage: 1,
    Message: '',
    NextPage: '',
    PageNumber: 1,
    PageSize: 1,
    PreviousPage: '',
    Succeeded: false,
    TotalPages: 1,
    TotalRecords: 0
  };

  private eventsStore = createStore(
    {name: "sendGridEventStore"},
    withProps<{sendGridEventResponse: ISendGridEventPagedResponse}>({sendGridEventResponse: this.defaultSendGridEventResponse}));

  public events$ = this.eventsStore.pipe(select(state => state.sendGridEventResponse));

  getEvents(filterParamsP: ISendGridEventFilter) {
    let query_params = new HttpParams();

    if (filterParamsP.playerId) {
      query_params = query_params.append("playerId", filterParamsP.playerId);
    }

    if (filterParamsP.templateId) {
      query_params = query_params.append("templateId", filterParamsP.templateId);
    }

    if (filterParamsP.gameId) {
      query_params = query_params.append("gameId", filterParamsP.gameId);
    }

    if (filterParamsP.event) {
      query_params = query_params.append("event", filterParamsP.event);
    }

    if (filterParamsP.email) {
      query_params = query_params.append("email", filterParamsP.email);
    }

    if (filterParamsP.fromDate) {
      query_params = query_params.append("fromDate", filterParamsP.fromDate.toDateString());
    }

    if (filterParamsP.toDate) {
      query_params = query_params.append("toDate", filterParamsP.toDate.toDateString());
    }

    if (filterParamsP.pageNumber) {
      query_params = query_params.append("pageNumber", filterParamsP.pageNumber);
    }

    if (filterParamsP.pageSize) {
      query_params = query_params.append("pageSize", filterParamsP.pageSize);
    }

    return this.apiService.MakeGetRequest<ISendGridEventPagedResponse>("sendgrid/events", query_params).pipe(map((response) => {
      this.eventsStore.update(setProp('sendGridEventResponse', response));
      return response.Data;
    }))
  }
}
