import {Injectable} from '@angular/core';
import {APIService} from "./api.service";
import {ICharitiesByCategoryResult} from "../interfaces/charity/ICharitiesByCategoryResult";
import {BehaviorSubject, concatMap, map, Observable, tap} from "rxjs";
import {IUpdateCharityCategorySortOrder} from "../interfaces/charity/IUpdateCharityCategorySortOrder";
import {IUpdateCharitySortOrder} from "../interfaces/charity/IUpdateCharitySortOrder";
import {ICharityCategory} from "../interfaces/charity/ICharityCategory";
import {ICreateCharityCommand} from "../interfaces/charity/ICreateCharityCommand";
import {IApiResponseBase} from "../interfaces/IApiResponseBase";
import {IUpdateCharityCommand} from "../interfaces/charity/IUpdateCharityCommand";
import {ICreateCharityCategoryCommand} from "../interfaces/charity/ICreateCharityCategoryCommand";
import {ICreateCharityCategoryResponse} from "../interfaces/charity/ICreateCharityCategoryResponse";
import {IUpdateCharityCategoryNameCommand} from "../interfaces/charity/IUpdateCharityCategoryNameCommand";
import {AddNewCharityComponent} from "../components/dialogs/add-new-charity/add-new-charity.component";
import {MatDialog} from "@angular/material/dialog";
import {createStore} from "@ngneat/elf";
import {
  selectActiveEntity,
  selectAllEntities,
  setActiveId,
  setEntities,
  withActiveId,
  withEntities
} from "@ngneat/elf-entities";
import {withRequestsCache} from "@ngneat/elf-requests";
import {ICharityWithContributionTotals} from "../interfaces/charity/ICharityWithContributionTotals";
import {IDeactivateCharity} from "../interfaces/charity/IDeactivateCharity";
import {IReactivateCharity} from "../interfaces/charity/IReactivateCharity";
import {IMoveCharity} from "../interfaces/charity/IMoveCharity";
import {IReactivateCharityCategory} from "../interfaces/charity/IReactivateCharityCategory";
import {IDeactivateCharityCategory} from "../interfaces/charity/IDeactivateCharityCategory";
import {ICharity} from "../interfaces/charity/ICharity";
import {ICharitySupporterTotalsReport} from "../interfaces/charity/ICharitySupporterTotalsReport";
import {ICharityDateRangeReport} from "../interfaces/charity/ICharityDateRangeReport";
import {AppConfigService} from "./app-config.service";
import {IGameQueryResult} from "../interfaces/IGameQueryResult";

@Injectable({
  providedIn: 'root'
})
export class CharityService {

  private charitiesByCategoryStore = createStore(
    {name: 'charities-by-category-state'},
    withEntities<ICharitiesByCategoryResult, 'CategoryId'>({idKey: 'CategoryId'}),
    withActiveId(),
    withRequestsCache<'charities-by-category'>()
  );

  public activeCharityBehaviourSubject: BehaviorSubject<ICharityWithContributionTotals | undefined> = new BehaviorSubject<any>(undefined);
  public activeCharity$: Observable<ICharityWithContributionTotals | undefined> = this.activeCharityBehaviourSubject.asObservable();
  public charityCategories$: Observable<ICharitiesByCategoryResult[]> = this.charitiesByCategoryStore.pipe(selectAllEntities());

  constructor(private apiService: APIService,
              private appConfigService: AppConfigService,
              private matDialog: MatDialog) {
  }

  public openAddCharityDialog(gameIdP: string) {
    this.matDialog.open(AddNewCharityComponent, {
      data: gameIdP,
      width: '650px'
    });
  }

  public charityCategoriesByGame(gameIdP: string): Observable<ICharityCategory[]> {
    return this.apiService.MakeGetRequest<{
      CharityCategories: ICharityCategory[]
    }>(`categories-by-game/${gameIdP}`)
      .pipe(map((res) => res.CharityCategories));
  }

  public clearCharityStore(): void {
    this.charitiesByCategoryStore.reset();
  }

  public activeCharity(): ICharityWithContributionTotals | undefined {
    return this.activeCharityBehaviourSubject.value;
  }

  public createNewCharity(createCharityCommandP: ICreateCharityCommand): Observable<IApiResponseBase> {
    return this.apiService.MakePostRequest<IApiResponseBase>(`charity/create-multiple`, createCharityCommandP).pipe(concatMap((createRes) => {
      return this.fetchCharitiesPerGameByCategory(createCharityCommandP.gameId).pipe(map(() => createRes));
    }));
  }

  public createNewCharityCategory(createCharityCategoryCommandP: ICreateCharityCategoryCommand): Observable<ICreateCharityCategoryResponse> {
    return this.apiService.MakePostRequest<ICreateCharityCategoryResponse>(`charity/category/create`, createCharityCategoryCommandP).pipe(concatMap((createRes) => {
      return this.fetchCharitiesPerGameByCategory(createCharityCategoryCommandP.gameId).pipe(map(() => createRes));
    }));
  }

  public updateCharity(updateCharityCommandP: IUpdateCharityCommand): Observable<ICharitiesByCategoryResult[]> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`charity/update-charity`, updateCharityCommandP).pipe(concatMap(() => {
      return this.fetchCharitiesPerGameByCategory(updateCharityCommandP.gameId).pipe(tap((charitiesByGroupP) => {
        let activeCharity = charitiesByGroupP.flatMap((charityGroup) => charityGroup.Charities).find((charityP) => charityP.Id === updateCharityCommandP.charityId);
        this.activeCharityBehaviourSubject.next(activeCharity);
      }));
    }));
  }

  public deactivateCharity(deactivateCharityCommandP: IDeactivateCharity): Observable<ICharitiesByCategoryResult[]> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`charity/deactivate`, deactivateCharityCommandP).pipe(concatMap(() => {
      return this.updateActiveCharityState(deactivateCharityCommandP.charityId, deactivateCharityCommandP.gameId);
    }));
  }

  public moveCharity(moveCharityCommandP: IMoveCharity): Observable<ICharitiesByCategoryResult[]> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`charity/move`, moveCharityCommandP).pipe(concatMap(() => {
      return this.updateActiveCharityState(moveCharityCommandP.charityId, moveCharityCommandP.gameId);
    }));
  }

  public reactivateCharity(reactivateCharityCommandP: IReactivateCharity): Observable<ICharitiesByCategoryResult[]> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`charity/reactivate`, reactivateCharityCommandP).pipe(concatMap(() => {
      return this.updateActiveCharityState(reactivateCharityCommandP.charityId, reactivateCharityCommandP.gameId)
    }));
  }

  public reactivateCharityCategory(reactivateCharityCommandP: IReactivateCharityCategory): Observable<ICharitiesByCategoryResult[]> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`charity/category-reactivate`, reactivateCharityCommandP).pipe(concatMap(() => {
      return this.fetchCharitiesPerGameByCategory(reactivateCharityCommandP.gameId);
    }))
  }

  public deactivateCharityCategory(deactivateCharityCommandP: IDeactivateCharityCategory): Observable<ICharitiesByCategoryResult[]> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`charity/category-deactivate`, deactivateCharityCommandP).pipe(concatMap(() => {
      return this.fetchCharitiesPerGameByCategory(deactivateCharityCommandP.gameId);
    }))
  }

  public updateCharityCategoryName(updateCharityCommandP: IUpdateCharityCategoryNameCommand): Observable<ICharitiesByCategoryResult[]> {
    return this.apiService.MakePutRequest('charity/category', updateCharityCommandP).pipe(concatMap(() => {
      return this.fetchCharitiesPerGameByCategory(updateCharityCommandP.gameId);
    }))
  }

  private updateActiveCharityState(charityIdP: string, gameIdP: string) {
    return this.fetchCharitiesPerGameByCategory(gameIdP).pipe(tap((charitiesByGroupP) => {
      let activeCharity = charitiesByGroupP.flatMap((charityGroup) => charityGroup.Charities).find((charityP) => charityP.Id === charityIdP);
      this.activeCharityBehaviourSubject.next(activeCharity);
    }));
  }

  public fetchActiveCharitiesForGame(gameIdP: string): Observable<ICharity[]> {
    return this.apiService.MakeGetRequest<ICharity[]>(`active-charities-for-game/${gameIdP}`)
      .pipe(map((resultP) => {
        return resultP;
      }));
  }

  public fetchCharitiesById(charityIdP: string): Observable<ICharityWithContributionTotals> {
    return this.apiService.MakeGetRequest<ICharityWithContributionTotals>(`charity/${charityIdP}`)
      .pipe(map((resultP) => {
        this.activeCharityBehaviourSubject.next(resultP);
        return resultP;
      }));
  }

  public fetchCharitySupporters(charityIdP: string): Observable<ICharitySupporterTotalsReport> {
    return this.apiService.MakeGetRequest<ICharitySupporterTotalsReport>(`report/charity-supporters/${charityIdP}`);
  }

  public fetchCharityDateRangeReport(charityIdP: string, startDateP: string, endDateP: string): Observable<ICharityDateRangeReport> {
    return this.apiService.MakeGetRequest<ICharityDateRangeReport>(`report/charity-date-range-report/${charityIdP}/${startDateP}/${endDateP}`);
  }

  public fetchCharityGameInstanceReport(charityIdP: string, gameInstanceIdP: string): Observable<ICharityDateRangeReport> {
    return this.apiService.MakeGetRequest<ICharityDateRangeReport>(`report/charity-game-instance-report/${charityIdP}/${gameInstanceIdP}`);
  }

  public fetchCharitiesPerGameByCategory(gameIdP: string): Observable<ICharitiesByCategoryResult[]> {
    return this.apiService.MakeGetRequest<{
      CharitiesByCategory: ICharitiesByCategoryResult[]
    }>(`charities-by-category/for-game/${gameIdP}/${true}`)
      .pipe(map((res) => {
        const categories = res.CharitiesByCategory.sort((a, b) => a.Category.SortOrder - b.Category.SortOrder);
        this.charitiesByCategoryStore.update(setEntities(categories));
        return res.CharitiesByCategory;
      }));
  }

  public setActiveCharityCategory(idP: string): void {
    this.charitiesByCategoryStore.update(setActiveId(idP));
  }

  public clearActiveCharityCategory(): void {
    this.charitiesByCategoryStore.update(setActiveId(null));
  }

  public selectActiveCategory(): Observable<ICharitiesByCategoryResult | undefined> {
    return this.charitiesByCategoryStore.pipe(selectActiveEntity());
  }

  public updateCharityCategorySortOrder(updateSortOrderP: IUpdateCharityCategorySortOrder): Observable<ICharitiesByCategoryResult[]> {
    return this.apiService.MakePutRequest('charity/update-charity-category-sort-order', updateSortOrderP).pipe(concatMap(() => {
      return this.fetchCharitiesPerGameByCategory(updateSortOrderP.gameId);
    }))
  }

  public updateCharitySortOrder(updateSortOrderP: IUpdateCharitySortOrder): Observable<ICharitiesByCategoryResult[]> {
    return this.apiService.MakePutRequest('charity/update-charity-sort-order', updateSortOrderP).pipe(concatMap(() => {
      return this.fetchCharitiesPerGameByCategory(updateSortOrderP.gameId);
    }))
  }

  public generateQrCodeUrl(charityIdP: string, activeGameP: IGameQueryResult) {
    let baseUrl = this.appConfigService.generateWebappDomainUrl(activeGameP.Subdomain, activeGameP.Type);
    let charityIdSection = `charityqr?Id=${charityIdP}`;
    return baseUrl + charityIdSection;
  }

}
