import {Component, OnInit} from '@angular/core';
import {AuditingService} from "../../services/auditing.service";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {GameService} from "../../services/game.service";
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {AdminAuditTableComponent} from "../../components/audit-tables/admin-audit-table/admin-audit-table.component";
import {PlayerAuditTableComponent} from "../../components/audit-tables/player-audit-table/player-audit-table.component";
import {SystemAuditTableComponent} from "../../components/audit-tables/system-audit-table/system-audit-table.component";
import {HasPermissionDirective} from "../../directives/permissions/has-permission";
import {ContainerComponent} from "@coreui/angular";

@Component({
  selector: 'app-auditing',
  templateUrl: './auditing.component.html',
  standalone: true,
  imports: [
    MatTabGroup,
    MatTab,
    AdminAuditTableComponent,
    PlayerAuditTableComponent,
    SystemAuditTableComponent,
    HasPermissionDirective,
    ContainerComponent
  ],
  styleUrls: ['./auditing.component.css']
})
export class AuditingComponent implements OnInit {

  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public uiStateForTemplate = UIStateEnum;
  protected readonly PermissionTypes = PermissionTypes;

  constructor(private auditingService: AuditingService,
              private errorHandlingService: ErrorHandlingService) {
  }

  ngOnInit(): void {
    this.auditingService.retrieveAllEventTypes().subscribe({
      next: () => this.uiState = UIStateEnum.ShowData,
      error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    })
  }

}
