import {Component, OnInit} from '@angular/core';
import {UIStateEnum} from "../../enum/UIStateEnum";
import {ActiveUserService} from "../../services/active-user.service";
import {ProfanityService} from "../../services/profanity.service";
import {MatButton} from "@angular/material/button";
import {SnackbarService} from "../../services/snackbar.service";
import {IUpdateBlackList} from "../../interfaces/IUpdateBlackList";
import {MatFormField, MatInput} from "@angular/material/input";
import {MatHint, MatLabel} from "@angular/material/form-field";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {ContainerComponent} from "@coreui/angular";

@Component({
  selector: 'app-profanity-filter-manager',
  standalone: true,
  imports: [
    MatButton,
    MatInput,
    MatFormField,
    MatLabel,
    ReactiveFormsModule,
    MatHint,
    ContainerComponent
  ],
  templateUrl: './profanity-filter-manager.component.html',
  styleUrl: './profanity-filter-manager.component.css'
})
export class ProfanityFilterManagerComponent implements OnInit{
  public uiState = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;

  public blackListValue = "";
  public blackListControl = new FormControl<string | null>("");

  constructor(private activeUserService: ActiveUserService,
              private profanityService: ProfanityService,
              private snackBarService: SnackbarService) {
  }

  ngOnInit() {
    this.uiState = UIStateEnum.ShowLoading;
    this.profanityService.getBlackList().subscribe({
      next: (res) => {
        this.uiState = UIStateEnum.ShowData;
        this.blackListValue = res.BlackList;
      },
      error: err => {
        this.uiState = UIStateEnum.ShowData;
        this.snackBarService.openErrorSnackBar("Failed to fetch the profanity blacklist");
      }
    });
  }

  updateBlackList() {
    this.uiState = UIStateEnum.ShowLoading;

    let command : IUpdateBlackList = {
      BlackList: this.blackListControl.value!,
      AdminId: this.activeUserService.activeUser().Id
    };

    this.profanityService.updateBlackList(command).subscribe({
      next: (res) => {
        this.uiState = UIStateEnum.ShowData;
        this.snackBarService.openSuccessfulSnackBar('Successfully Updated Black List');
      },
      error: err => {
        this.uiState = UIStateEnum.ShowData;
        this.snackBarService.openErrorSnackBar("Failed to Update Black List");
      }
    });
  }
}
