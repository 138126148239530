<mat-card class="margin-bottom-med">
  <mat-card-header>
    <mat-card-title>General Info</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    @if(playerInfo) {
      <div>
        <div>
          <div class="detail-label">Player Name</div>
          <div class="detail-value"
               data-testid="manage-player-name">{{ playerInfo.FirstName }} {{ playerInfo.LastName }}
          </div>
        </div>

        <div>
          <div class="detail-label">E-mail</div>
          <div class="detail-value" data-testid="manage-player-email">{{ playerInfo.Email }}</div>
        </div>

        <div>
          <div class="detail-label">Player Status</div>
          <div class="detail-value" data-testid="manage-player-status">
                <span
                  [class]="playerInfo.Active ? 'green-text': 'red-text'">{{ playerInfo.Active ? 'Active' : 'Deactivated' }}</span>
          </div>
        </div>

        <div>
          <div class="detail-label">Phone</div>
          <div class="detail-value" data-testid="manage-player-phone">{{ playerInfo.Phone }}</div>
        </div>

        <div>
          <div class="detail-label">Player Since</div>
          <div class="detail-value"
               data-testid="manage-player-created-on">{{ playerInfo.CreatedOn | date: 'yyyy-MM-dd' }}
          </div>
        </div>

        <div>
          <div class="detail-label">Agreed To Terms Of Use & Privacy Policy On</div>
          <div data-testid="manage-player-agreed-to-tou-privacy-policy-on"
               class="detail-value">{{ playerInfo.AgreedToTermsOfUsePrivacyPolicyOn ? (playerInfo.AgreedToTermsOfUsePrivacyPolicyOn | date: 'yyyy-MM-dd') : 'Has not agreed' }}
          </div>
        </div>
        <div>
          <div class="flex-col mb-3">
            <button mat-stroked-button
                    *hasPermission="PermissionTypes.ViewPlayerAudits"
                    (click)="onViewPlayerAuditsClick()"
                    data-testid="view-player-audits-button"
                    class="margin-top-med">
              <mat-icon>edit</mat-icon>
              View Player Audits
            </button>

            <button class="green-text margin-top-med"
                    *hasPermission="PermissionTypes.AdminUpdatePlayerBasicInfo"
                    (click)="onUpdateBasicInfoClick()"
                    data-testid="update-player-basic-info-button"
                    mat-stroked-button>
              <mat-icon>refresh</mat-icon>
              Update Player Basic Info
            </button>

            @if (playerInfo.Active) {
              <button class="red-text margin-top-med"
                      *hasPermission="PermissionTypes.UpdatePlayerActivationStatus"
                      (click)="onDeactivatePlayerClick()"
                      data-testid="deactivate-player-button"
                      mat-stroked-button>
                <mat-icon>do_disturb</mat-icon>
                Deactivate player
              </button>
            } @else {
              <button class="green-text margin-top-med"
                      *hasPermission="PermissionTypes.UpdatePlayerActivationStatus"
                      (click)="onReactivatePlayerClick()"
                      data-testid="reactivate-player-button"
                      mat-stroked-button>
                <mat-icon>refresh</mat-icon>
                Reactivate player
              </button>
            }
          </div>
        </div>
      </div>

    }
  </mat-card-content>
</mat-card>
