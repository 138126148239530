import {Component} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";
import {GameService} from "../../../services/game.service";
import {PlayersService} from "../../../services/players.service";
import {IPlayerSearchParams} from "../../../interfaces/player/IPlayerSearchParams";
import {Router} from "@angular/router";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {PhoneFormatPipe} from "../../../pipes/phone-format.pipe";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatTooltip} from "@angular/material/tooltip";
import {MatButton} from "@angular/material/button";
import {MatCard} from "@angular/material/card";
import {AgGridAngular} from "ag-grid-angular";
import {MatIcon} from "@angular/material/icon";
import {BasePlayerSearchComponent} from "../base-search.component";
import {ContainerComponent} from "@coreui/angular";

@Component({
  selector: 'app-player-search',
  standalone: true,
  templateUrl: './player-search.component.html',
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInput,
    MatTooltip,
    MatIcon,
    MatButton,
    MatCard,
    AgGridAngular,
    ContainerComponent
  ],
  styleUrls: ['./player-search.component.scss']
})
export class PlayerSearchComponent extends BasePlayerSearchComponent {

  constructor(
    private gameService: GameService,
    playerService: PlayersService,
    phoneFormatPipe: PhoneFormatPipe,
    errorHandlingService: ErrorHandlingService,
    router: Router
  ) {
    super(playerService, phoneFormatPipe, errorHandlingService, router);
  }

  protected getSearchParams(): IPlayerSearchParams | undefined {
    const activeGameId = this.gameService.activeGame()?.Id;
    return {
      email: this.playerSearchForm.controls.email.value || '',
      lastName: this.playerSearchForm.controls.lastName.value || '',
      firstName: this.playerSearchForm.controls.firstName.value || '',
      phoneNumber: this.phoneNumberControl.value?.replace(/-/g, '') || '',
      playerId: this.playerSearchForm.controls.playerId.value || '',
      gameId: activeGameId ?? ''
    };
  }

  protected isGlobalSearch(): boolean {
    return false;
  }

  protected getPlayerManagementUrl(playerId: string): string {
    const gameId = this.gameService.activeGame()?.Id;
    return gameId ? `${gameId}/manage-player/${playerId}` : `/manage-player/${playerId}`;
  }
}
