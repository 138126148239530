import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {PlayersService} from "../../services/players.service";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {HasPermissionDirective} from "../../directives/permissions/has-permission";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {
  ColComponent,
  ContainerComponent,
  ListGroupDirective,
  ListGroupItemDirective,
  RowComponent
} from "@coreui/angular";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {PlayerScreenState} from "../../enum/PlayerScreenState";
import {
  ManagePlayerNumbersComponent
} from "../../components/manage-player/manage-player-numbers/manage-player-numbers.component";
import {
  ManagePlayerNotesComponent
} from "../../components/manage-player/manage-player-notes/manage-player-notes.component";
import {IGeneralPlayerInfo} from "../../interfaces/player/IGeneralPlayerInfo";
import {
  ManagePlayerTransactionComponent
} from "../../components/manage-player/manage-player-transaction/manage-player-transaction.component";
import {
  ManagePlayerPledgesComponent
} from "../../components/manage-player/manage-player-pledges/manage-player-pledges.component";
import {MatIconModule} from "@angular/material/icon";
import {
  ManagePlayerSuspensionsComponent
} from "../../components/manage-player/manage-player-suspensions/manage-player-suspensions.component";
import {IPlayerSuspension} from "../../interfaces/player/IPlayerSuspension";
import {concatMap, of} from "rxjs";
import {PermissionsService} from "../../services/permissions.service";
import {
  ManagePlayerSendGridInfoComponent
} from "../../components/manage-player/manage-player-send-grid-info/manage-player-send-grid-info.component";
import {ActiveUserService} from "../../services/active-user.service";
import {
  UserHasCausableAdminAccessDirective
} from "../../directives/permissions/user-has-causable-admin-access.directive";
import {
  ManagePlayerGeneralInfoComponent
} from "../../components/manage-player/manage-player-general-info/manage-player-general-info.component";
import {GameService} from "../../services/game.service";
import {AppConfigService} from "../../services/app-config.service";
import {GameThemeType} from "../../enum/GameThemeType";

@Component({
  selector: 'app-manage-game',
  standalone: true,
  imports: [
    ContainerComponent,
    RowComponent,
    ColComponent,
    MatButtonModule,
    HasPermissionDirective,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    ListGroupDirective,
    ListGroupItemDirective,
    ManagePlayerNumbersComponent,
    ManagePlayerNotesComponent,
    ManagePlayerPledgesComponent,
    ManagePlayerTransactionComponent,
    MatIconModule,
    ManagePlayerSuspensionsComponent,
    ManagePlayerSendGridInfoComponent,
    UserHasCausableAdminAccessDirective,
    ManagePlayerGeneralInfoComponent
  ],
  templateUrl: './manage-player.component.html',
  styleUrl: './manage-player.component.scss'
})
export class ManagePlayerComponent implements OnInit {
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private playerService: PlayersService = inject(PlayersService);
  private activeUserService: ActiveUserService = inject(ActiveUserService);
  private permissionService: PermissionsService = inject(PermissionsService);
  private errorHandlingService: ErrorHandlingService = inject(ErrorHandlingService);
  private appConfigService: AppConfigService = inject(AppConfigService);
  private gameService: GameService = inject(GameService);
  private router: Router = inject(Router);

  protected readonly PermissionTypes = PermissionTypes;

  public chosenGame: IGameQueryResult | null = null;

  public currentScreenState: PlayerScreenState = PlayerScreenState.GeneralInfo;
  public screenStateEnum = PlayerScreenState;

  public playerInfo: IGeneralPlayerInfo | null = null;
  public playerId: string | null = '';
  public playerSuspensions: IPlayerSuspension[] = [];
  public playerIsSuspendedInCurrentGame = false;
  public allowUserToChangeGame = false;
  public gameIdFromRoute = '';
  public gameLogo = '';
  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public uiStateForTemplate = UIStateEnum;
  public gameThemeType = GameThemeType;

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((paramMapP) => {
      this.playerId = paramMapP.get('playerId');
      let gameId = paramMapP.get('gameId');

      if (gameId && this.gameService.activeGame()?.Id == gameId) {
        this.gameIdFromRoute = gameId;
        this.chosenGame = this.gameService.activeGame();
      }

      if (this.playerId) {
        this.getAllPlayerInfo(!gameId);
      }
    });
  }

  setupFetchSuspensions() {
    // if subject gets value pushed, go get suspensions. This will happen on game change, or if a user adds / resolves a suspension
    this.playerService.fetchUpdatedSuspensionsSubject
      .pipe(concatMap((shouldFetchSuspensions) => {
          if (shouldFetchSuspensions && this.activeUserService.activeUser().CausableAdmin) {
            return this.playerService.getAllPlayerSuspensions(this.playerInfo?.Id!);
          }
          if (shouldFetchSuspensions && this.chosenGame) {
            return this.playerService.getPlayerSuspensionsForGame(this.playerInfo?.Id!, this.chosenGame.Id)
          }
          return of(null);
        }
      ))
      .subscribe({
        next: (result) => {
          if (result?.Suspensions) {
            this.playerSuspensions = result.Suspensions;
            this.playerIsSuspendedInCurrentGame = result.PlayerHasActiveSuspension;
          }

        }, error: (err) => {
          this.errorHandlingService.displayPageLevelErrorMessage(err);
        }
      })
  }

  onGameChange(chosenGame: IGameQueryResult) {
    this.chosenGame = chosenGame;
    this.gameLogo = `${this.appConfigService.cdnRoot()}/assets/${chosenGame.Subdomain}/game-logo.svg`;
    this.playerService.fetchUpdatedSuspensionsSubject.next(true);
  }

  chooseNewPlayerClick() {
    if (this.gameIdFromRoute) {
      this.router.navigateByUrl(`/${this.gameIdFromRoute}/player-search`);
    } else {
      this.router.navigateByUrl(`global-player-search`);
    }
  }

  getAllPlayerInfo(allowChooseGame = false) {
    this.uiState = UIStateEnum.ShowLoading;
    this.playerService.getGeneralPlayerData(this.playerId!, this.activeUserService.activeUser().Id)
      .subscribe({
        next: (res) => {
          this.playerInfo = res;

          if (this.playerInfo?.PlayerGames?.length <= 0) {
            // if user does not have access to the chosen game, or nothing comes back in the player games cause the player has not played yet
            this.chosenGame = null;
            return;
          }

          if (allowChooseGame) {
            if (this.playerInfo.PlayerGames.length > 1) {
              this.allowUserToChangeGame = true;
            } else if (this.playerInfo.PlayerGames.length == 1) {
              this.chosenGame = this.playerInfo.PlayerGames[0];
            }
          }

          if (this.permissionService.userHasPermission(PermissionTypes.ViewPlayerSuspensions)) {
            this.setupFetchSuspensions();
          }
          this.uiState = UIStateEnum.ShowData;
        },
        error: (err) => {
          this.uiState = UIStateEnum.ShowData;
          this.errorHandlingService.displayPageLevelErrorMessage(err);
        }
      })
  }
}
