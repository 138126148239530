
<app-component-level-error-message [errorMessage]="errorMessage"></app-component-level-error-message>

@if (uiState === uiStateForTemplate.ShowLoading) {
  <app-loading-card-content></app-loading-card-content>
} @else if (uiState === uiStateForTemplate.ShowData && comprehensiveReport) {
  <div class="margin-top-large">
    <div class="justify-space-between flex-row align-start">
      <div>
        <div class="section-header">Comprehensive Game Report</div>
        <div class="ticket-type-header">Date Range:</div>
        {{ dateRanges.StartDate | date: 'MMM d, y HH:mm' }} -
        {{ dateRanges.EndDate | date: 'MMM d, y HH:mm' }}
      </div>
      <div>
        <button mat-raised-button color="primary" (click)="generatePDF()"
                data-testid="comprehensive-report-generate-pdf-button">Generate
          PDF
        </button>
      </div>
    </div>

    <div class="margin-top-large">
      <h3>Sales Breakdown</h3>
      <div class="margin-left-med">
        <div class="margin-top-med">
          <div class="report-label">Gross Sales</div>
          <div class="report-value"
               data-testid="comprehensive-report-gross-total">{{ comprehensiveReport.FinancialTotals.GrossTotalSales | formatCurrency }}
          </div>
        </div>
        <div>
          <div class="report-label">Charity Host Admin Fees</div>
          <div
            class="report-value red-text"
            data-testid="comprehensive-report-admin-commission">(-{{ comprehensiveReport.FinancialTotals.AdministrativeCommissionTotal | formatCurrency }})
          </div>
        </div>
        <div>
          <div class="report-label">Causable Fees</div>
          <div class="report-value red-text"
               data-testid="comprehensive-report-causable-fees">(-{{ comprehensiveReport.FinancialTotals.CausableFees| formatCurrency }})
          </div>
        </div>
        <div>
          <div class="report-label">HST on Causable Fees</div>
          <div class="report-value red-text"
               data-testid="comprehensive-report-causable-fees-tax">(-{{ comprehensiveReport.FinancialTotals.CausableFeesTax| formatCurrency }})
          </div>
        </div>
        <div>
          <div class="report-label">Stripe Fees</div>
          <div class="report-value red-text"
               data-testid="comprehensive-report-payment-processor-fees">(-{{ comprehensiveReport.FinancialTotals.StripeFees| formatCurrency }})
          </div>
        </div>
        <div>
          <div class="report-label">Prize Amount</div>
          <div class="report-value red-text"
               data-testid="comprehensive-report-contribution-total">(-{{ comprehensiveReport.FinancialTotals.PotContributionTotal| formatCurrency }})
          </div>
        </div>
        <hr>
        <div>
          <div class="report-label bold-text">Net Proceeds For Charity</div>
          <div
            class="report-value bold-text"
            data-testid="comprehensive-report-charity-contribution">{{ comprehensiveReport.FinancialTotals.CharityContributionTotal | formatCurrency }}
          </div>
        </div>
      </div>

      <h3>Game Counts</h3>
      <div class="margin-left-med">
        <div>
          <div class="report-label">Draws with no Winner</div>
          <div class="report-value"
               data-testid="comprehensive-report-draws-without-winners">{{ comprehensiveReport.GameWinnerData.NumberOfDrawsWithNoWinners }}
          </div>
        </div>
        <div class="bottom-border">
          <div class="report-label">Draws with a winner</div>
          <div class="report-value"
               data-testid="comprehensive-report-draws-with-winners">{{ comprehensiveReport.GameWinnerData.NumberOfDrawsWithWinners }}
          </div>
        </div>
        <div class="margin-top-med">
          <div class="report-label">Total Number of Draws</div>
          <div class="report-value bold-text"
               data-testid="comprehensive-report-total-draws">{{ comprehensiveReport.GameWinnerData.TotalNumberOfDraws }}
          </div>
        </div>
      </div>

      <h3>Player Numbers</h3>
      <div class="margin-left-med">
        <div>
          <div class="report-label">Total numbers played</div>
          <div class="report-value"
               data-testid="comprehensive-report-tickets-played">{{ comprehensiveReport.TicketCounts.NumberOfTicketsPlayed }}
          </div>
        </div>
        <div>
          <div class="report-label">Total unplayed numbers</div>
          <div class="report-value"
               data-testid="comprehensive-report-tickets-unplayed">{{ comprehensiveReport.TicketCounts.NumberOfTicketsNotPlayed }}
          </div>
        </div>
      </div>

      <h3>Players</h3>
      <div class="margin-left-med">
        <div>
          <div class="report-label">Played</div>
          <div class="report-value"
               data-testid="comprehensive-report-active-users">{{ comprehensiveReport.UserCounts.NumberOfActiveUsers }}
          </div>
        </div>
        <div>
          <div class="report-label">Not Played</div>
          <div class="report-value"
               data-testid="comprehensive-report-inactive-users">{{ comprehensiveReport.UserCounts.NumberOfInactiveUsers }}
          </div>
        </div>
      </div>

      <h3>Group/Charity Breakdown</h3>
      <div class="margin-left-med">
        <div class="report-label">Total Charity Contribution</div>
        <div
          class="report-value"
          data-testid="comprehensive-report-financial-charity-contribution">{{ comprehensiveReport.FinancialTotals.CharityContributionTotal | formatCurrency }}
        </div>
      </div>

      <ag-grid-angular
        class="ag-theme-quartz margin-top-med  margin-left-med"
        data-testid="charity-contribution-grid"
        (gridReady)="onGridReady($event)"
        [animateRows]="true"
        [domLayout]="'autoHeight'"
        overlayNoRowsTemplate="<span>No tickets for this game</span>"
        [rowData]="comprehensiveReport.CharityBreakdown"
        [columnDefs]="groupBreakdownReportDefs">
      </ag-grid-angular>
    </div>
  </div>

}


