<mat-nav-list>
  @if (activeGameId$ | async; as gameId) {
    <mat-list-item>
      <div matListItemLine>Game Admin</div>
    </mat-list-item>
    <hr class="white-text">
    <mat-list-item [routerLink]="[gameId, 'active-game-instances']"
                   [matTooltipDisabled]="sideNavShouldOpen"
                   data-testid="current-draw-page-link"
                   matTooltip="Current Draw"
                   matTooltipPosition="right">
      <mat-icon matListItemIcon>games</mat-icon>
      <div matListItemLine data-testid="sidenav-current-draw">
        Current Draw
      </div>
    </mat-list-item>

    <mat-list-item [routerLink]="[gameId, 'manage-game']"
                   [matTooltipDisabled]="sideNavShouldOpen"
                   *hasPermission="permissionTypes.ViewGameSettings"
                   data-testid="manage-game-page-link"
                   matTooltip="Manage Game"
                   matTooltipPosition="right">
      <mat-icon matListItemIcon>settings</mat-icon>
      <div matListItemLine data-testid="sidenav-game-settings">Game
        Settings
      </div>
    </mat-list-item>
    <mat-list-item [routerLink]="[gameId, 'game-admin-reporting']"
                   [matTooltipDisabled]="sideNavShouldOpen"
                   *hasPermission="permissionTypes.ViewReports"
                   data-testid="reports-page-link"
                   matTooltip="Reports"
                   matTooltipPosition="right">
      <mat-icon matListItemIcon>request_page</mat-icon>
      <div matListItemLine data-testid="sidenav-reports">Reports
      </div>
    </mat-list-item>
    <mat-list-item [routerLink]="[gameId, 'manage-charities']"
                   [matTooltipDisabled]="sideNavShouldOpen"
                   data-testid="manage-charities-page-link"
                   *hasPermission="permissionTypes.ViewCharities"
                   matTooltip="Manage Charities"
                   matTooltipPosition="right">
      <mat-icon matListItemIcon>volunteer_activism</mat-icon>
      <div matListItemLine data-testid="sidenav-charities">Charities
      </div>
    </mat-list-item>
    <mat-list-item [routerLink]="[gameId, 'game-admin-notification-preferences']"
                   data-testid="notification-preferences-page-link">
      <mat-icon matListItemIcon>volunteer_activism</mat-icon>
      <div matListItemLine data-testid="sidenav-notification-preferences">Notification Preferences
      </div>
    </mat-list-item>
    <mat-list-item [routerLink]="[gameId, 'winner-payouts']"
                   data-testid="winner-payout-page-link"
                   *hasPermission="permissionTypes.ViewWinnerPayouts">
      <mat-icon matListItemIcon>money</mat-icon>
      <div matListItemLine data-testid="sidenav-winner-payouts">Winner
        Payouts
      </div>
    </mat-list-item>
    <mat-list-item [routerLink]="[gameId, 'player-search']"
                   [matTooltipDisabled]="sideNavShouldOpen"
                   data-testid="player-search-page-link"
                   *hasPermission="permissionTypes.ViewPlayerInfo"
                   matTooltip="Manage Players"
                   matTooltipPosition="right">
      <mat-icon matListItemIcon>manage_accounts</mat-icon>
      <div matListItemLine data-testid="sidenav-players">Players
      </div>
    </mat-list-item>
    @if (gameDrawnManually$ | async) {
      <mat-list-item [routerLink]="[gameId, 'draw-winner']"
                     [matTooltipDisabled]="sideNavShouldOpen"
                     data-testid="draw-winner-page-link"
                     *hasPermission="permissionTypes.DrawGameWinner"
                     matTooltip="Draw Winner"
                     matTooltipPosition="right">
        <mat-icon matListItemIcon>celebration</mat-icon>
        <div matListItemLine data-testid="sidenav-draw-winner">Draw Winner</div>
      </mat-list-item>
    }

    @if(helpDeskEnabled) {
      <mat-list-item [routerLink]="[gameId, 'game-admin-support-desk-search']"
                     [matTooltipDisabled]="sideNavShouldOpen"
                     data-testid="game-support-desk-page-link"
                     *hasPermission="permissionTypes.ViewSupportRequests"
                     matTooltip="Support Desk"
                     matTooltipPosition="right">
        <mat-icon matListItemIcon
                  [matBadge]="(assignedSupportRequestsCount$ | async)?.length"
                  matBadgeColor="warn"
                  [matBadgeHidden]="(assignedSupportRequestsCount$ | async)?.length == 0">
          support_agent
        </mat-icon>
        <div matListItemLine data-testid="sidenav-game-support-desk">Support Desk</div>
      </mat-list-item>
    }
  }

  @if ((availableGamesToUser$ | async)?.length! > 1) {
    <mat-list-item [routerLink]="['']"
                   [matTooltipDisabled]="sideNavShouldOpen"
                   data-testid="change-game-page-link"
                   matTooltip="Change Game"
                   matTooltipPosition="right">
      <mat-icon matListItemIcon>home</mat-icon>
      <div matListItemLine data-testid="sidenav-change-game">Landing
      </div>
    </mat-list-item>
  }

</mat-nav-list>
