import {Component} from '@angular/core';
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatTooltip} from "@angular/material/tooltip";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {GameService} from "../../../services/game.service";
import {MatDialog} from "@angular/material/dialog";
import {ActiveUserService} from "../../../services/active-user.service";
import {AppConfigService} from "../../../services/app-config.service";
import {
  RecalculateGameTotalsComponent
} from "../../../components/dialogs/recalculate-game-totals/recalculate-game-totals.component";
import {
  CreateAzureResourceForGameComponent
} from "../../../components/dialogs/create-azure-resource-for-game/create-azure-resource-for-game.component";
import {GameSettingsComponent} from "../../../components/game-settings/game-settings.component";
import {ChooseYourGameComponent} from "../../../components/choose-your-game/choose-your-game.component";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";
import {ContainerComponent} from "@coreui/angular";

@Component({
  selector: 'app-cross-game-manage-game',
  standalone: true,
  imports: [
    MatIconButton,
    HasPermissionDirective,
    MatMenuTrigger,
    MatTooltip,
    MatIcon,
    GameSettingsComponent,
    MatMenu,
    MatMenuItem,
    ChooseYourGameComponent,
    ContainerComponent

  ],
  templateUrl: './cross-game-manage-game.component.html',
  styleUrl: './cross-game-manage-game.component.css'
})
export class CrossGameManageGameComponent {
  public cdnRoot = this.appConfigService.cdnRoot();
  public activeUser = this.activeUserService.activeUser();
  public allowCreationOfAzureResources = this.appConfigService.allowCreationOfAzureResources();
  public chosenGame: IGameQueryResult | null = null;
  permissionTypes = PermissionTypes;

  constructor(private gameService: GameService,
              private matDialog: MatDialog,
              private activeUserService: ActiveUserService,
              private appConfigService: AppConfigService) {
  }

  onRecalculateGameClick() {
    this.matDialog.open(RecalculateGameTotalsComponent, {
      width: '80vw'
    });
  }

  onCreateAzureResourcesClick() {
    this.matDialog.open(CreateAzureResourceForGameComponent, {
      width: '80vw'
    });
  }

  onGameChange(chosenGame: IGameQueryResult) {
    this.chosenGame = chosenGame;
  }

}
