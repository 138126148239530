import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatInput} from "@angular/material/input";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgForOf} from "@angular/common";
import {IDropdownOption} from "../../interfaces/IDropDownOptions";
import {MatAutocomplete, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {map, startWith} from "rxjs";

@Component({
  selector: 'app-filterable-dropdown',
  standalone: true,
  imports: [
    MatFormField,
    MatSelect,
    MatOption,
    MatInput,
    FormsModule,
    NgForOf,
    MatLabel,
    ReactiveFormsModule,
    MatAutocompleteTrigger,
    MatAutocomplete
  ],
  templateUrl: './filterable-dropdown.component.html',
  styleUrl: './filterable-dropdown.component.css'
})
export class FilterableDropdownComponent implements OnInit{

  @Output() selectionChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() placeholder: string = '';

  @Input() set optionsSetter(dropdownOptions: IDropdownOption[]) {
    this.dropdownOptions = dropdownOptions;
    this.filteredOptions = dropdownOptions;
  }

  myControl = new FormControl();
  filteredOptions: IDropdownOption[] = [];
  dropdownOptions: IDropdownOption[] = [];

  ngOnInit(): void {
    this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
        return filterValue ? this.dropdownOptions.filter(option => option.name.toLowerCase().includes(filterValue)) : this.dropdownOptions;
      })
    ).subscribe(options => {
      this.filteredOptions = options;
    });
  }

  onOptionSelected(event: any): void {
    const selectedOption = event.option.value as IDropdownOption;
    this.myControl.setValue(selectedOption.name);
    this.selectionChange.emit(selectedOption.id);
  }

  resetSelection(): void {
    this.myControl.setValue('');
  }
}
