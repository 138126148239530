import { Injectable } from '@angular/core';
import {APIService} from "./api.service";
import {ITestSentryException} from "../interfaces/ITestSentryException";

@Injectable({
  providedIn: 'root'
})
export class SentryTestingService {

  constructor(private apiService: APIService) {

  }

  public throwSentryException(sentryExceptionRequest: ITestSentryException){
    return this.apiService.MakePostRequest<any>('admin-user/throw-sentry-exception', sentryExceptionRequest);
  }
}
