import {NotificationsService} from "../../services/notifications.service";
import {Component, Directive, inject} from "@angular/core";
import {MatButton} from "@angular/material/button";
import {MatListOption, MatSelectionList, MatSelectionListChange} from "@angular/material/list";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {ChooseYourGameComponent} from "../../components/choose-your-game/choose-your-game.component";
import {ActiveUserService} from "../../services/active-user.service";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {SnackbarService} from "../../services/snackbar.service";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {INotificationType} from "../../interfaces/notifications/INotificationType";
import {IAdminNotificationPreferences} from "../../interfaces/notifications/IAdminNotificationPreferences";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {forkJoin} from "rxjs";
import {IUpdateAdminNotificationPreferences} from "../../interfaces/notifications/IUpdateAdminNotificationPreferences";

@Directive()
export abstract class NotificationPreferencesBase {

  private notificationService: NotificationsService = inject(NotificationsService);
  private activeUserService: ActiveUserService = inject(ActiveUserService);
  private errorHandlingService: ErrorHandlingService = inject(ErrorHandlingService);
  private snackbarService: SnackbarService = inject(SnackbarService);

  protected uiState: UIStateEnum = UIStateEnum.ShowLoading
  protected uiStateForTemplate = UIStateEnum;
  protected potentialNotificationTypes: INotificationType[] = [];
  protected adminNotificationPreferences: IAdminNotificationPreferences[] = [];
  protected selectedNotificationTypes: number[] = []; // Holds the IDs of selected notification types
  protected game: IGameQueryResult | null = null;
  protected isCrossGame = false;

  onGameChosen(chosenGameP: IGameQueryResult){
    this.game = chosenGameP;
    this.fetchNotificationPreferenceData();
  }

  isNotificationTypeSelected(notificationTypeId: number): boolean {
    return this.selectedNotificationTypes.includes(Number(notificationTypeId));
  }

  onSelectionChange(event: MatSelectionListChange): void {
    const selectedOptions = event.source.selectedOptions.selected;

    // Update the selectedNotificationTypes array based on the checked boxes
    this.selectedNotificationTypes = selectedOptions.map((option: MatListOption) => Number(option.value));
  }

  protected fetchNotificationPreferenceData() {
    const activeUser = this.activeUserService.activeUser().Id;

    forkJoin([
      this.notificationService.fetchNotificationTypes(activeUser),
      this.notificationService.fetchAdminNotificationPreferences(activeUser, this.game?.Id!)
    ]).subscribe({
      next: ([notificationTypes, adminPreferences]) => {
        this.potentialNotificationTypes = notificationTypes;
        this.selectedNotificationTypes = adminPreferences.map(p => p.NotificationType);
        this.adminNotificationPreferences = adminPreferences;
        this.uiState = UIStateEnum.ShowData;
      }, error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err);
        this.uiState = UIStateEnum.ShowData;
      }
    })
  }

  saveMyPreferences() {
    this.uiState = UIStateEnum.ShowRequestProcessing;

    const updatePreferencesCommand: IUpdateAdminNotificationPreferences = {
      GameId: this.game?.Id!,
      AdminId: this.activeUserService.activeUser().Id,
      NotificationTypes: this.selectedNotificationTypes
    };

    this.notificationService.updateUserPreferences(updatePreferencesCommand).subscribe({
      next: () => {
        this.snackbarService.openSuccessfulSnackBar('Successfully updated your notification preferences!');
        this.uiState = UIStateEnum.ShowData;
      },
      error: () => {
        this.snackbarService.openErrorSnackBar('Error updated your preferences. Please try again or contact system admin for assistance');
        this.uiState = UIStateEnum.ShowData;
      }
    })
  }
}
