import { Component } from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {GameService} from "../../../services/game.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {MatTabsModule} from "@angular/material/tabs";
import {AgGridAngular} from "ag-grid-angular";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-create-azure-resource-for-game',
  standalone: true,
  imports: [
    DialogBaseComponent,
    MatTabsModule,
    AgGridAngular,
    MatButton
  ],
  templateUrl: './create-azure-resource-for-game.component.html',
  styleUrl: './create-azure-resource-for-game.component.css'
})
export class CreateAzureResourceForGameComponent {
  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;
  public creationCompleted = false;

  constructor(private gameService: GameService,
              private errorHandlingService: ErrorHandlingService) {
  }

  onCreateAzureResourcesClick() {
    this.uiState = UIStateEnum.ShowLoading;
    this.creationCompleted = false;
    this.gameService.createAzureResourcesForGame().subscribe({
      next: (resP) => {
        this.creationCompleted = true;
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err, true, err.Error.Errors[0]);
      }
    })
  }

}
