import {Component} from '@angular/core';
import {UIStateEnum} from "../../../../enum/UIStateEnum";
import {SnackbarService} from "../../../../services/snackbar.service";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {CdkCopyToClipboard} from "@angular/cdk/clipboard";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {ActivatedRoute, Router} from "@angular/router";
import {SupportRequestService} from "../../../../services/support-request.service";
import {ColComponent, ContainerComponent, RowComponent} from "@coreui/angular";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {ActiveUserService} from "../../../../services/active-user.service";
import {HasPermissionDirective} from "../../../../directives/permissions/has-permission";
import {MatInput} from "@angular/material/input";
import {GameService} from "../../../../services/game.service";
import {
  DisplaySupportRequestsComponent
} from "../../../../components/support-requests/display-support-requests/display-support-requests.component";
import {IEscalateSupportRequest} from "../../../../interfaces/IEscalateSupportRequest";
import {BaseSupportRequestViewComponent} from "../base-support-request-view.component";
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";

@Component({
  selector: 'app-game-admin-view-support-request',
  standalone: true,
  imports: [
    MatButton,
    MatIcon,
    MatIconButton,
    CdkCopyToClipboard,
    MatProgressSpinner,
    ContainerComponent,
    RowComponent,
    ColComponent,
    DatePipe,
    FormsModule,
    MatError,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    NgIf,
    ReactiveFormsModule,
    HasPermissionDirective,
    MatInput,
    DisplaySupportRequestsComponent,
    NgForOf,
    MatCard,
    MatCardHeader,
    MatCardContent
  ],
  templateUrl: './game-admin-view-support-request.component.html',
  styleUrl: './game-admin-view-support-request.component.scss'
})
export class GameAdminViewSupportRequestComponent extends BaseSupportRequestViewComponent {

  constructor(
    supportRequestService: SupportRequestService,
    gameService: GameService,
    activeUserService: ActiveUserService,
    activatedRoute: ActivatedRoute,
    snackBarService: SnackbarService,
    router: Router
  ) {
    super(supportRequestService, gameService, activeUserService, activatedRoute, snackBarService, router);
  }

  escalateSupportRequest(): void {
    this.uiState = UIStateEnum.ShowLoading;

    const command: IEscalateSupportRequest = {
      AdminId: this.activeUserService.activeUser().Id,
      RequestId: this.supportRequest!.Id,
      GameId: this.gameService.activeGame().Id
    };

    this.supportRequestService.escalateSupportRequest(command).subscribe({
      next: (res) => {
        this.supportRequest = res;
        this.uiState = UIStateEnum.ShowData;
        this.snackBarService.openSuccessfulSnackBar("Successfully escalated support request");
      },
      error: () => {
        this.uiState = UIStateEnum.ShowData;
        this.snackBarService.openErrorSnackBar("Failed to escalate support request");
      }
    });
  }

}
