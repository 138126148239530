import {Component} from '@angular/core';
import {UIStateEnum} from "../../enum/UIStateEnum";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {GameService} from "../../services/game.service";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {ActiveUserService} from "../../services/active-user.service";
import {Router} from "@angular/router";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MarkdownComponent} from "ngx-markdown";
import {MatButton} from "@angular/material/button";
import {SnackbarService} from "../../services/snackbar.service";
import {GameRulesService} from "../../services/game-rules.service";
import {IUpdateGameRulesCommand} from "../../interfaces/IUpdateGameRulesCommand";
import {ContainerComponent} from "@coreui/angular";

@Component({
  selector: 'app-game-rules-editor',
  standalone: true,
  templateUrl: './game-rules-editor.component.html',
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInput,
    MarkdownComponent,
    MatButton,
    ContainerComponent
  ],
  styleUrl: './game-rules-editor.component.scss'
})
export class GameRulesEditorComponent {
  public uiState = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public activeGame = this.gameService.activeGame();
  public activeUser = this.activeUserService.activeUser();
  public gameRulesId: string = "";

  public gameRulesControl: FormControl<string | null> = new FormControl<string | null>("", Validators.required);

  public gameRulesForm = new FormGroup({
    gameRules: this.gameRulesControl
  });

  constructor(private gameService: GameService,
              private gameRulesService: GameRulesService,
              private activeUserService: ActiveUserService,
              private errorHandlingService: ErrorHandlingService,
              private snackBarService: SnackbarService,
              private router: Router) {
    this.populateForm();
  }

  submit() {
    if (!this.gameRulesForm.valid) {
      this.gameRulesForm.markAllAsTouched();
      return;
    }

    let command: IUpdateGameRulesCommand = {
      gameId: this.activeGame.Id,
      adminId: this.activeUser.Id,
      GameRules: {
        GameRulesContent: this.gameRulesControl.value!,
        GameId: this.activeGame.Id,
        Id: this.gameRulesId
      }
    }

    this.gameRulesService.updateGameRules(command).subscribe({
      next: () => {
        this.snackBarService.openSuccessfulSnackBar("Successfully updated the game rules!");
        let gameId = this.activeGame.Id;
        this.router.navigateByUrl(`${gameId}/manage-game`);
      },
      error: err => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err, true);
      }
    });
  }

  populateForm() {
    this.gameRulesService.getGameRules(this.activeGame.Id).subscribe({
      next: rulesP => {
        this.gameRulesControl.setValue(rulesP.GameRulesContent);
        this.gameRulesId = rulesP.Id;
      }, error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }

  formatMarkdown(textP: string) {
    if (textP == undefined) {
      return "";
    }
    return textP.replace(/\n/, "<br>");
  }
}
