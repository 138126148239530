import {inject} from "@angular/core";
import {ActiveUserService} from "../services/active-user.service";
import {OidcService} from "../services/oidc.service";
import {Router} from "@angular/router";
import {concatMap, map} from "rxjs";
import {catchError} from "rxjs/operators";
import {GameService} from "../services/game.service";

export const LandingPageRedirectGuard = () => {
  const activeUserService = inject(ActiveUserService);
  const oidcService = inject(OidcService);
  const gameService = inject(GameService);

  const router = inject(Router);

  const is_authenticated = oidcService.isAuthenticated();
  if (!is_authenticated) {
    return router.navigateByUrl('auth/login');
  }

  const adminId = oidcService.getAdminIdFromClaims();

  if (adminId) {
    return activeUserService.retrieveActiveUser(adminId)
      .pipe(concatMap((adminP) => {
        // if causable admin or cross game admin, go to the cross game landing page
          if (adminP.CausableAdmin || (adminP.CrossGamePermissions?.length > 0)) {

            return gameService.getGamesForUser(adminId).pipe(map(() => {
              return router.navigateByUrl('cross-game-view-draw-landing');
            }));
          }
          // allow any game admins to continue to the landing page
          return gameService.getGamesForUser(adminId).pipe(map(() => true));
       }),
        catchError(() => {
          return router.navigateByUrl('not-admin');
        }))
  } else {
    return router.navigateByUrl('not-admin');
  }

};
