import {Component, OnDestroy, OnInit} from '@angular/core';
import {interval, Observable, Subscription} from "rxjs";
import {GameInstanceService} from "../../services/game-instance.service";
import {IDrawNumberResponse} from "../../interfaces/IDrawNumberResponse";
import {IManualDrawWinnerResults} from "../../interfaces/IManualDrawWinnerResults";
import {GameService} from "../../services/game.service";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {GameInstanceStatuses} from "../../enum/GameInstanceStatusEnum";
import {IActiveGameInstance} from "../../interfaces/IActiveGameInstance";
import {IDrawNumberRequest} from "../../interfaces/IDrawNumberRequest";
import {ActiveUserService} from "../../services/active-user.service";
import {switchMap} from "rxjs/operators";
import {AsyncPipe} from "@angular/common";
import {MatCard} from "@angular/material/card";
import {MatButton} from "@angular/material/button";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {PipesModule} from "../../pipes/pipes.module";
import {ContainerComponent} from "@coreui/angular";

@Component({
  selector: 'app-draw-winner',
  standalone: true,
  templateUrl: './draw-winner-page.component.html',
  imports: [
    AsyncPipe,
    MatCard,
    MatButton,
    MatProgressSpinner,
    PipesModule,
    ContainerComponent
  ],
  styleUrls: ['./draw-winner-page.component.scss']
})
export class DrawWinnerPageComponent implements OnInit, OnDestroy {

  public endDatePassedButNotReadyMessage: string = 'The game is now over! Just give us a couple of minutes to get ready for the draw!';
  public showEndDatePassedButNotReadyMessage: boolean = false;
  public winnerIsBeingDrawn: boolean = false;
  public gameInstanceHasEndedSub: Subscription | null = null;
  public checkForGameReadyForDrawSub: Subscription | null = null;
  public activeGameInstance$: Observable<IActiveGameInstance> = this.gameInstanceService.selectActiveGameInstance();
  public winnerResponse?: IManualDrawWinnerResults;
  public checkForGameReadyForDraw$: Observable<number> = interval(5000);
  public gameInstanceStates = GameInstanceStatuses;
  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public uiStateForTemplate = UIStateEnum;

  constructor(private gameInstanceService: GameInstanceService,
              private activeUserService: ActiveUserService,
              private errorHandlingService: ErrorHandlingService,
              private gameService: GameService) {
  }

  ngOnInit() {
    this.listenForGameEndedEvent();
  }

  private waitForGameToMoveToReadyForDraw() {
    this.checkForGameReadyForDrawSub = this.checkForGameReadyForDraw$.pipe(switchMap(() => {
      return this.gameInstanceService
        .activeGameInstanceByGame(this.gameService.activeGame().Id, this.activeUserService.activeUser().Id)
    }))
      .subscribe({
        next: (activeGameInstance) => {
          if (activeGameInstance.State == GameInstanceStatuses.ReadyForDraw && this.checkForGameReadyForDrawSub) {
            this.checkForGameReadyForDrawSub.unsubscribe();
          }
        }
      })
  }

  private listenForGameEndedEvent() {
    this.gameInstanceHasEndedSub = this.gameInstanceService.gameInstanceHasEnded$.subscribe({
      next: (gameInstanceHasEnded) => {
        if (gameInstanceHasEnded) {
          this.gameInstanceHasEndedSub?.unsubscribe();

          if (!this.checkForGameReadyForDrawSub) {
            this.waitForGameToMoveToReadyForDraw();
          }
        }
      }
    })
  }

  ngOnDestroy() {
    if (this.checkForGameReadyForDrawSub) {
      this.checkForGameReadyForDrawSub?.unsubscribe()
    }
    if (this.gameInstanceHasEndedSub) {
      this.gameInstanceHasEndedSub?.unsubscribe()
    }
  }

  drawWinner() {
    this.winnerIsBeingDrawn = true;
    this.uiState = UIStateEnum.ShowLoading;
    const drawRequest: IDrawNumberRequest = {
      GameInstanceId: this.gameInstanceService.activeGameInstanceId(),
      OverrideValidation: false,
      AdminId: this.activeUserService.activeUser().Id,
      IsRedraw: false
    };

    this.gameInstanceService.drawWinner(drawRequest).subscribe({
      next: (responseP: IDrawNumberResponse) => {
        if (responseP.GameInstanceWinnerDetails) {
          this.winnerIsBeingDrawn = false;
          this.winnerResponse = responseP.GameInstanceWinnerDetails;
          this.uiState = UIStateEnum.ShowData;
        }
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.winnerIsBeingDrawn = false;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    })
  }

}
