@if (supportRequest) {
  <div class="margin-bottom-medium">
    <div class="detail-label">Player Name</div>
    <div class="detail-value">{{ supportRequest.PlayerName }}</div>
  </div>

  <div class="margin-bottom-medium">
    <div class="detail-label">Player Id</div>
    <div class="detail-value">{{ supportRequest.PlayerId }}
      <mat-icon class="pointer-cursor" [cdkCopyToClipboard]="supportRequest.PlayerId"
                (click)="copyToClipBoard()">content_copy
      </mat-icon>
    </div>
  </div>

  <div class="margin-bottom-medium">
    <div class="detail-label">Player Email</div>
    <div class="detail-value">{{ supportRequest.PlayerEmail }}</div>
  </div>

  <div>
    <button mat-stroked-button data-testid="support-request-go-to-player"
            (click)="onViewPlayerClick()">Go to Player
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>

  <hr>

  <div class="margin-bottom-medium">
    <div class="detail-label">Associated with Game</div>
    <div class="detail-value">{{ supportRequest.GameId }}</div>
  </div>

  <div class="margin-bottom-medium">
    <div class="detail-label">Created On</div>
    <div class="detail-value">{{ supportRequest.CreatedOn | date: 'MMM d, y HH:mm' }}</div>
  </div>

  <div class="margin-bottom-medium">
    <div class="detail-label">Resolved</div>
    <div class="detail-value">{{ supportRequest.Resolved ? "Yes" : "No" }}</div>
  </div>

  <div class="margin-bottom-medium">
    <div class="detail-label">Has Been Escalated to Causable Team</div>
    <div class="detail-value">{{ supportRequest.Escalated ? "Yes" : "No" }}</div>
  </div>

  <div *userHasCausableAdminAccess class="margin-bottom-medium">
    <div class="detail-label">Sentry Event Id</div>
    <div class="detail-value">{{ supportRequest.SentryEventId }}
      <mat-icon class="pointer-cursor" [cdkCopyToClipboard]="supportRequest.SentryEventId"
                (click)="copyToClipBoard()">content_copy
      </mat-icon>
    </div>
  </div>

  <div class="margin-bottom-medium">
    <div class="detail-label">Error Type</div>
    <div class="detail-value">{{ displayErrorType(supportRequest.ErrorType) }}</div>
  </div>

  @if (supportRequest.Resolved) {
    <div>
      <div class="detail-label">Resolved On</div>
      <div class="detail-value">{{ supportRequest.ResolvedOn | date: 'MMM d, y HH:mm' }}</div>
    </div>

    <div>
      <div class="detail-label">Resolved By</div>
      <div class="detail-value">{{ supportRequest.ResolvedBy }}</div>
    </div>
  }
  <hr>
}
