import {Component, inject, Input} from '@angular/core';
import {DatePipe} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {ISupportRequest} from "../../../interfaces/player/ISupportRequest";
import {CdkCopyToClipboard} from "@angular/cdk/clipboard";
import {SupportDeskColDefs} from "../../../pages/support-desk/support-desk-column-defs";
import {SnackbarService} from "../../../services/snackbar.service";
import {Router} from "@angular/router";
import {
  UserHasCausableAdminAccessDirective
} from "../../../directives/permissions/user-has-causable-admin-access.directive";

@Component({
  selector: 'app-display-support-requests',
  standalone: true,
  imports: [
    DatePipe,
    MatButton,
    MatIcon,
    CdkCopyToClipboard,
    UserHasCausableAdminAccessDirective
  ],
  templateUrl: './display-support-requests.component.html',
  styleUrl: './display-support-requests.component.css'
})
export class DisplaySupportRequestsComponent {
  private supportDeskColDefs: SupportDeskColDefs = inject(SupportDeskColDefs);
  private snackBarService: SnackbarService = inject(SnackbarService);
  private router: Router = inject(Router);

  @Input() set supportRequestSetter(requestP: ISupportRequest) {
    this.supportRequest = requestP;
  }

  protected supportRequest: ISupportRequest | null = null;

  displayErrorType(errorTypeP: number) {
    return this.supportDeskColDefs.errorTypes[errorTypeP - 1].name;
  }

  copyToClipBoard() {
    this.snackBarService.openSuccessfulSnackBar("Copied to clipboard!");
  }

  public onViewPlayerClick() {
    this.router.navigateByUrl(`/manage-player/${this.supportRequest?.PlayerId!}`);
  }
}
