import {Component, inject, Input} from '@angular/core';
import {AsyncPipe} from "@angular/common";
import {GameAssetSettingsFormComponent} from "../game-asset-settings-form/game-asset-settings-form.component";
import {GameDrawSettingsComponentForm} from "../game-draw-settings-form/game-draw-settings-component-form.component";
import {GameMetadataSettingsFormComponent} from "../game-metadata-settings-form/game-metadata-settings-form.component";
import {GamePaymentSettingsFormComponent} from "../game-payment-settings-form/game-payment-settings-form.component";
import {GeneralGameSettingsForm} from "../general-game-settings-form/general-game-settings-form.component";
import {MatButton, MatFabButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatStep, MatStepLabel, MatStepper} from "@angular/material/stepper";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {IGameGeneralSettingsForm} from "../../../interfaces/new-game/forms/IGameGeneralSettingsForm";
import {IGameDrawSettingsForm} from "../../../interfaces/new-game/forms/IGameDrawSettingsForm";
import {IGamePaymentSettingsForm} from "../../../interfaces/new-game/forms/IGamePaymentSettingsForm";
import {IGameMetadataSettingsForm} from "../../../interfaces/new-game/forms/IGameMetadataSettingsForm";
import {IGameAssetSettingsForm} from "../../../interfaces/new-game/forms/IGameAssetSettingsForm";
import {NewGameService} from "../../../services/new-game.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {DateService} from "../../../services/date.service";
import {IGameMetadataSettings} from "../../../interfaces/new-game/breakdown/IGameMetadataSettings";
import {IGamePaymentSettings} from "../../../interfaces/new-game/breakdown/IGamePaymentSettings";
import {IGameAssetSettings} from "../../../interfaces/new-game/breakdown/IGameAssetSettings";
import {IGameDrawSettings} from "../../../interfaces/new-game/breakdown/IGameDrawSettings";
import {IGameGeneralSettings} from "../../../interfaces/new-game/breakdown/IGameGeneralSettings";
import {concatMap, forkJoin, Observable, of} from "rxjs";
import {ICompleteNewGameRequest} from "../../../interfaces/new-game/ICompleteNewGameRequest";
import {GameService} from "../../../services/game.service";
import {IApiResponseBase} from "../../../interfaces/IApiResponseBase";
import {CharityService} from "../../../services/charity.service";
import {ICharitiesByCategoryResult} from "../../../interfaces/charity/ICharitiesByCategoryResult";
import {GameTypeEnum} from "../../../enum/GameTypeEnum";
import {EditPaymentTiersFiftyFiftyComponent} from "../../edit-payment-tiers/edit-payment-tiers-fifty-fifty.component";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";
import {
  EditPaymentTiersGoldrushComponent
} from "../../edit-payment-tiers-goldrush/edit-payment-tiers-goldrush.component";
import {MatError, MatFormField, MatHint} from "@angular/material/form-field";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {GameRulesService} from "../../../services/game-rules.service";
import {IGameRules} from "../../../interfaces/IGameRules";
import {MarkdownComponent} from "ngx-markdown";
import {MatInput} from "@angular/material/input";
import {IAddGameRules} from "../../../interfaces/IAddGameRules";
import {IUpdateGameRulesCommand} from "../../../interfaces/IUpdateGameRulesCommand";
import {ActiveUserService} from "../../../services/active-user.service";
import {AddPlayerGameNoteComponent} from "../../dialogs/add-player-game-note/add-player-game-note.component";
import {MatCheckbox} from "@angular/material/checkbox";
import {AppConfigService} from "../../../services/app-config.service";

@Component({
  selector: 'app-new-game-container',
  standalone: true,
  imports: [
    AsyncPipe,
    GameAssetSettingsFormComponent,
    GameDrawSettingsComponentForm,
    GameMetadataSettingsFormComponent,
    GamePaymentSettingsFormComponent,
    GeneralGameSettingsForm,
    MatButton,
    MatFabButton,
    MatIcon,
    MatStep,
    MatStepLabel,
    MatStepper,
    EditPaymentTiersFiftyFiftyComponent,
    EditPaymentTiersGoldrushComponent,
    MatError,
    MatProgressSpinner,
    MarkdownComponent,
    MatFormField,
    MatHint,
    MatInput,
    ReactiveFormsModule,
    MatCheckbox,
    FormsModule
  ],
  templateUrl: './new-game-container.component.html',
  styleUrl: './new-game-container.component.css'
})
export class NewGameContainerComponent {

  @Input() set newGameIdSetter(newGameIdP: string) {
    const draftGame = this.gameService.getAllDraftGames().find((draftGame) => draftGame.Id === newGameIdP);
    if (draftGame) {
      this.inProgressDraftGame = draftGame;
      this.newGameId = newGameIdP;
      this.newGameService.mapToCompleteNewGameRequest(draftGame);
      this.fetchNewGameData(newGameIdP);
    } else {
      this.showWrongGameMessage = true;
    }
  }

  private appConfigService: AppConfigService = inject(AppConfigService);
  private newGameService: NewGameService = inject(NewGameService);
  private gameService: GameService = inject(GameService);
  private gameRulesService: GameRulesService = inject(GameRulesService);
  private activeUserService: ActiveUserService = inject(ActiveUserService);
  private errorHandlingService: ErrorHandlingService = inject(ErrorHandlingService);
  private snackbarService: SnackbarService = inject(SnackbarService);
  private dateService: DateService = inject(DateService);
  private charityService: CharityService = inject(CharityService);

  public gameGeneralForm!: FormGroup<IGameGeneralSettingsForm>;
  public gameDrawSettingsForm!: FormGroup<IGameDrawSettingsForm>;
  public gamePaymentSettingsForm!: FormGroup<IGamePaymentSettingsForm>;
  public gameMetadataSettingsForm!: FormGroup<IGameMetadataSettingsForm>;
  public gameAssetsSettingsForm!: FormGroup<IGameAssetSettingsForm>;
  public newGame$: Observable<ICompleteNewGameRequest> = this.newGameService.selectNewGameRequest();
  public charitiesForNewGame$: Observable<ICharitiesByCategoryResult[]> = this.charityService.charityCategories$;
  public wrongGameIdMessage: string = 'Looks like the game you are trying to reach is not a draft game';
  public showWrongGameMessage: boolean = false;
  public gameRulesAlreadyExist: boolean = false;
  public existingGameRules: IGameRules | null = null;
  public validationErrors: string[] = [];
  protected readonly GameTypeEnum = GameTypeEnum;
  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public allowCreationOfAzureResources: boolean = this.appConfigService.allowCreationOfAzureResources();
  public createAzureResources: boolean = this.allowCreationOfAzureResources;

  public gameRulesControl: FormControl<string | null> = new FormControl<string | null>("", Validators.required);

  public gameRulesForm: FormGroup = new FormGroup({
    gameRules: this.gameRulesControl
  });

  private newGameId: string = '';
  public inProgressDraftGame: IGameQueryResult | null = null;

  formatMarkdown(textP: string) {
    if (textP == undefined) {
      return "";
    }
    return textP.replace(/\n/, "<br>");
  }

  private fetchNewGameData(newGameIdP: string) {
    forkJoin([
      this.charityService.fetchCharitiesPerGameByCategory(newGameIdP),
      this.gameRulesService.getGameRules(newGameIdP)
    ]).subscribe({
      next: ([charities, gameRulesP]) => {
        if (gameRulesP.GameRulesContent && gameRulesP.Id) {
          this.existingGameRules = gameRulesP;
          this.gameRulesAlreadyExist = true;
          this.gameRulesControl.setValue(gameRulesP.GameRulesContent);
        }
      },
      error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }

  addNewCharities() {
    this.charityService.openAddCharityDialog(this.newGameId);
  }

  addNewGameRules() {
    this.uiState = UIStateEnum.ShowLoading;
    let newRules: IAddGameRules = {
      GameId: this.newGameId,
      AdminId: this.activeUserService.activeUser().Id,
      GameRulesContent: this.gameRulesControl.value ?? ''
    };

    this.gameRulesService.addGameRules(newRules).subscribe({
      next: (newRulesP) => {
        this.existingGameRules = newRulesP;
        this.gameRulesAlreadyExist = true;
        this.uiState = UIStateEnum.ShowData;
        this.snackbarService.openSuccessfulSnackBar('Added new game rules.')
      }, error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }

  editGameRules() {
    if (!this.gameRulesForm.valid) {
      this.gameRulesForm.markAllAsTouched();
      return;
    }

    let command: IUpdateGameRulesCommand = {
      gameId: this.newGameId,
      adminId: this.activeUserService.activeUser().Id,
      GameRules: {
        GameRulesContent: this.gameRulesControl.value!,
        GameId: this.newGameId,
        Id: this.existingGameRules?.Id!
      }
    }

    this.gameRulesService.updateGameRules(command).subscribe({
      next: () => {
        this.snackbarService.openSuccessfulSnackBar("Successfully updated the game rules!");
      },
      error: err => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }

  saveGame() {
    this.getSaveGameRequest().subscribe({
        next: () => {
          this.snackbarService.openSuccessfulSnackBar('New Game Saved.')
        }, error: (err) => {
          this.errorHandlingService.displayPageLevelErrorMessage(err);
        }
      }
    )
  }

  getSaveGameRequest(): Observable<any> {
    this.newGameService.saveNewGameGeneral(this.formToGeneralGameSettingsMapper());
    this.newGameService.saveNewGameDraw(this.formToDrawGameSettingsMapper());
    this.newGameService.saveNewGamePayment(this.formToPaymentGameSettingsMapper());
    this.newGameService.saveNewGameAssets(this.formToAssetGameSettingsMapper());
    this.newGameService.saveNewGameMeta(this.formToMetadataGameSettingsMapper());

    const newGameSaveRequest = this.newGameService.mapGameToRequest();

    if (newGameSaveRequest) {
      return this.newGameService.saveGameToApi(newGameSaveRequest);
    }

    return of(null);
  }

  private formToMetadataGameSettingsMapper(): IGameMetadataSettings {
    const controls = this.gameMetadataSettingsForm.value;
    return {
      metadataDescription: controls.metadataDescription ?? '',
      metadataImageUrl: controls.metadataImageUrl ?? '',
      metadataSiteName: controls.metadataSiteName ?? '',
      metadataTitle: controls.metadataTitle ?? '',
      metadataUrl: controls.metadataUrl ?? '',
      metadataType: controls.metadataType ?? '',
      metadataFaviconUrl: controls.metadataFaviconUrl ?? ''
    };
  }

  private formToPaymentGameSettingsMapper(): IGamePaymentSettings {
    const controls = this.gamePaymentSettingsForm.value;
    return {
      causableFeeMultiplier: controls.causableFeeMultiplier ? controls.causableFeeMultiplier / 100 : 0,
      taxMultiplier: controls.taxMultiplier ? controls.taxMultiplier / 100 : 0,
      deductTaxesFromCharityContribution: controls.deductTaxesFromCharityContribution ?? false,
      stripePublicKey: controls.stripePublicKey ?? '',
      administrationCommissionMultiplier: controls.administrationCommissionMultiplier ? controls.administrationCommissionMultiplier / 100 : 0
    };
  }

  private formToAssetGameSettingsMapper(): IGameAssetSettings {
    const controls = this.gameAssetsSettingsForm.value;
    return {
      gameThemeTypeId: controls.gameThemeTypeId ?? 0,
      primaryGameColor: controls.primaryGameColor ?? ''
    };
  }

  private formToDrawGameSettingsMapper(): IGameDrawSettings {
    const controls = this.gameDrawSettingsForm.value;
    return {
      drawDayOfWeek: controls.drawDayOfWeek ?? 0,
      drawTimeMinutesFromMidnightLocal: controls.drawTimeMinutesFromMidnightLocal ? this.dateService.convertMilitaryTimeToMinutes(controls.drawTimeMinutesFromMidnightLocal) : 0,
      manualDraw: controls.manualDraw ?? false,
      maxTicketsPerDraw: controls.maxTicketsPerDraw ?? 0,
      notificationsStartMinutesFromMidnightLocal: controls.notificationsStartMinutesFromMidnightLocal ? this.dateService.convertMilitaryTimeToMinutes(controls.notificationsStartMinutesFromMidnightLocal) : 0,
      instanceIntervalType: controls.instanceIntervalType ?? 0,
      minimumGameLengthInHours: controls.minimumGameLengthInHours ?? 0,
      maxAmountPlayersCanSpendPerDraw: controls.maxAmountPlayersCanSpendPerDraw ? controls.maxAmountPlayersCanSpendPerDraw * 100 : 0,
    };
  }

  private formToGeneralGameSettingsMapper(): IGameGeneralSettings {

    const controls = this.gameGeneralForm.value;
    return {
      licenceNumber: controls.licenceNumber ?? '',
      type: controls.type ?? 0,
      dateOfFirstInstance: controls.dateOfFirstInstance ?? new Date(),
      dateOfFinalInstance: controls.dateOfFinalInstance ?? null,
      gameDescription: controls.gameDescription ?? '',
      autoplayEnabled: controls.autoplayEnabled ?? false,
      regionLockPlayerGeolocation: controls.regionLockPlayerGeolocation ?? false,
      regionLockPlayerPostalCode: controls.regionLockPlayerPostalCode ?? false,
      timezone: controls.timezone ?? '',
      collectPlayerAddress: controls.collectPlayerAddress ?? false,
      senderEmail: controls.senderEmail ?? '',
      pushNotificationMessageFeeCost: controls.pushNotificationMessageFeeCost ?? 0,
      maxReceiptAttempts: controls.maxReceiptAttempts ?? 0,
      displayNonWinnerName: controls.displayNonWinnerName ?? false,
      allowFreeTickets: controls.allowFreeTickets ?? false,
      contactEmail: controls.contactEmail ?? '',
      twilioAuthToken: controls.twilioAuthToken ?? '',
      twilioPhoneNumber: controls.twilioPhoneNumber ?? '',
      randomApiOverrideId: controls.randomApiOverrideId ?? '',
      twilioSid: controls.twilioSid ?? '',
      loginMoreInfoUrl: controls.loginMoreInfoUrl ?? '',
      maxPledgesPerPlayer: controls.maxPledgesPerPlayer ?? 0,
      shortName: controls.shortName ?? '',
      gameMoreInformation: controls.gameMoreInformation ?? '',
      minimumAgeOfParticipation: controls.minimumAgeOfParticipation ?? 19,
      useStripeConnect: controls.useStripeConnect ?? false
    };
  }

  onValidateGameClick() {
    this.validationErrors = [];

    this.uiState = UIStateEnum.ShowRequestProcessing;
    this.getSaveGameRequest().pipe(
      concatMap((saveRes) => {
        if (saveRes != null) {
          return this.newGameService.completeGameAndValidate(this.createAzureResources);
        }
        return of(null);
      })).subscribe({
      next: (validateResP) => {
        if (validateResP != null) {
          this.uiState = UIStateEnum.ShowData;
          this.snackbarService.openSuccessfulSnackBar('New game has been saved and new game is valid!.')
        } else {
          this.uiState = UIStateEnum.ShowData;
          this.snackbarService.openErrorSnackBar('Something went wrong validating your game.')
        }

      }, error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        let errors = err.Error as IApiResponseBase;
        this.validationErrors = errors.Errors ?? [];
      }
    })
  }

  protected readonly uiStateEnumForTemplate = UIStateEnum;
  protected readonly AddPlayerGameNoteComponent = AddPlayerGameNoteComponent;
}
