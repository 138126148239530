@if (playerInfo) {
  <c-container class="mt-3">
    <c-row>
      <c-col>
        <h2 data-testid="chosen-player-name">{{ playerInfo.FirstName }} {{ playerInfo.LastName }}
          <small data-testid="chosen-player-id">({{ playerInfo.Id }})</small>
        </h2>
        <div class="margin-bottom-med">
          <button mat-raised-button (click)="chooseNewPlayerClick()" data-testid="chosen-player-back-button">
            <mat-icon>arrow_back_ios</mat-icon>
            Back
          </button>
        </div>
      </c-col>
    </c-row>

    @if (playerInfo?.PlayerGames?.length == 0) {
      <div>Looks like this player hasn't played in any games yet!!</div>
    }

    @if (allowUserToChangeGame) {
      <c-row>
        <c-col>
          <mat-card class="mb-4">
            <mat-card-content>
              <p>Choose a game in order to see the players data for that game</p>
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Choose Game</mat-label>
                <mat-select [(ngModel)]="chosenGame" (ngModelChange)="onGameChange($event)" data-testid="select-game">
                  @for (game of playerInfo.PlayerGames; track game) {
                    <mat-option data-testid="game-option" [value]="game" class="choose-game-option">
                      {{ game.ShortName ? game.ShortName : game.Name }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </mat-card-content>
          </mat-card>
        </c-col>
        <c-col>
          @if (gameLogo && chosenGame) {
            <div class="p-3 text-center"
                 [style]="chosenGame?.GameThemeTypeId == gameThemeType.Dark ? 'background-color: #222222; border-radius: 4px; height: 150px' : ''">
              <img alt="game logo" style="height: 100%" [src]="gameLogo"/>
            </div>
          }
        </c-col>
      </c-row>

    }

    @if (playerIsSuspendedInCurrentGame && chosenGame) {
      <div class="suspension-banner mb-3" data-testid="player-suspended-banner">
        Looks like this player is suspended in this game. Check out the Player Suspensions section to learn more or
        investigate the suspension
      </div>
    }

    @if (chosenGame) {
      <c-row class="mb-3">
        <c-col md="3" class="mb-3">
          <div cListGroup>
            <button cListGroupItem
                    data-testid="general-info-tab"
                    (click)="currentScreenState = screenStateEnum.GeneralInfo"
                    [active]="currentScreenState === screenStateEnum.GeneralInfo">
              <div>
                <h5 class="mb-1">General Info</h5>
                <p>view general info on player</p>
              </div>
            </button>

            <button *userHasCausableAdminAccess
                    cListGroupItem
                    data-testid="causable-suspensions-tab"
                    (click)="currentScreenState = screenStateEnum.CausableSuspensionsView"
                    [active]="currentScreenState === screenStateEnum.CausableSuspensionsView">
              <div>
                <h5 class="mb-1">Causable Suspensions</h5>
                <p>view any active and historical suspensions for this player from causable as a whole</p>
              </div>
            </button>

            @if (chosenGame) {
              <button cListGroupItem
                      data-testid="player-numbers-tab"
                      *hasPermission="PermissionTypes.ViewPlayerNumbers"
                      (click)="currentScreenState = screenStateEnum.PlayerNumbersView"
                      [active]="currentScreenState === screenStateEnum.PlayerNumbersView">
                <div>
                  <h5 class="mb-1">Player Number Management</h5>
                  <p>view and manage this players numbers</p>
                </div>
              </button>
              <button cListGroupItem
                      data-testid="notes-tab"
                      *hasPermission="PermissionTypes.ViewPlayerNotes"
                      (click)="currentScreenState = screenStateEnum.NotesView"
                      [active]="currentScreenState === screenStateEnum.NotesView">
                <div>
                  <h5 class="mb-1">Player Note Management</h5>
                  <p>view, edit and add players notes</p>
                </div>
              </button>
              <button cListGroupItem
                      *hasPermission="PermissionTypes.ViewPlayerPledges"
                      (click)="currentScreenState = screenStateEnum.PledgesView"
                      [active]="currentScreenState === screenStateEnum.PledgesView">
                <div>
                  <h5 class="mb-1">Player Pledges</h5>
                  <p>view any active and historical player charity pledges</p>
                </div>
              </button>
              <button *hasPermission="PermissionTypes.ViewPlayerTransactions"
                      cListGroupItem
                      data-testid="transactions-tab"
                      (click)="currentScreenState = screenStateEnum.TransactionView"
                      [active]="currentScreenState === screenStateEnum.TransactionView">
                <div>
                  <h5 class="mb-1">Transactions / Payments</h5>
                  <p>view all transactions this player has made for this game</p>
                </div>
              </button>
              <button
                *hasPermission="PermissionTypes.ViewPlayerSuspensions"
                cListGroupItem
                data-testid="suspensions-tab"
                (click)="currentScreenState = screenStateEnum.SuspensionsView"
                [active]="currentScreenState === screenStateEnum.SuspensionsView">
                <div>
                  <h5 class="mb-1">Suspensions</h5>
                  <p>view any active and historical suspensions for this player</p>
                </div>
              </button>
              <button *hasPermission="PermissionTypes.ViewSendgridData"
                      cListGroupItem
                      data-testid="sendgrid-tab"
                      (click)="currentScreenState = screenStateEnum.SendGridView"
                      [active]="currentScreenState === screenStateEnum.SendGridView">
                <div>
                  <h5 class="mb-1">Email Details</h5>
                  <p>View a player's last five emails that have failed to send and view and disable email
                    suppressions</p>
                </div>
              </button>
            }
          </div>
        </c-col>

        <c-col>
          @switch (currentScreenState) {
            @case (screenStateEnum.GeneralInfo) {
              <app-manage-player-general-info
                [gamePlayerData]="{playerId: playerInfo.Id, game: chosenGame!, playerInfo: playerInfo}"
                (getFreshPlayerInfoEvent)="getAllPlayerInfo()"></app-manage-player-general-info>
            }
            @case (screenStateEnum.PlayerNumbersView) {
              <app-manage-player-numbers
                [gamePlayerData]="{playerId: playerInfo.Id, game: chosenGame!}"></app-manage-player-numbers>
            }
            @case (screenStateEnum.NotesView) {
              <app-manage-player-notes
                [gamePlayerData]="{playerId: playerInfo.Id, game: chosenGame!}"></app-manage-player-notes>
            }
            @case (screenStateEnum.PledgesView) {
              <app-manage-player-pledges
                [gamePlayerData]="{playerId: playerInfo.Id, game: chosenGame!}"></app-manage-player-pledges>
            }
            @case (screenStateEnum.TransactionView) {
              <app-manage-player-transaction
                [gamePlayerData]="{playerId: playerInfo.Id, game: chosenGame!}"></app-manage-player-transaction>
            }
            @case (screenStateEnum.SuspensionsView) {
              <app-manage-player-suspensions [suspensions]="playerSuspensions"
                                             [isCausableSuspensionTab]="false"
                                             [gamePlayerData]="{playerId: playerInfo.Id, game: chosenGame!}"></app-manage-player-suspensions>
            }
            @case (screenStateEnum.SendGridView) {
              <app-manage-player-send-grid-info [playerEmail]="playerInfo.Email"
                                                [gamePlayerData]="{playerId: playerInfo.Id, game: chosenGame!}"></app-manage-player-send-grid-info>
            }
            @case (screenStateEnum.CausableSuspensionsView) {
              <app-manage-player-suspensions [suspensions]="playerSuspensions"
                                             [isCausableSuspensionTab]="true"
                                             [gamePlayerData]="{playerId: playerInfo.Id, game: chosenGame!}"></app-manage-player-suspensions>
            }
          }
        </c-col>
      </c-row>

    }

  </c-container>

}


