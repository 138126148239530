<mat-card>
  <mat-card-header>
    <div class="flex-row justify-space-between full-width">

      <h3>Charity Admins</h3>
      <button mat-raised-button
              data-testid="add-game-admin-button"
              *hasPermission="permissionTypes.ModifyAdminUsers"
              color="primary"
              (click)="onInviteUserClick()"
              matTooltip="Invite New Game Admin"
              matTooltipShowDelay="500">Add Charity Admin
        <mat-icon>add_circle</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    @if (uiState == uiStateForTemplate.ShowLoading) {
      <mat-spinner class="center-margin"></mat-spinner>
    } @else {
      <ag-grid-angular
        data-testid="charity-admin-user-table"
        id="charityAdminGrid"
        class="ag-theme-quartz margin-top-med margin-bottom-med"
        [tooltipShowDelay]=0
        domLayout='autoHeight'
        [rowHeight]="50"
        rowClass="table-row"
        (firstDataRendered)="onFirstDataRendered($event)"
        (gridSizeChanged)="gridSizeChanged($event)"
        overlayNoRowsTemplate="<span>No charity admins for this game</span>"
        [rowData]="charityAdminUsers"
        [columnDefs]="charityAdminColDefs">
      </ag-grid-angular>
    }
  </mat-card-content>
</mat-card>
