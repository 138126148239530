import {Component, Input} from "@angular/core";
import {ReportingService} from "../../services/reporting.service";
import {FormatCurrencyPipe} from "../../pipes/format-currency.pipe";
import {PdfUtilityService} from "../../services/pdf-utility.service";
import {DatePipe} from "@angular/common";
import {GameTypeEnum} from "../../enum/GameTypeEnum";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {IGameInstanceShared} from "../../interfaces/IGameInstanceShared";
import {WinnerPayoutStatusPipe} from "../../pipes/winner-payout-status.pipe";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";

@Component({
  selector: 'base-page',
  template: 'base-template'
})
export abstract class ReportBaseComponent {

  public gameTypes = GameTypeEnum;
  public errorMessage: string = '';
  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public uiStateEnumForTemplate = UIStateEnum;
  public activeGame: IGameQueryResult | null = null;

  @Input() set updateChosenGameInstance(gameInstanceP: IGameInstanceShared) {
    this.chosenGameInstance = gameInstanceP;
    this.generateReport(gameInstanceP);
  }

  public chosenGameInstance?: IGameInstanceShared;

  protected constructor(protected reportingService: ReportingService,
                        protected currencyPipe: FormatCurrencyPipe,
                        protected winnerPayoutStatusPipe: WinnerPayoutStatusPipe,
                        protected pdfUtilityService: PdfUtilityService,
                        protected datePipe: DatePipe) {
  }

  abstract generateReport(gameInstanceP: IGameInstanceShared): any;
}
