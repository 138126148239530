import {Component, inject} from '@angular/core';
import {
  ButtonCloseDirective,
  ButtonDirective,
  FormCheckComponent,
  FormCheckInputDirective,
  FormCheckLabelDirective,
  FormControlDirective,
  FormDirective,
  FormFeedbackComponent,
  FormLabelDirective,
  ModalBodyComponent,
  ModalComponent,
  ModalFooterComponent,
  ModalHeaderComponent,
  ModalTitleDirective,
  ModalToggleDirective
} from "@coreui/angular";
import {DialogBaseComponent} from "../../dialogs/dialog-base/dialog-base.component";
import {MarkdownComponent} from "ngx-markdown";
import {MatButton} from "@angular/material/button";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {FrequentlyAskedQuestionsService} from "../../../services/frequently-asked-questions.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {MatDialogRef} from "@angular/material/dialog";
import {IApiErrorResponse} from "../../../interfaces/error/IApiErrorResponse";

@Component({
  selector: 'app-faq-add',
  standalone: true,
  imports: [
    ButtonCloseDirective,
    ButtonDirective,
    DialogBaseComponent,
    FormCheckComponent,
    FormCheckInputDirective,
    FormCheckLabelDirective,
    FormControlDirective,
    FormDirective,
    FormFeedbackComponent,
    FormLabelDirective,
    MarkdownComponent,
    MatButton,
    ModalBodyComponent,
    ModalComponent,
    ModalFooterComponent,
    ModalHeaderComponent,
    ModalTitleDirective,
    ReactiveFormsModule,
    ModalToggleDirective
  ],
  templateUrl: './faq-add.component.html',
  styleUrl: './faq-add.component.css'
})
export class FaqAddComponent {
  private frequentlyAskedQuestionsService: FrequentlyAskedQuestionsService = inject(FrequentlyAskedQuestionsService);
  private snackbarService: SnackbarService = inject(SnackbarService);

  uiState: UIStateEnum = UIStateEnum.ShowData;
  uiStateEnumForTemplate = UIStateEnum;
  answerContentControl = new FormControl<string | null>("", Validators.required);
  questionControl = new FormControl<string | null>("", Validators.required);
  activeControl = new FormControl<boolean>(false);

  addFaqForm = new FormGroup({
    question: this.questionControl,
    answer: this.answerContentControl,
    active: this.activeControl
  });

  constructor(private matDialogRef: MatDialogRef<FaqAddComponent>) {
  }

  addFaq() {
    this.addFaqForm.markAllAsTouched();

    if (!this.addFaqForm.valid) {
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;

    this.frequentlyAskedQuestionsService.addFaq({
      active: this.activeControl.value!,
      answer: this.answerContentControl.value!,
      question: this.questionControl.value!
    }).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
        this.snackbarService.openSuccessfulSnackBar('Successfully added FAQ');
        this.matDialogRef.close();
      }, error: (res: IApiErrorResponse) => {
        this.uiState = UIStateEnum.ShowData;
        if (res.Error?.ClientErrorMessages?.length > 0) {
          this.snackbarService.openErrorSnackBar(res.Error.ClientErrorMessages.toString());
        } else {
          this.snackbarService.openErrorSnackBar('There was an issue adding your FAQ');
        }
      }
    })
  }
}
