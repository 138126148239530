import {Component, inject, Input, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {AdminUserTables} from "../../../table-definitions/admin-user-tables";
import {ActiveUserService} from "../../../services/active-user.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {UsersService} from "../../../services/users.service";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";
import {ColDef, FirstDataRenderedEvent, GridSizeChangedEvent} from "ag-grid-community";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {AddNewAdminComponent} from "../../dialogs/add-new-admin/add-new-admin.component";
import {AdminInviteType} from "../../../enum/AdminInviteType";
import {AgGridAngular} from "ag-grid-angular";
import {MatButton} from "@angular/material/button";
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {IAdmin} from "../../../interfaces/IAdmin";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-causable-admin-user-view',
  standalone: true,
  imports: [
    AgGridAngular,
    MatButton,
    MatCard,
    MatCardContent,
    MatCardHeader,
    MatIcon,
    MatTooltip,
    MatProgressSpinner
  ],
  templateUrl: './causable-admin-user-view.component.html',
  styleUrl: './causable-admin-user-view.component.css'
})
export class CausableAdminUserViewComponent implements OnInit {
  private matDialog: MatDialog = inject(MatDialog);
  private adminUserTables: AdminUserTables = inject(AdminUserTables);
  private activeUserService: ActiveUserService = inject(ActiveUserService);
  private snackbarService: SnackbarService = inject(SnackbarService);
  private usersService: UsersService = inject(UsersService);
  public uiState = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;

  @Input() set gameSetter(gameP: IGameQueryResult) {
    this.chosenGame = gameP;
    this.causableAdminColDefs = this.adminUserTables.causableAdminColDefs(this.chosenGame?.Id!, true);
    this.fetchCausableAdmins();
  }

  private chosenGame: IGameQueryResult | null = null;
  public causableAdminUsers: IAdmin[] = [];
  public causableAdminColDefs: ColDef[] = [];

  permissionTypes = PermissionTypes;

  ngOnInit(): void {
    this.usersService.getUpdatedAdminUsersBehaviorSubject.subscribe({
      next: (res) => {
        if (res) {
          this.fetchCausableAdmins();
        }
      }
    })
  }

  private fetchCausableAdmins() {
    this.uiState = UIStateEnum.ShowLoading;

    this.usersService.fetchCausableAdmins(this.activeUserService.activeUser().Id).subscribe({
        next: (res) => {
          this.causableAdminUsers = res;
          this.uiState = UIStateEnum.ShowData;
        },
        error: () => {
          this.snackbarService.openErrorSnackBar('Failed to retrieve causable admins. Please try again or contact your system admin.');
          this.uiState = UIStateEnum.ShowData;
        }
      }
    )
  }

  onInviteUserClick() {
    let dialogRef = this.matDialog.open(AddNewAdminComponent, {
      data: {
        inviteType: AdminInviteType.CausableInvite,
        game: this.chosenGame
      },
      width: '650px'
    });

    dialogRef.afterClosed().subscribe({
      next: (updateSuccess) => {
        if (updateSuccess) {
          this.fetchCausableAdmins();
        }
      }
    })
  }

  gridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }
}
