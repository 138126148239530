import {Component} from '@angular/core';
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatTooltip} from "@angular/material/tooltip";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {UsersService} from "../../../services/users.service";
import {ICellRendererParams} from "ag-grid-community";
import {IGameLotteryLicence} from "../../../interfaces/IGameLotteryLicence";
import {MatDialog} from "@angular/material/dialog";
import {LotteryLicenceEditComponent} from "../../lottery-licences/lottery-licence-edit/lottery-licence-edit.component";

@Component({
  selector: 'app-lottery-licence-settings',
  standalone: true,
  imports: [
    HasPermissionDirective,
    MatIcon,
    MatIconButton,
    MatTooltip
  ],
  templateUrl: './lottery-licence-settings.component.html',
  styleUrl: './lottery-licence-settings.component.css'
})
export class LotteryLicenceSettingsComponent implements ICellRendererAngularComp {
  public permissionTypes = PermissionTypes;
  public rowData!: IGameLotteryLicence;
  public params!: any;

  constructor(private userService: UsersService,
              private matDialog: MatDialog) {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.rowData = params.data;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  onEditLotteryLicence() {
    this.matDialog.open(LotteryLicenceEditComponent, {
      data: this.rowData
    });
  }
}
