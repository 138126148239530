import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SupportRequestService} from '../../../../services/support-request.service';
import {GameService} from '../../../../services/game.service';
import {ActiveUserService} from '../../../../services/active-user.service';
import {SnackbarService} from '../../../../services/snackbar.service';
import {BaseSupportRequestViewComponent} from "../base-support-request-view.component";
import {MatIcon} from "@angular/material/icon";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {ColComponent, ContainerComponent, RowComponent} from "@coreui/angular";
import {
  DisplaySupportRequestsComponent
} from "../../../../components/support-requests/display-support-requests/display-support-requests.component";
import {HasPermissionDirective} from "../../../../directives/permissions/has-permission";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {
  UserHasCausableAdminAccessDirective
} from "../../../../directives/permissions/user-has-causable-admin-access.directive";
import {CdkCopyToClipboard} from "@angular/cdk/clipboard";
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";

@Component({
  selector: 'app-cross-game-view-support-request',
  standalone: true,
  templateUrl: './cross-game-view-support-request.component.html',
  imports: [
    MatButton,
    MatIcon,
    MatIconButton,
    UserHasCausableAdminAccessDirective,
    CdkCopyToClipboard,
    MatProgressSpinner,
    ContainerComponent,
    RowComponent,
    ColComponent,
    DatePipe,
    FormsModule,
    MatError,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    NgIf,
    ReactiveFormsModule,
    HasPermissionDirective,
    MatInput,
    DisplaySupportRequestsComponent,
    NgForOf,
    MatCard,
    MatCardContent,
    MatCardHeader
  ],
  styleUrls: ['./cross-game-view-support-request.component.css']
})
export class CrossGameViewSupportRequestComponent extends BaseSupportRequestViewComponent {

  constructor(
    supportRequestService: SupportRequestService,
    gameService: GameService,
    activeUserService: ActiveUserService,
    activatedRoute: ActivatedRoute,
    snackBarService: SnackbarService,
    router: Router
  ) {
    super(supportRequestService, gameService, activeUserService, activatedRoute, snackBarService, router);
  }
}


