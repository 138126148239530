import {Component, inject, OnInit} from '@angular/core';
import {MatCardModule} from "@angular/material/card";
import {ICharitySupporterTotalsReport} from "../../../interfaces/charity/ICharitySupporterTotalsReport";
import {CharityService} from "../../../services/charity.service";
import {ICharityWithContributionTotals} from "../../../interfaces/charity/ICharityWithContributionTotals";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {SnackbarService} from "../../../services/snackbar.service";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatHint} from "@angular/material/form-field";
import {MatTooltip} from "@angular/material/tooltip";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-charity-supporter-details',
  standalone: true,
  imports: [
    MatCardModule,
    MatProgressSpinner,
    MatHint,
    MatTooltip,
    MatIcon
  ],
  templateUrl: './charity-supporter-details.component.html',
  styleUrl: './charity-supporter-details.component.css'
})
export class CharitySupporterDetailsComponent implements OnInit {
  private charityService: CharityService = inject(CharityService);
  private snackBarService: SnackbarService = inject(SnackbarService);

  protected charitySupportersReport: ICharitySupporterTotalsReport | null = null;
  protected charity: ICharityWithContributionTotals | undefined = this.charityService.activeCharity();
  protected uiState = UIStateEnum.ShowData;
  protected uiStateForTemplate = UIStateEnum;

  ngOnInit(): void {
    this.charityService.fetchCharitySupporters(this.charity!.Id).subscribe({
      next: (res) => {
        this.charitySupportersReport = res;
      },
      error: () => {
        this.snackBarService.openErrorSnackBar('Looks like there was a problem loading your report. Please try again or contact a system administrator');
        this.uiState = UIStateEnum.ShowData;
      }
    })
  }
}
