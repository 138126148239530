<h3>Suppressions</h3>
<div class="flex-col">
  @if (uiState === uiStateEnumForTemplate.ShowData) {
    <mat-list>
      <mat-list-item>
        <span matListItemTitle>Globally Unsubscribed</span>
        <mat-icon matListItemIcon>{{ playerSuppressions!.IsGloballyUnsubscribed ? "check" : "do_not_disturb" }}
        </mat-icon>
      </mat-list-item>

      <mat-list-item>
        <span matListItemTitle>Group Unsubscribes</span>
        <span matListItemLine>{{ groupUnsubscribes }}</span>
      </mat-list-item>

      <mat-list-item>
        <span matListItemTitle>Marked as Spam</span>
        <mat-icon matListItemIcon>{{ playerSuppressions!.MarkedAsSpam ? "check" : "do_not_disturb" }}</mat-icon>
      </mat-list-item>
    </mat-list>

    <button *hasPermission="permissionTypes.RemoveSendgridSuppressions"
            mat-stroked-button
            (click)="removeUnsubscribes()">Remove Unsubscribes and Spam Reports
    </button>

    <h4>Last Five Failed Emails</h4>
    <ag-grid-angular
      id="playerLastFiveFailedEmailsGrid"
      class="ag-theme-quartz margin-top-med large-table"
      data-testid="last-five-failed-emails-table"
      [tooltipShowDelay]=0
      rowClass="table-row"
      domLayout='autoHeight'
      overlayNoRowsTemplate="<span>Player has not had any emails fail to be delivered</span>"
      (gridReady)="onSendGridMessageDataGridReady($event)"
      [rowData]="(lastFiveFailedEmails$ | async)?.Messages"
      [columnDefs]="sendGridColDefs">
    </ag-grid-angular>

    <!--This will be disabled until/unless we decide to use the SendGrid Webhook -->

    @if (sendGridEventsEnabled) {
      <h4>Event Filter</h4>
      <form [formGroup]="sendGridEventFilterFormGroup">
        <div class="flex-row-wrap justify-space-between">
          <mat-form-field appearance="outline" class="flex-50">
            <mat-label>Template Name</mat-label>
            <mat-select placeholder="Select Template" formControlName="templateId">
              @for (type of emailTemplateTypes; track type.value) {
                <mat-option [value]="type.value">{{ type.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="flex-50">
            <mat-label>Event</mat-label>
            <mat-select placeholder="Select Event" formControlName="event">
              @for (event of eventTypes; track event) {
                <mat-option [value]="event.toLocaleLowerCase()">{{ event }}</mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="flex-50">
            <mat-label>Email</mat-label>
            <input type="text" formControlName="email" matInput placeholder="Enter Email"
                   data-testid="email-input">
          </mat-form-field>

          <mat-form-field class="flex-50" appearance="outline">
            <mat-label>Date Range</mat-label>
            <mat-date-range-input
              [rangePicker]="rangePicker">
              <input matStartDate placeholder="From" formControlName="fromDate" data-testid="from-date-input">
              <input matEndDate placeholder="To" formControlName="toDate" data-testid="to-date-input">
            </mat-date-range-input>
            <mat-hint>MM-DD-YYYY – MM-DD-YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #rangePicker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </form>

      <div class="text-end">
        <button mat-raised-button class="margin-right-small" color="primary" (click)="onFormSubmit();">Filter</button>
        <button mat-raised-button (click)="onResetClick();">Reset</button>
      </div>

      <ag-grid-angular
        class="ag-theme-quartz margin-top-med"
        data-testid="sendgrid-event-table"
        [tooltipShowDelay]="0"
        [pagination]="true"
        [rowData]="events$ | async"
        (gridReady)="onSendGridEventGridReady($event)"
        (rowClicked)="onRowClicked($event)"
        [columnDefs]="columnDefs"
        domLayout="autoHeight"
        [suppressPaginationPanel]="true"
        overlayNoRowsTemplate="<span>No events to display</span>">
      </ag-grid-angular>

      <div class="custom-paginator">
        <span>{{ fromNumber }}</span> to <span>{{ toNumber }}</span> of <span
        class="margin-right-large">{{ totalRecords }}</span>
        <mat-icon (click)="onBtFirst()" [ngClass]="pageNumber === 1 ? 'disabled' : ''">first_page</mat-icon>
        <mat-icon (click)="onBtPrevious()" [ngClass]="pageNumber === 1 ? 'disabled' : ''">navigate_before</mat-icon>
        Page <span>{{ pageNumber }}</span> of <span>{{ totalPages }}</span>
        <mat-icon (click)="onBtNext()" [ngClass]="pageNumber === pagedEventResponse?.TotalPages ? 'disabled' : ''">
          navigate_next
        </mat-icon>
        <mat-icon (click)="onBtLast()" [ngClass]="pageNumber === pagedEventResponse?.TotalPages ? 'disabled' : ''">
          last_page
        </mat-icon>
      </div>
    }
  } @else {
    <mat-spinner class="center-margin"></mat-spinner>
  }
</div>
