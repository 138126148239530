<mat-card class="full-height">
  <mat-card-header>
    <mat-card-title>Charity Supporter Details</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    @if (charitySupportersReport && uiState == uiStateForTemplate.ShowData) {

      <div class="margin-top-med">
        <div class="report-label">
          Actively Pledged Supporters
          <mat-icon
            class="info-icon"
            matTooltipPosition="right"
            matTooltip="This is the number of users who currently have this charity selected as one of their pledged charities">
            info_outline
          </mat-icon>
        </div>
        <div
          class="report-value"
          data-testid="charity-details-active-supporters">{{ charitySupportersReport.ActiveSupporters }}
        </div>
      </div>

      <div>
        <div class="report-label">Supporters No Longer Pledged
          <mat-icon
            class="info-icon"
            matTooltipPosition="right"
            matTooltip="This is the number of users who used to be pledged to your charity but no longer are">
            info_outline
          </mat-icon>
        </div>
        <div
          class="report-value"
          data-testid="charity-details-inactive-supporters">{{ charitySupportersReport.InactiveSupporters }}
        </div>
      </div>

      <div>
        <div class="report-label">Supporters on Autoplay
          <mat-icon
            class="info-icon"
            matTooltipPosition="right"
            matTooltip="This is the number of users who are pledged to your charity and have autoplay enabled">
            info_outline
          </mat-icon>
        </div>
        <div
          class="report-value"
          data-testid="charity-details-autoplay-supporters">{{ charitySupportersReport.AutoplaySupporters }}
        </div>
      </div>

      <div>
        <div class="report-label">Exclusive Supporters
          <mat-icon
            class="info-icon"
            matTooltipPosition="right"
            matTooltip="This is the number of users who are pledged ONLY to your charity">
            info_outline
          </mat-icon>
        </div>
        <div
          class="report-value"
          data-testid="charity-details-exclusive-supporters">{{ charitySupportersReport.UniqueSupporters }}
        </div>
      </div>

      <div>
        <div class="report-label">Partial Supporters
          <mat-icon
            class="info-icon"
            matTooltipPosition="right"
            matTooltip="This is the number of users who are pledged to your charity, but also pledged to other charities">
            info_outline
          </mat-icon>
        </div>
        <div
          class="report-value"
          data-testid="charity-details-partial-supporters">{{ charitySupportersReport.PartialSupporters }}
        </div>
      </div>
    } @else {
      <mat-spinner class="center-margin"></mat-spinner>
    }
  </mat-card-content>
</mat-card>
