import {Component, EventEmitter, inject, Output} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NewGameService} from "../../../services/new-game.service";
import {IInitNewGame} from "../../../interfaces/new-game/breakdown/IInitNewGame";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MatCard} from "@angular/material/card";
import {MatError, MatFormField, MatHint, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatButton} from "@angular/material/button";
import {ActiveUserService} from "../../../services/active-user.service";
import {MatOption, MatSelect} from "@angular/material/select";
import {CountryService} from "../../../services/country.service";
import {ICountry} from "../../../interfaces/ICountry";

@Component({
  selector: 'app-init-game',
  standalone: true,
  templateUrl: './init-game.component.html',
  imports: [
    MatCard,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatHint,
    MatInput,
    MatError,
    MatProgressSpinner,
    MatButton,
    MatSelect,
    MatOption
  ],
  styleUrl: './init-game.component.css'
})
export class InitGameComponent {

  @Output() newGameWasInitiated = new EventEmitter();

  ngOnInit(): void {
    this.populateCountries();
  }

  private newGameService: NewGameService = inject(NewGameService);
  private activeUserService: ActiveUserService = inject(ActiveUserService);
  private countryService: CountryService = inject(CountryService);
  private errorHandlingService: ErrorHandlingService = inject(ErrorHandlingService);
  private noSpacesRegex: RegExp = /^\S*$/;

  public countries: ICountry[] = [];

  uiState: UIStateEnum = UIStateEnum.ShowData;

  uiStateEnumForTemplate = UIStateEnum;  initGameForm = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    subdomain: new FormControl<string>('', [Validators.required, Validators.pattern(this.noSpacesRegex)]),
    country: new FormControl<string>('', [Validators.required])
  });

  startGameCreation() {
    if(!this.initGameForm.valid) {
      this.initGameForm.markAllAsTouched();
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;

    let initGame: IInitNewGame = {
      name: this.initGameForm.controls.name.value!,
      subdomain: this.initGameForm.controls.subdomain.value!,
      countryId: this.initGameForm.controls.country.value!
    };

    this.newGameService.initNewGame(initGame, this.activeUserService.activeUser().Id).subscribe({
      next:() => {
        this.uiState = UIStateEnum.ShowData;
        this.newGameWasInitiated.emit(true);
      },
      error:(err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });

  }

  populateCountries() {
    this.countryService.allCountries().subscribe({
      next: (countriesP) => {
        this.countries = countriesP;
      },
      error: (error) => {
        this.errorHandlingService.displayDialogLevelErrorMessage(error);
      }
    });
  }
}
