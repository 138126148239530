import { Component } from '@angular/core';
import {ContainerComponent} from "@coreui/angular";
import {ChooseYourGameComponent} from "../../../components/choose-your-game/choose-your-game.component";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";
import {ReportsComponent} from "../reports.component";

@Component({
  selector: 'app-cross-game-reporting',
  standalone: true,
  imports: [
    ContainerComponent,
    ChooseYourGameComponent,
    ReportsComponent
  ],
  templateUrl: './cross-game-reporting.component.html',
  styleUrl: './cross-game-reporting.component.css'
})
export class CrossGameReportingComponent {

  protected game: IGameQueryResult | null = null;

  onGameChange(gameP: IGameQueryResult) {
    this.game = gameP;
  }
}
