import {Component} from '@angular/core';
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";
import {GameService} from "../../../services/game.service";
import {AppConfigService} from "../../../services/app-config.service";
import {MatDialog} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {ActiveUserService} from "../../../services/active-user.service";
import {Router} from "@angular/router";
import {MatButton, MatIconButton} from "@angular/material/button";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {AsyncPipe, DatePipe} from "@angular/common";
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {PipesModule} from "../../../pipes/pipes.module";
import {
  LotteryLicencesTableComponent
} from "../../../components/lottery-licences/lottery-licences-table/lottery-licences-table.component";
import {
  LotteryLicenceAddComponent
} from "../../../components/lottery-licences/lottery-licence-add/lottery-licence-add.component";
import {
  TestimonialsTableComponent
} from "../../../components/testimonials/testimonials-table/testimonials-table.component";
import {AddTestimonialComponent} from "../../../components/testimonials/add-testimonial/add-testimonial.component";
import {
  EditGameDescriptionComponent
} from "../../../components/dialogs/edit-game-description/edit-game-description.component";
import {
  EditGameMoreInformationComponent
} from "../../../components/dialogs/edit-game-more-information/edit-game-more-information.component";
import {GameSettingsComponent} from "../../../components/game-settings/game-settings.component";
import {ContainerComponent} from "@coreui/angular";

@Component({
  selector: 'app-manage-game',
  standalone: true,
  templateUrl: './game-admin-manage-game.component.html',
  imports: [
    MatIconButton,
    HasPermissionDirective,
    MatMenuTrigger,
    MatIcon,
    MatTooltip,
    AsyncPipe,
    MatMenu,
    MatMenuItem,
    MatTab,
    MatTabGroup,
    MatButton,
    PipesModule,
    DatePipe,
    LotteryLicencesTableComponent,
    TestimonialsTableComponent,
    GameSettingsComponent,
    ContainerComponent
  ],
  styleUrls: ['./game-admin-manage-game.component.scss']
})
export class GameAdminManageGameComponent {

  public activeGame: IGameQueryResult  = this.gameService.activeGame();

  constructor(private gameService: GameService) {}

}
