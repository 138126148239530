import {Component, inject} from '@angular/core';
import {MatListOption, MatSelectionList} from "@angular/material/list";
import {ChooseYourGameComponent} from "../../../components/choose-your-game/choose-your-game.component";
import {
  ColComponent,
  ContainerComponent,
  ListGroupDirective,
  ListGroupItemDirective,
  RowComponent
} from "@coreui/angular";
import {DatePipe, NgClass} from "@angular/common";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatCard, MatCardActions, MatCardContent, MatCardHeader, MatCardTitle} from "@angular/material/card";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable
} from "@angular/material/table";
import {MatIcon} from "@angular/material/icon";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatTooltip} from "@angular/material/tooltip";
import {GameService} from "../../../services/game.service";
import {UserDetailsComponent} from "../user-details.component";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";

@Component({
  selector: 'app-game-admin-user-details',
  standalone: true,
  imports: [
    ChooseYourGameComponent,
    ColComponent,
    ContainerComponent,
    DatePipe,
    ListGroupDirective,
    ListGroupItemDirective,
    MatButton,
    MatCard,
    MatCardActions,
    MatCardContent,
    MatCardHeader,
    MatCardTitle,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatIcon,
    MatIconButton,
    MatListOption,
    MatProgressSpinner,
    MatRow,
    MatRowDef,
    MatSelectionList,
    MatTable,
    MatTooltip,
    RowComponent,
    NgClass,
    MatHeaderCellDef
  ],
  templateUrl: '../user-details.component.html',
  styleUrl: './game-admin-user-details.component.css'
})
export class GameAdminUserDetailsComponent extends UserDetailsComponent {

  private gameService: GameService = inject(GameService);

  override ngOnInit() {
    this.chosenGame = this.gameService.activeGame();
    super.ngOnInit();
  }

  onBackToUsersClick() {
    this.router.navigateByUrl(`${this.chosenGame?.Id}/game-admin-manage-users`);
  }

  onGameChange(gameP: IGameQueryResult) {
    this.selectedRoles = [];
  }
}
