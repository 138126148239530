import {Component} from '@angular/core';
import {CharityManagementComponent} from "../charity-management.component";
import {ContainerComponent} from "@coreui/angular";
import {ChooseYourGameComponent} from "../../../components/choose-your-game/choose-your-game.component";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";

@Component({
  selector: 'app-cross-game-charity-management',
  standalone: true,
  imports: [CharityManagementComponent, ContainerComponent, ChooseYourGameComponent],
  templateUrl: './cross-game-charity-management.component.html',
  styleUrl: './cross-game-charity-management.component.css'
})
export class CrossGameCharityManagementComponent {

  protected game: IGameQueryResult | null = null;

  onGameChosen(chosenGameP: IGameQueryResult) {
    this.game = chosenGameP;
  }
}
