import {Injectable} from '@angular/core';
import {Observable, of, tap} from 'rxjs';
import {createStore, select, setProp, withProps} from "@ngneat/elf";
import {APIService} from "./api.service";
import {updateRequestCache, withRequestsCache} from "@ngneat/elf-requests";
import {IAdminQueryResult} from "../interfaces/IAdminQueryResult";

@Injectable({
  providedIn: 'root'
})
export class ActiveUserService {

  private readonly defaultUser: IAdminQueryResult = {
    FirstName: '',
    Email: '',
    LastName: '',
    Id: '',
    Active: false,
    ConfirmedOn: new Date(),
    CreatedOn: new Date(),
    CausableAdmin: false,
    Phone: '',
    VerificationCode: '',
    AdminPermissions: [],
    Roles: [],
    Charities: [],
    CrossGamePermissions: []
  };
  private activeUserStore = createStore(
    {name: 'active-user'},
    withProps<{ activeUser: IAdminQueryResult }>({
      activeUser: this.defaultUser
    }),
    withRequestsCache<'active-user'>()
  );

  constructor(private apiService: APIService) {
  }

  public activeUser(): IAdminQueryResult {
    return this.activeUserStore.getValue().activeUser;
  }

  public selectActiveUser(): Observable<IAdminQueryResult> {
    return this.activeUserStore.pipe(select((activeUserStoreP) =>
      activeUserStoreP.activeUser
    ));
  }

  public userIsLoggedIn(): Observable<boolean> {
    return this.activeUserStore.pipe(select((state) => {
      return state.activeUser.Id != '';
    }));
  }

  public retrieveActiveUser(adminIdP: string): Observable<IAdminQueryResult> {
    const activeUser = this.activeUser();

    if (adminIdP && activeUser.Id === adminIdP) {
      return of(activeUser);
    }

    return this.apiService.MakeGetRequest<IAdminQueryResult>(`admin-user/logged-in-user/${adminIdP}`).pipe(tap((activeUserP) => {
      this.activeUserStore.update(
        updateRequestCache('active-user', {value: 'full'}),
        setProp('activeUser', activeUserP))
    }));
  }
}
