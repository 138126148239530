import {Component} from '@angular/core';
import {GameService} from "../../services/game.service";
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {FormatCurrencyPipe} from "../../pipes/format-currency.pipe";
import {AsyncPipe, DatePipe} from "@angular/common";
import {
  WinnerPayoutOptionsComponent
} from "../../components/winner-payouts/winner-payout-options/winner-payout-options.component";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {AgGridAngular} from "ag-grid-angular";
import {LoadingCardContentComponent} from "../../shared/loading-card-content/loading-card-content.component";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {IWinnerPayoutWithWithWinnerInfo} from "../../interfaces/IWinnerPayoutWithWithWinnerInfo";
import {WinnerPayoutStatusPipe} from "../../pipes/winner-payout-status.pipe";
import {WinnerPayoutService} from "../../services/winner-payout.service";
import {Observable} from "rxjs";
import {ContainerComponent} from "@coreui/angular";
import {ChooseYourGameComponent} from "../../components/choose-your-game/choose-your-game.component";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";

@Component({
  selector: 'app-winner-payouts',
  standalone: true,
  templateUrl: './winner-payouts.component.html',
  imports: [
    AgGridAngular,
    LoadingCardContentComponent,
    AsyncPipe,
    ContainerComponent,
    ChooseYourGameComponent
  ],
  styleUrls: ['./winner-payouts.component.css']
})
export class WinnerPayoutsComponent {

  public winnerPayouts$: Observable<IWinnerPayoutWithWithWinnerInfo[]> = this.winnerPayoutService.selectPayouts$();
  public chosenGame: IGameQueryResult | null = null;
  uiState = UIStateEnum.ShowLoading;

  public winnerPayoutDefs: ColDef[] = [
    {
      headerName: 'Draw Date',
      field: 'DrawTime',
      resizable: true,
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y');
        return convertedDate ? convertedDate : '';
      },
      sortable: true
    },
    {
      headerName: 'Winner Name',
      resizable: true,
      valueGetter: params => {
        let payout = params.data as IWinnerPayoutWithWithWinnerInfo;
        return payout.PlayerFirstName + ' ' + payout.PlayerLastName;
      },
      sortable: true
    },
    {
      headerName: 'Player Winnings',
      resizable: true,
      wrapHeaderText: true,
      sortable: true,
      field: 'PlayerWinnings',
      valueFormatter: params => {
        return this.currencyPipe.transform(params.value);
      }
    },
    {
      headerName: 'Payout Status',
      resizable: true,
      wrapHeaderText: true,
      sortable: true,
      field: 'PayoutStatus',
      valueFormatter: params => {
        return this.winnerPayoutStatusPipe.transform(params.value);
      }
    },
    {
      headerName: 'Actions',
      resizable: true,
      cellRenderer: WinnerPayoutOptionsComponent
    }
  ];

  public winnersGridApi!: GridApi<IWinnerPayoutWithWithWinnerInfo>;

  constructor(private currencyPipe: FormatCurrencyPipe,
              private winnerPayoutService: WinnerPayoutService,
              private winnerPayoutStatusPipe: WinnerPayoutStatusPipe,
              private errorHandlingService: ErrorHandlingService,
              private datePipe: DatePipe) {
  }

  fetchWinnerPayoutsPerGame(): void {
    this.winnerPayoutService.fetchWinnerPayouts(this.chosenGame?.Id!).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    })
  }

  onChosenGameChange(gameP: IGameQueryResult) {
    this.chosenGame = gameP;
    this.fetchWinnerPayoutsPerGame();
  }

  onWinnerPayoutGridReady(params: GridReadyEvent<IWinnerPayoutWithWithWinnerInfo>) {
    this.winnersGridApi = params.api;
    this.winnersGridApi.sizeColumnsToFit({
      defaultMinWidth: 90,
    });
  }

  protected readonly UIStateEnum = UIStateEnum;
}
