import {Component, OnInit} from '@angular/core';
import {LoadingCardContentComponent} from "../../../shared/loading-card-content/loading-card-content.component";
import {MatButton} from "@angular/material/button";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {MatTooltip} from "@angular/material/tooltip";
import {AgGridAngular} from "ag-grid-angular";
import {
  UserHasCausableAdminAccessDirective
} from "../../../directives/permissions/user-has-causable-admin-access.directive";
import {MatIcon} from "@angular/material/icon";
import {
  ColComponent,
  ContainerComponent,
  ListGroupDirective,
  ListGroupItemDirective,
  RowComponent
} from "@coreui/angular";
import {MatCard, MatCardContent, MatCardHeader, MatCardTitle} from "@angular/material/card";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {FormsModule} from "@angular/forms";
import {ChooseYourGameComponent} from "../../../components/choose-your-game/choose-your-game.component";
import {BaseManageUsers} from "../base-manage-users";
import {
  GameAdminUsersViewComponent
} from "../../../components/manage-users/game-admin-users-view/game-admin-users-view.component";
import {
  CausableAdminUserViewComponent
} from "../../../components/manage-users/causable-admin-user-view/causable-admin-user-view.component";
import {
  CrossGameAdminUsersViewComponent
} from "../../../components/manage-users/cross-game-admin-users-view/cross-game-admin-users-view.component";
import {
  CharityAdminUsersViewComponent
} from "../../../components/manage-users/charity-admin-users-view/charity-admin-users-view.component";
import {HasCrossGamePermissionDirective} from "../../../directives/permissions/has-cross-game-permission";

@Component({
  selector: 'app-manage-users',
  standalone: true,
  templateUrl: '../base-manage-users.html',
  imports: [
    LoadingCardContentComponent,
    MatButton,
    MatIcon,
    HasPermissionDirective,
    MatTooltip,
    AgGridAngular,
    UserHasCausableAdminAccessDirective,
    ColComponent,
    MatCard,
    MatCardContent,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    RowComponent,
    FormsModule,
    ContainerComponent,
    ChooseYourGameComponent,
    ListGroupDirective,
    ListGroupItemDirective,
    MatCardTitle,
    MatCardHeader,
    GameAdminUsersViewComponent,
    CausableAdminUserViewComponent,
    CrossGameAdminUsersViewComponent,
    CharityAdminUsersViewComponent,
    HasCrossGamePermissionDirective
  ],
  styleUrls: ['./cross-game-manage-users.component.scss']
})
export class CrossGameManageUsersComponent extends BaseManageUsers implements OnInit {

  ngOnInit() {
    this.enableGameSearch = true;
  }
}
