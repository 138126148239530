<c-container class="mt-5">
  <h1>Hello {{ user?.FirstName }} {{ user?.LastName }}</h1>
  @if (uiState === UIStateEnum.ShowData) {
    <div>
      @if (user?.CausableAdmin) {
        <div cListGroup class="margin-top-large" data-testid="causable-admin-game-list">
          <p>Choose a game to enter the admin app as a game admin</p>
          <h2>All Games</h2>
          @for (game of userGames; track game) {
            <a (click)="updateAndNavigateToActiveGame(game)" cListGroupItem class="causable-admin-game-option">{{ game.Name }}</a>
          }
        </div>
      } @else {
        @if (userGames && userGames.length > 0) {
          <div cListGroup data-testid="game-admin-game-list">
            <p>Looks like you administer multiple games. If you wish to view one of these games as a game admin, please
              choose from the list below.</p>
            <h2>Your Games</h2>
            @for (game of userGames; track game) {
              <a (click)="updateAndNavigateToActiveGame(game)" cListGroupItem class="game-admin-game-option">{{ game.Name }}</a>
            }
          </div>
        }

        @if (user?.Charities && user?.Charities!.length > 0) {
          <div cListGroup data-testid="charity-admin-charity-list">
            <p>Looks like you administer multiple charities. If you wish to view one of these charities as a charity
              admin, please choose from the list below.</p>
            <h2>My Charities</h2>
            @for (charity of user?.Charities; track charity) {
              <a (click)="updateAndNavigateToActiveCharity(charity)" cListGroupItem class="charity-admin-charity-option">{{ charity.Name }}</a>
            }
          </div>
        }
      }

    </div>
  } @else if (uiState === UIStateEnum.ShowLoading) {
    <mat-spinner class="center-margin"></mat-spinner>
  }
</c-container>
