<c-container class="mt-5">
  <h2>
    Help Desk Support Request
    <ng-container *ngIf="supportRequest?.Resolved">
      (Resolved
      <mat-icon class="green-text">check_circle</mat-icon>
      )
    </ng-container>
  </h2>

  <ng-container *ngIf="uiState === uiStateEnumForTemplate.ShowLoading; else supportContent">
    <mat-spinner class="center-margin"></mat-spinner>
  </ng-container>

  <ng-template #supportContent>
    <c-container *ngIf="supportRequest; else noSupport">

      @if (supportRequest.Escalated && !supportRequest.Resolved) {
        <div class="escalated-message-container">
          <div>Looks like this issue has been escalated to the Causable Dev Team.</div>
        </div>
      }

      <c-row class="margin-top-med">
        <c-col md="8">
          <app-display-support-requests [supportRequestSetter]="supportRequest"></app-display-support-requests>
        </c-col>
        @if (!supportRequest.Escalated) {
          <c-col md="4" *hasPermission="permissionTypes.ModifySupportRequests">
            <div>
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Assignee</mat-label>
                <mat-select [(ngModel)]="assigneeId" (ngModelChange)="assign()">
                  <mat-option
                    *ngFor="let admin of adminsToAssignRequestTo"
                    [value]="admin.Id">
                    {{ admin.FirstName }} {{ admin.LastName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="text-end margin-bottom-med">
                <button mat-stroked-button data-testid="support-request-dialog-assign-to-me-button"
                        (click)="assignToMe()">
                  Assign to me
                </button>
              </div>
            </div>
          </c-col>
        }
      </c-row>
      <c-col class="margin-bottom-large">
        <div>
          <div class="detail-label">User Description</div>
          <div class="detail-value">{{ supportRequest.ErrorDescription }}</div>
        </div>
        <ng-container *ngIf="supportRequest.Resolved">
          <div>
            <div class="detail-label">Resolution Description</div>
            <div class="detail-value">{{ supportRequest.ResolutionDescription }}</div>
          </div>
        </ng-container>
      </c-col>

      <c-row *ngIf="readyToResolve">
        <div class="detail-label margin-bottom-med">
          Please Describe How you Resolved the Support Request
        </div>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Description</mat-label>
          <textarea matInput data-testid="resolution-description-input"
                    [formControl]="resolutionDescriptionControl"></textarea>
          <mat-error *ngIf="(resolutionDescriptionControl.dirty || resolutionDescriptionControl.touched) &&
                                   resolutionDescriptionControl.errors">
            Description must not be empty
          </mat-error>
        </mat-form-field>
      </c-row>

      <div>
        @if (!supportRequest.Resolved) {
          @if (supportRequest.Escalated) {
            <button class="margin-top-med full-width"
                    data-testid="deescalate-request"
                    [disabled]="uiState === uiStateEnumForTemplate.ShowRequestProcessing"
                    (click)="deEscalateSupportRequest()"
                    mat-stroked-button>
              Issue does not need to be assigned to the Causable Team? Click here to de-escalate the issue.
            </button>
          } @else {
            <button class="margin-top-med full-width"
                    data-testid="escalate-request"
                    [disabled]="uiState == uiStateEnumForTemplate.ShowRequestProcessing"
                    (click)="escalateSupportRequest()"
                    mat-stroked-button>
              Need to escalate this issue to the Causable dev team ? Click here to escalate the issue.
            </button>
          }

          @if (!readyToResolve) {
            <button class="margin-top-med full-width"
                    data-testid="ready-to-resolve-support-request"
                    [disabled]="uiState === uiStateEnumForTemplate.ShowRequestProcessing"
                    (click)="readyToResolve = true"
                    mat-stroked-button>
              Ready to Resolve the Support Request?
            </button>
          } @else {
            <button class="margin-top-med full-width"
                    data-testid="update-player-basic-info-button"
                    [disabled]="uiState === uiStateEnumForTemplate.ShowRequestProcessing"
                    (click)="updateRequestResolvedState(true)"
                    mat-stroked-button>
              <mat-icon>check</mat-icon>
              <ng-container *ngIf="uiState === uiStateEnumForTemplate.ShowRequestProcessing">
                <mat-spinner diameter="32" color="accent"></mat-spinner>
              </ng-container>
              <ng-container *ngIf="uiState !== uiStateEnumForTemplate.ShowRequestProcessing">
                Resolve Support Request
              </ng-container>
            </button>
          }
        } @else {
          <button class="margin-top-med full-width"
                  data-testid="update-player-basic-info-button"
                  [disabled]="uiState === uiStateEnumForTemplate.ShowRequestProcessing"
                  (click)="updateRequestResolvedState(false)"
                  mat-stroked-button>
            <ng-container *ngIf="uiState === uiStateEnumForTemplate.ShowRequestProcessing">
              <mat-spinner diameter="32" color="accent"></mat-spinner>
            </ng-container>
            <ng-container *ngIf="uiState !== uiStateEnumForTemplate.ShowRequestProcessing">
              Re-open the support request
            </ng-container>
          </button>
        }
      </div>
    </c-container>
    <ng-template #noSupport>
      <div>No information for the provided Support request.</div>
    </ng-template>
  </ng-template>
</c-container>
