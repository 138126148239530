<app-dialog-base>
  <span dialog-title data-testid="title">Show Winner Name</span>

  <ng-container dialog-content>
    <p data-testid="generic-dialog-message">Looks like this game has the ability to hide the name of the winner if they
      have not played their winning ticket number for the draw. Would you like the winners name to appear on the generated PDF?</p>
  </ng-container>
  <ng-container actions>
    <button data-testid="do-not-show-winner-name"
            mat-button
            [mat-dialog-close]="false"
            class="primary-button">Hide Winner Name
    </button>
    <button data-testid="do-show-winner-name"
            mat-button
            [mat-dialog-close]="true"
            class="primary-button">Show Winner Name
    </button>
  </ng-container>
</app-dialog-base>
