import {Directive, Input, TemplateRef, ViewContainerRef} from "@angular/core";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {PermissionsService} from "../../services/permissions.service";

@Directive({
  standalone: true,
  selector: '[hasCrossGamePermission]'
})
export class HasCrossGamePermissionDirective {
  @Input()
  set hasCrossGamePermission(val: PermissionTypes) {
    const hasAccess = this.permissionService.userHasCrossGamePermission(val);

    if (hasAccess) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionsService
  ) {
  }

}
