import {Component, OnInit} from '@angular/core';
import {AsyncPipe} from "@angular/common";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {MatIcon} from "@angular/material/icon";
import {MatListItem, MatListItemIcon, MatListItemLine, MatNavList} from "@angular/material/list";
import {RouterLink} from "@angular/router";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {AppConfigService} from "../../../services/app-config.service";
import {SupportRequestService} from "../../../services/support-request.service";
import {MatBadge} from "@angular/material/badge";
import {
  UserHasCausableAdminAccessDirective
} from "../../../directives/permissions/user-has-causable-admin-access.directive";
import {ActiveUserService} from "../../../services/active-user.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MatExpansionModule} from "@angular/material/expansion";

@Component({
  selector: 'app-cross-game-admin-side-nav',
  standalone: true,
  imports: [
    AsyncPipe,
    HasPermissionDirective,
    MatIcon,
    MatListItem,
    MatListItemIcon,
    MatListItemLine,
    MatNavList,
    RouterLink,
    MatBadge,
    UserHasCausableAdminAccessDirective,
    MatExpansionModule
  ],
  templateUrl: './cross-game-admin-side-nav.component.html',
  styleUrl: './cross-game-admin-side-nav.component.scss'
})
export class CrossGameAdminSideNav implements OnInit {
  public permissionTypes = PermissionTypes;
  public currentEnvironment: string = "";
  public hangfireDashboardLink: string = "";
  public assignedSupportRequestsCount$ = this.supportRequestService.mySupportRequests$;
  helpDeskEnabled: boolean = false;

  constructor(private appConfigService: AppConfigService,
              private errorHandlingService: ErrorHandlingService,
              private activeUserService: ActiveUserService,
              private supportRequestService: SupportRequestService) {
  }

  ngOnInit(): void {
    this.appConfigService.appConfig$.subscribe(config => {
      this.currentEnvironment = config.currentEnvironment;
      this.hangfireDashboardLink = config.commonUrl + '/hangfire';
    });

    this.supportRequestService.fetchMyAssignedRequests(this.activeUserService.activeUser().Id!, null).subscribe({
      error: () => {
        this.errorHandlingService.displayPageLevelErrorMessage('Failure to retrieve your support requests. Please refresh or contact a system admin')
      }
    })
  }

}
