<c-container class="mt-5">
  @if (currentUser) {
    <button mat-raised-button class="margin-bottom-med margin-top-med" (click)="onBackToUsersClick()"
            data-testid="back-to-users-button">
      <mat-icon>arrow_back_ios</mat-icon>
      Back to Users
    </button>
    <h1 data-testid="current-user-name-value">{{ currentUser.FirstName }} {{ currentUser.LastName }}</h1>
    <c-row class="mt-4">
      <c-col md="3" class="mb-3">
        <div cListGroup>
          <button cListGroupItem
                  data-testid="general-info-tab"
                  (click)="screenState = screenStateEnum.ViewDetails"
                  [active]="screenState === screenStateEnum.ViewDetails">
            <div>
              <h5 class="mb-1">View User Details</h5>
              <p>view general details about this user</p>
            </div>
          </button>

          <button cListGroupItem
                  data-testid="causable-suspensions-tab"
                  (click)="screenState = screenStateEnum.ViewRoles"
                  [active]="screenState === screenStateEnum.ViewRoles">
            <div>
              <h5 class="mb-1">View User Roles</h5>
              <p>view the users roles for the games they have access to</p>
            </div>
          </button>
        </div>
      </c-col>
      <c-col md="9">
        @switch (screenState) {
          @case (screenStateEnum.ViewDetails) {
            <mat-card>
              <mat-card-header>
                <mat-card-title>View User Details</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div>
                  <div class="detail-label">Admin Since</div>
                  <div class="detail-value"
                       data-testid="current-user-created-on">{{ currentUser.CreatedOn | date: 'MMM d, y' }}
                  </div>
                </div>

                <div>
                  <div class="detail-label">Email Address</div>
                  <div class="detail-value" data-testid="current-user-email-value">{{ currentUser.Email }}</div>
                </div>

                <div>
                  <div class="detail-label">Phone Number</div>
                  <div class="detail-value" data-testid="current-user-phone-number">{{ currentUser.Phone }}</div>
                </div>

                <div>
                  <div class="detail-label">Admin Id</div>
                  <div class="detail-value" data-testid="current-user-id-value">{{ currentUser.Id }}</div>
                </div>

                <div>
                  <div class="detail-label">Verification Code</div>
                  <div class="detail-value"
                       data-testid="current-user-verification-value">{{ currentUser.VerificationCode }}
                  </div>
                </div>

                <div>
                  <div class="detail-label">Status</div>
                  <div class="detail-value" data-testid="current-user-status">
                    <mat-icon
                      [ngClass]="currentUser.Active ? 'green-text' : 'red-text'">{{ currentUser.Active ? 'check' : 'do_not_disturb' }}
                    </mat-icon>
                    <span
                      [ngClass]="currentUser.Active ? 'green-text' : 'red-text'">{{ currentUser.Active ? 'Active' : 'Deactivated' }}</span>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          }
          @case (screenStateEnum.ViewRoles) {
            @if (enableChoosingGame) {
              <app-choose-your-game
                [message]="'Choose a game to assign roles to'"
                (gameChosenEvent)="onGameChange($event)"></app-choose-your-game>
            }

            @if (chosenGame) {
              @if (existingRolesForGame) {
                <mat-card class="mb-4">
                  <mat-card-header>
                    <mat-card-title>Current User Role(s)</mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    @if (existingRolesForGame.length > 0) {
                      <table mat-table [dataSource]="existingRolesForGame" data-testid="user-roles-table">
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef>Name</th>
                          <td mat-cell *matCellDef="let element"> {{ element.Name }}</td>
                        </ng-container>

                        <ng-container matColumnDef="description">
                          <th mat-header-cell *matHeaderCellDef>Description</th>
                          <td mat-cell *matCellDef="let element"> {{ element.Description }}</td>
                        </ng-container>

                        <ng-container matColumnDef="deactivate">
                          <th mat-header-cell *matHeaderCellDef></th>
                          <td mat-cell *matCellDef="let element">
                            <button
                              data-testid="remove-role-button"
                              mat-icon-button
                              matTooltip="remove this role"
                              (click)="onRemoveUserRoleClick(element)">
                              <mat-icon class="red-text">delete</mat-icon>
                            </button>
                          </td>

                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr data-testid="user-roles-row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>
                    } @else {
                      Looks like this user does not have any active roles in this game.
                    }

                  </mat-card-content>
                </mat-card>
              }
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Available Roles</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  @if (uiState == uiStateForTemplate.ShowRequestProcessing) {
                    <mat-spinner class="center-margin"></mat-spinner>
                  } @else {
                    <mat-selection-list (selectionChange)="onSelectionChange($event)">
                      @for (role of possibleRolesForChosenGame; track role) {
                        <mat-list-option [value]="role" [selected]="isRoleSelected(role)"
                                         [disabled]="!userHasEditAccess" [matTooltip]="role.Description">
                          <span class="bold-text text-underline">{{ role.Name }}</span> - {{ role.Description }}
                        </mat-list-option>
                      }
                    </mat-selection-list>
                  }
                  <mat-card-actions>
                    <button mat-raised-button color="primary" (click)="onUpdateUserRolesClick()">Save Changes</button>
                  </mat-card-actions>
                </mat-card-content>
              </mat-card>
            }
          }
        }
      </c-col>
    </c-row>
  }
</c-container>
