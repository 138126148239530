<c-container class="mt-5">
  <h2>Edit Terms Of Service</h2>

  <h3>Game Rules Input</h3>

  <div class="rules-section margin-bottom-large">
    <form [formGroup]="gameRulesForm">
      <mat-form-field class="full-width">
        <mat-hint>game rules are written in markdown. Input your markdown here, and the preview below will show the
          final product
        </mat-hint>
        <textarea matInput formControlName="gameRules" rows="20" data-testid="game-rules-input"></textarea>
        @if (gameRulesControl.errors) {
          <mat-error>Game rules are required</mat-error>
        }
      </mat-form-field>
    </form>
  </div>
  <hr>
  <h3>Game Rules Preview</h3>

  <div class="markdown-preview rules-section padding-med">
    <markdown [data]=formatMarkdown(gameRulesControl.value!)></markdown>
  </div>

  <br>
  <button mat-flat-button
          color="primary"
          (click)="submit()"
          [disabled]="uiState === uiStateEnumForTemplate.ShowLoading"
          data-testid="submit-game-rules-form-button">Saves Changes
  </button>
</c-container>
