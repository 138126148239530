@if(activeGame) {
  <c-container class="mt-3">
    <c-row>
      <c-col>
        <h2 data-testid="game-name">{{ activeGame.Name }}
          <small data-testid="game-id">({{ activeGame.Id }})</small>
        </h2>
      </c-col>
    </c-row>

    <c-row class="mb-3">
      <c-col md="3" class="mb-3">
        <div cListGroup>
          <button cListGroupItem
                  data-testid="general-settings-tab"
                  (click)="screenState = screenStateEnum.GeneralSettings"
                  [active]="screenState === screenStateEnum.GeneralSettings">
          <div>
            <h5 class="mb-1">General Settings</h5>
            <p>View General Settings for the Game</p>
          </div>
          </button>

          <button cListGroupItem
                  data-testid="asset-settings-tab"
                  (click)="screenState = screenStateEnum.AssetSettings"
                  [active]="screenState === screenStateEnum.AssetSettings">
            <div>
              <h5 class="mb-1">Asset Settings</h5>
              <p>View Asset Settings for the Game</p>
            </div>
          </button>

          <button cListGroupItem
                  data-testid="draw-settings-tab"
                  (click)="screenState = screenStateEnum.DrawSettings"
                  [active]="screenState === screenStateEnum.DrawSettings">
            <div>
              <h5 class="mb-1">Draw Settings</h5>
              <p>View Draw Settings for the Game</p>
            </div>
          </button>

          <button cListGroupItem
                  data-testid="lottery-license-tab"
                  (click)="screenState = screenStateEnum.LotteryLicense"
                  [active]="screenState === screenStateEnum.LotteryLicense">
            <div>
              <h5 class="mb-1">Lottery License Settings</h5>
              <p>View Lottery License Settings for the Game</p>
            </div>
          </button>

          <button cListGroupItem
                  data-testid="testimonials-tab"
                  (click)="screenState = screenStateEnum.Testimonials"
                  [active]="screenState === screenStateEnum.Testimonials">
            <div>
              <h5 class="mb-1">Testimonials</h5>
              <p>View and Manage Testimonials for the Game</p>
            </div>
          </button>
        </div>
      </c-col>

      <c-col>
        @switch (screenState) {
          @case (screenStateEnum.GeneralSettings) {
            <mat-card>
              <mat-card-header>
                <mat-card-title>General Settings</mat-card-title>
              </mat-card-header>

              <mat-card-content>
                <div class="margin-top-med">
                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">Game Name</div>
                    <div class="flex-66" data-testid="game-settings-name">{{ activeGame.Name }}</div>
                  </div>

                  <div class="flex-row justify-space-between align-center custom-border-bottom" *hasPermission="permissionTypes.EditGameTermsOfService">
                    <div class="flex-33 bold-text">Game Rules</div>
                    <div class="flex-66">
                      <button mat-stroked-button data-testid="edit-tos-button" (click)="editGameRules()">Edit</button>
                    </div>
                  </div>

                  <div class="flex-row justify-space-between align-center custom-border-bottom" *hasPermission="permissionTypes.EditGameHowToPlay">
                    <div class="flex-33 bold-text">How To Play Text</div>
                    <div class="flex-66">
                      <button mat-stroked-button data-testid="edit-how-to-play-button" (click)="editHowToPlay()">Edit</button>
                    </div>
                  </div>

                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">Game Subdomain</div>
                    <div class="flex-66" data-testid="game-settings-subdomain">{{ activeGame.Subdomain }}</div>
                  </div>

                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">Game Type</div>
                    <div class="flex-66" data-testid="game-settings-type">{{ activeGame.Type | gameType }}</div>
                  </div>

                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">Game Time Zone</div>
                    <div class="flex-66" data-testid="game-settings-timezone">{{ activeGame.Timezone }}</div>
                  </div>

                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">Date of First Game</div>
                    <div class="flex-66"
                         data-testid="game-settings-date-of-first-instance">{{ activeGame.DateOfFirstInstance | date: 'MMM d, y' }}
                    </div>
                  </div>
                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">Date of Final Game</div>
                    <div
                      class="flex-66"
                      data-testid="game-settings-date-of-final-instance">{{ activeGame.DateOfFinalInstance ? (activeGame.DateOfFinalInstance | date: 'MMM d, y') : '-----' }}
                    </div>
                  </div>

                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">Game Created On</div>
                    <div class="flex-66" data-testid="game-settings-created-on">{{ activeGame.CreatedOn | date: 'MMM d, y' }}
                    </div>
                  </div>

                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">State</div>
                    <div class="flex-66" data-testid="game-status">{{ activeGame.State | gameState}}</div>
                  </div>

                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">AutoPlay Enabled</div>
                    <div class="flex-66">
                      <mat-icon
                        data-testid="game-settings-autoplay-enabled"
                        [class]="activeGame.AutoplayEnabled ? 'green-text': 'red-text'">{{ activeGame.AutoplayEnabled ? 'check' : 'clear' }}
                        check
                      </mat-icon>
                    </div>
                  </div>

                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">Collect Player Address</div>
                    <div class="flex-66">
                      <mat-icon
                        data-testid="game-settings-collect-player-address"
                        [class]="activeGame.CollectPlayerAddress ? 'green-text': 'red-text'">{{ activeGame.CollectPlayerAddress ? 'check' : 'clear' }}
                        check
                      </mat-icon>
                    </div>
                  </div>

                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">Lock Players to Region</div>
                    <div class="flex-66">
                      <mat-icon
                        data-testid="game-settings-lock-player-region"
                        [class]="activeGame.RegionLockPlayerGeolocation ? 'green-text': 'red-text'">{{ activeGame.RegionLockPlayerGeolocation ? 'check' : 'clear' }}
                        check
                      </mat-icon>
                    </div>
                  </div>

                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">Lock Players to Postal Code</div>
                    <div class="flex-66">
                      <mat-icon
                        data-testid="game-settings-lock-postal-code"
                        [class]="activeGame.RegionLockPlayerPostalCode ? 'green-text': 'red-text'">{{ activeGame.RegionLockPlayerPostalCode ? 'check' : 'clear' }}
                        check
                      </mat-icon>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          }
          @case (screenStateEnum.AssetSettings) {
            <mat-card>
              <mat-card-header>
                <mat-card-title>Asset Settings</mat-card-title>
              </mat-card-header>

              <mat-card-content>
                <div class="margin-top-med">

                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">Login More Info Url</div>
                    <div class="flex-66" data-testid="game-settings-login-more-info">{{ activeGame.LoginMoreInfoUrl }}</div>
                  </div>

                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">Game Description</div>
                    <div class="flex-66">
                      <span data-testid="game-settings-description-text">{{ activeGame.GameDescription }}</span>
                      <mat-icon
                        [matTooltip]="'Click here to modify the game description'"
                        *hasPermission="permissionTypes.ModifyGameSettings"
                        class="edit-game-icon"
                        data-testid="edit-game-description-button"
                        (click)="openEditGameDescriptionDialog()">edit</mat-icon>
                    </div>
                  </div>

                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">Game More Information</div>
                    <div class="flex-66">
                      <button mat-stroked-button
                              data-testid="edit-game-more-information-button"
                              (click)="openEditGameMoreInformationDialog()">Edit</button>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          }
          @case (screenStateEnum.DrawSettings) {
            <mat-card>
              <mat-card-header>
                <mat-card-title>Draw Settings</mat-card-title>
              </mat-card-header>

              <mat-card-content>
                <div class="margin-top-med">

                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">Day of Draw</div>
                    <div class="flex-66"
                         data-testid="game-settings-day-of-draw">{{ activeGame.DrawDayOfWeek | drawDayOfWeek }}
                    </div>
                  </div>

                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">Time of Draw</div>
                    <div class="flex-66"
                         data-testid="game-settings-time-of-draw">{{ activeGame.DrawTimeMinutesFromMidnightLocal | minutesFromMidnightToHours }}
                    </div>
                  </div>

                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">Notifications Start Time</div>
                    <div
                      class="flex-66"
                      data-testid="game-settings-notifications-start-time">{{ activeGame.NotificationsStartMinutesFromMidnightLocal | minutesFromMidnightToHours }}
                    </div>
                  </div>

                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">Draw Frequency</div>
                    <div class="flex-66"
                         data-testid="game-settings-draw-frequency">{{ activeGame.InstanceIntervalType | drawInterval }}
                    </div>
                  </div>

                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">Max Tickets Per Draw</div>
                    <div class="flex-66" data-testid="game-settings-tickets-per-draw">{{ activeGame.MaxTicketsPerDraw }}</div>
                  </div>

                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">Max Dollar Amount Per Player</div>
                    <div class="flex-66"
                         data-testid="game-settings-max-per-player">{{ activeGame.MaxAmountPlayersCanSpendPerDraw | formatCurrency }}
                    </div>
                  </div>

                  <div class="flex-row justify-space-between align-center custom-border-bottom">
                    <div class="flex-33 bold-text">Game is Drawn Manually</div>
                    <div class="flex-66">
                      <mat-icon
                        data-testid="game-settings-drawn-manually"
                        [class]="activeGame.ManualDraw ? 'green-text': 'red-text'">{{ activeGame.ManualDraw ? 'check' : 'clear' }}
                        check
                      </mat-icon>
                    </div>
                  </div>
                  <div class="flex-row justify-space-between custom-border-bottom">
                    <div class="flex-33 bold-text">Payment Tiers</div>
                    <div class="flex-66" data-testid="game-settings-payment-tiers">
                      @for(tier of activeGame.PaymentTiers; track tier) {
                        <div data-testid="payment-tiers">
                          {{ tier.NumberOfTickets }} for {{ tier.Price | formatCurrency }}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          }
          @case (screenStateEnum.LotteryLicense) {
            <mat-card>
              <mat-card-header>
                <mat-card-title>Lottery License Settings</mat-card-title>
              </mat-card-header>

              <mat-card-content>
                <div class="margin-top-med">
                  <div class="flex-row justify-end">
                    <button mat-raised-button
                            data-testid="add-new-licence-number-button"
                            *hasPermission="permissionTypes.ModifyLotteryLicence"
                            color="primary"
                            (click)="openAddLicenceNumber()"
                            matTooltip="add new licence number"
                            matTooltipShowDelay="500"> Add
                      <mat-icon>add_circle</mat-icon>
                    </button>
                  </div>
                  <app-lottery-licences-table [gameSetter]="activeGame"></app-lottery-licences-table>
                </div>
              </mat-card-content>
            </mat-card>
          }
          @case (screenStateEnum.Testimonials) {
            <mat-card>
              <mat-card-header>
                <mat-card-title>Testimonials</mat-card-title>
              </mat-card-header>

              <mat-card-content>
                <div class="margin-top-med">
                  <div class="flex-row justify-end">
                    <button mat-raised-button
                            data-testid="add-testimonial-button"
                            *hasPermission="permissionTypes.ModifyTestimonials"
                            color="primary"
                            (click)="openAddTestimonial()"
                            matTooltip="add new testimonial"
                            matTooltipShowDelay="500">Add
                      <mat-icon>add_circle</mat-icon>
                    </button>
                  </div>
                  <app-testimonials-table [gameSetter]="activeGame"></app-testimonials-table>
                </div>
              </mat-card-content>
            </mat-card>
          }
        }
      </c-col>
    </c-row>
  </c-container>
}
