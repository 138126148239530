import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {OidcService} from '../services/oidc.service';
import {ActiveUserService} from "../services/active-user.service";
import {concatMap, map, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {GameService} from "../services/game.service";

@Injectable({
  providedIn: 'root'
})
export class UserIsAdminGuard implements CanActivate {
  constructor(private oidcService: OidcService,
              private activeUserService: ActiveUserService,
              private gameService: GameService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const is_authenticated = this.oidcService.isAuthenticated();
    if (!is_authenticated) {
      return this.router.navigateByUrl('auth/login');
    }

    const adminId = this.oidcService.getAdminIdFromClaims();
    if (adminId) {
      return this.activeUserService.retrieveActiveUser(adminId)
        .pipe(
          concatMap((adminP) => {
            // admin is found if admin is truthy (not null in this context)
            let adminFound = !!adminP;

            if(!adminFound) {
              return of(false);
            }
            // if the admin is found, go get their games
            return this.gameService.getGamesForUser(adminId).pipe(map(() => true));
          }),
          catchError(() => {
            return this.router.navigateByUrl('not-admin');
          }))
    }

    this.router.navigateByUrl('not-admin');
    return false;
  } 
}
