<mat-card>
  <mat-card-header>
    <mat-card-title>Charity Draw Reporting</mat-card-title>
    <mat-card-subtitle>Choose a draw from the table to see a charity breakdown for that draw
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    @if (uiState == uiStateEnum.ShowLoading) {
      <mat-spinner class="center-margin"></mat-spinner>
    } @else {
      @if (!chosenGameInstance && charity) {
        <app-choose-game-instance
          (chosenGameInstanceEvent)="updateChosenGameInstance($event)" [gameIdSetter]="charity.GameId"></app-choose-game-instance>
      } @else if (charityGameInstanceReport) {
        <div class="text-end">
          <button mat-raised-button (click)="onGenerateDateRangeReport()" class="margin-top-med"
                  data-testid="charity-date-range-report-generate-button">Choose another draw
          </button>
        </div>
        <div class="margin-top-large">
          <div>
            <div class="report-label">Number of Supporters</div>
            <div
              class="report-value"
              data-testid="charity-date-range-total-supporters">{{ charityGameInstanceReport.NumberOfSupporters }}
            </div>
          </div>

          <div>
            <div class="report-label">Total autoplay sales</div>
            <div
              class="report-value"
              data-testid="charity-date-range-autoplay-sales">{{ charityGameInstanceReport.AutoplaySales | formatCurrency }}
            </div>
          </div>

          <div>
            <div class="report-label">Total manual sales</div>
            <div
              class="report-value"
              data-testid="charity-date-range-manual-sales">{{ charityGameInstanceReport.ManualSales | formatCurrency }}
            </div>
          </div>
          <div>
            <div class="report-label">Total sales</div>
            <div
              class="report-value"
              data-testid="charity-date-range-total-sales">{{ charityGameInstanceReport.TotalAmountRaised | formatCurrency }}
            </div>
          </div>

          <hr>

          <div class="report-label">Purchase Size Breakdown</div>
          <div>
            <div class="report-label">Purchase amount</div>
            <div
              class="report-value"
              data-testid="charity-date-range-total-sales">Number of Purchases
            </div>
          </div>

          @for (breakdown of charityGameInstanceReport.PurchaseSizeBreakdown; track breakdown) {
            <div>
              <div class="report-label">{{ breakdown.PurchaseAmount | formatCurrency }}</div>
              <div
                class="report-value"
                data-testid="charity-date-range-total-sales">{{ breakdown.NumberOfPurchases }}
              </div>
            </div>
          }
        </div>
      }
    }
  </mat-card-content>
</mat-card>
