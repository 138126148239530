import {Component, Inject, inject} from '@angular/core';
import {TestimonialsService} from "../../../services/testimonials.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IApiErrorResponse} from "../../../interfaces/error/IApiErrorResponse";
import {DialogBaseComponent} from "../../dialogs/dialog-base/dialog-base.component";
import {
  FormCheckComponent,
  FormCheckInputDirective,
  FormCheckLabelDirective,
  FormControlDirective,
  FormDirective,
  FormFeedbackComponent,
  FormLabelDirective
} from "@coreui/angular";
import {MatButton} from "@angular/material/button";
import {AgGridAngular} from "ag-grid-angular";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";
import {ActiveUserService} from "../../../services/active-user.service";

@Component({
  selector: 'app-add-testimonial',
  standalone: true,
  imports: [
    DialogBaseComponent,
    FormCheckComponent,
    FormCheckInputDirective,
    FormCheckLabelDirective,
    FormControlDirective,
    FormDirective,
    FormFeedbackComponent,
    FormLabelDirective,
    FormsModule,
    MatButton,
    ReactiveFormsModule,
    AgGridAngular
  ],
  templateUrl: './add-testimonial.component.html',
  styleUrl: './add-testimonial.component.css'
})
export class AddTestimonialComponent {
  private testimonialsService: TestimonialsService = inject(TestimonialsService);
  private activeUserService: ActiveUserService = inject(ActiveUserService);
  private snackbarService: SnackbarService = inject(SnackbarService);

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;

  testimonialContentControl = new FormControl<string | null>("", [
    Validators.required,
    Validators.minLength(10),
    Validators.maxLength(1100)
  ]);
  userNameControl = new FormControl<string | null>("", Validators.required);
  userPositionControl = new FormControl<string | null>("", Validators.required);
  activeControl = new FormControl<boolean>(false);

  testimonialForm = new FormGroup({
    content: this.testimonialContentControl,
    userPosition: this.userPositionControl,
    userName: this.userNameControl,
    active: this.activeControl
  });

  constructor(private matDialogRef: MatDialogRef<AddTestimonialComponent>,
              @Inject(MAT_DIALOG_DATA) public game: IGameQueryResult) {
  }

  addTestimonial() {
    if (!this.testimonialForm.valid) {
      this.testimonialForm.markAllAsTouched();
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;

    this.testimonialsService.addTestimonial({
      gameId: this.game.Id,
      active: this.activeControl.value!,
      content: this.testimonialContentControl.value!,
      givenByUserName: this.userNameControl.value!,
      givenByUserPosition: this.userPositionControl.value!,
      adminId: this.activeUserService.activeUser().Id
    }).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
        this.snackbarService.openSuccessfulSnackBar('Successfully added testimonial');
        this.matDialogRef.close();
      }, error: (res: IApiErrorResponse) => {
        this.uiState = UIStateEnum.ShowData;
        if (res.Error?.ClientErrorMessages?.length > 0) {
          this.snackbarService.openErrorSnackBar(res.Error.ClientErrorMessages.toString());
        } else {
          this.snackbarService.openErrorSnackBar('There was an issue adding your testimonial');
        }
      }
    })
  }
}
