import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {UsersService} from "../../../services/users.service";
import {IAdminQueryResult} from "../../../interfaces/IAdminQueryResult";
import {Router} from "@angular/router";
import {SnackbarService} from "../../../services/snackbar.service";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {MatDivider} from "@angular/material/divider";

@Component({
  selector: 'app-game-admin-settings',
  templateUrl: './game-admin-settings.component.html',
  standalone: true,
  imports: [
    HasPermissionDirective,
    MatMenuTrigger,
    MatIconButton,
    MatIcon,
    MatTooltip,
    MatMenu,
    MatMenuItem,
    MatDivider
  ],
  styleUrls: ['./game-admin-settings.component.scss']
})
export class GameAdminSettingsComponent implements ICellRendererAngularComp {

  public adminUser!: IAdminQueryResult;
  public permissionTypes = PermissionTypes;
  public gameId: string = '';
  public isCrossGame: boolean = false;

  constructor(
    private snackbarService: SnackbarService,
    private router: Router,
    private userService: UsersService) {
  }

  agInit(params: ICellRendererParams & {
    gameId: string,
    isCrossGame: boolean
  }): void {
    this.adminUser = params.data;
    this.gameId = params.gameId;
    this.isCrossGame = params.isCrossGame;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  onDeactivateUserClick() {
    this.userService.onDeactivateUserClick(this.adminUser, this.gameId);
  }

  onViewDetailsClick() {
    if (this.isCrossGame) {
      this.router.navigateByUrl(`cross-game-user-details/${this.adminUser.Id}`);
    } else {
      this.router.navigateByUrl(`${this.gameId}/game-admin-user-details/${this.adminUser.Id}`);
    }
  }

  onReactiveUserClick() {
    this.userService.onReactivateUserClick(this.adminUser, this.gameId);
  }

  onResendInviteClick() {
    this.userService.resendAdminInvite({
      targetAdminId: this.adminUser.Id,
      gameId: this.gameId,
      roles: this.adminUser.Roles.find(roleByGame => roleByGame.GameId == this.gameId)?.GameRoles ?? [],
      redirectUrl: `${window.location.origin}/login`
    }).subscribe({
      next: () => this.snackbarService.openSuccessfulSnackBar('Successfully resent email'),
      error: () => {
        this.snackbarService.openErrorSnackBar('Looks like there was an error resending the email. Please try again or contact a system admin');
      }
    });
  }
}
