import { Component } from '@angular/core';
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {MatDivider} from "@angular/material/divider";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatTooltip} from "@angular/material/tooltip";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {IAdminQueryResult} from "../../../interfaces/IAdminQueryResult";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {Router} from "@angular/router";
import {UsersService} from "../../../services/users.service";
import {ICellRendererParams} from "ag-grid-community";
import {SnackbarService} from "../../../services/snackbar.service";
import {ICharityAdmin} from "../../../interfaces/ICharityAdmin";

@Component({
  selector: 'app-charity-admin-settings',
  standalone: true,
  imports: [
    HasPermissionDirective,
    MatDivider,
    MatIcon,
    MatIconButton,
    MatMenu,
    MatMenuItem,
    MatTooltip,
    MatMenuTrigger
  ],
  templateUrl: './charity-admin-settings.component.html',
  styleUrl: './charity-admin-settings.component.scss'
})
export class CharityAdminSettingsComponent  implements ICellRendererAngularComp {

  public adminUser!: ICharityAdmin;
  public permissionTypes = PermissionTypes;
  public gameId: string = '';
  public isCrossGame: boolean = false;

  constructor(private router: Router,
              private snackbarService: SnackbarService,
              private userService: UsersService) {
  }

  agInit(params: ICellRendererParams & {
    gameId: string,
    isCrossGame: boolean
  }): void {
    this.adminUser = params.data;
    this.gameId = params.gameId;
    this.isCrossGame = params.isCrossGame;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  onDeactivateUserClick() {
    this.userService.onDeactivateUserClick(this.adminUser, this.gameId);
  }

  onViewDetailsClick() {
    if (this.isCrossGame) {
      this.router.navigateByUrl(`cross-game-user-details/${this.adminUser.Id}`);
    } else {
      this.router.navigateByUrl(`${this.gameId}/game-admin-user-details/${this.adminUser.Id}`);
    }
  }

  onReactiveUserClick() {
    this.userService.onReactivateUserClick(this.adminUser, this.gameId);
  }

}
