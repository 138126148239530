<form [formGroup]="newGameAssetsSettingsForm" (change)="onFormChange()">

  <small>*The Following images are for display only. These images will need to be uploaded into AZURE in order for the game to display them.</small>

  <div class="margin-top-med">
    <div class="text-underline">QR Code Image:</div>
    @if (qrCodeImageState === CreateImageState.ViewSavedImage) {
      <img [src]="qrCodeImageUrl" alt="Uploaded Image" style="max-width: 300px;"/>
      <div>
        <button color="primary" mat-raised-button (click)="editQRCode()" data-testid="confirm-game">
          Change QR Code
        </button>
      </div>

    } @else if (qrCodeImageState === CreateImageState.NoImageSaved) {
      <input type="file"
             accept=".png"
             class="file-input display-none"
             (change)="onQrCodeFileSelected($event)" #qrfileUpload>
      @if (qrCodeImage) {
        <div class="margin-top-med margin-bottom-med">
          <img [src]="qrCodeImageUrl" alt="Uploaded Image" style="max-width: 300px;"/>
        </div>
      }
      <div class="margin-top-med">
        <button color="primary" mat-raised-button
                (click)="qrfileUpload.click()">
          <mat-icon>attach_file</mat-icon>
          Search images
        </button>

        @if (qrCodeImage) {
          <button class="margin-left-small" color="primary" mat-raised-button (click)="saveQRCode()"
                  data-testid="save-qr-code">
            <mat-icon>save</mat-icon>
            Save QR Code
          </button>
        }
      </div>

    }
  </div>

  <div class="margin-top-med">
    <div class="text-underline">Game Logo:</div>
    @if (gameLogoImageState === CreateImageState.ViewSavedImage) {
      <img [src]="gameLogoUrl" alt="Uploaded Image" style="max-width: 300px;"/>
      <div>
        <button color="primary" mat-raised-button (click)="editGameLogo()" data-testid="confirm-game">
          Change Game Logo
        </button>
      </div>
    } @else if (gameLogoImageState === CreateImageState.NoImageSaved) {
      <input type="file"
             accept=".svg"
             class="file-input display-none"
             (change)="onGameLogoFileSelected($event)" #gameLogoUpload>
      @if (gameLogoImage) {
        <div class="margin-top-med margin-bottom-med">
          <img [src]="gameLogoUrl" alt="Uploaded Image" style="max-width: 300px;"/>
        </div>
      }
      <div class="margin-top-med">
        <button color="primary" mat-raised-button
                (click)="gameLogoUpload.click()">
          <mat-icon>attach_file</mat-icon>
          Search images
        </button>
        @if (gameLogoImage) {
          <button color="primary" class="margin-left-small" mat-raised-button (click)="saveGameLogo()"
                  data-testid="save-game-logo">
            Save Game Logo
          </button>
        }
      </div>

    }
  </div>

  @if (gameLogoUrl) {
    <mat-toolbar class="sample-header margin-top-large" [style.background-color]="toolbarColor">
      <button mat-icon-button
              class="margin-right-med"
              aria-label="icon for opening the side menu on smaller screens">
        <mat-icon [style.color]="iconColor">menu</mat-icon>
      </button>
      <img [src]="gameLogoUrl" alt="Uploaded Image" style="max-width: 300px;"/>
    </mat-toolbar>

    <mat-form-field appearance="outline" class="full-width margin-top-large">
      <mat-label>Game Theme Type</mat-label>
      <mat-select formControlName="gameThemeTypeId" placeholder="choose how often the draw will happen"
                  (valueChange)="onThemeTypeChange($event)">
        <mat-option [value]="GameThemeType.Light">Light</mat-option>
        <mat-option [value]="GameThemeType.Dark">Dark</mat-option>
      </mat-select>
    </mat-form-field>
  }

  <div>
    <mat-label>Primary game Color:</mat-label>
    <ngx-colors ngx-colors-trigger class="color-picker"
                formControlName="primaryGameColor"
                data-testid="primary-game-color-button"
                [acceptLabel]="'CONFIRM'"></ngx-colors>
    {{ newGameAssetsSettingsForm.controls.primaryGameColor.value }}
  </div>

</form>
