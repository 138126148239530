import {Component} from '@angular/core';
import {SidebarModule} from "@coreui/angular";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {
  UserHasCausableAdminAccessDirective
} from "../../../directives/permissions/user-has-causable-admin-access.directive";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {RouterLink} from "@angular/router";
import {AsyncPipe} from "@angular/common";
import {MatListModule} from "@angular/material/list";
import {MatBadge} from "@angular/material/badge";
import {Observable, tap} from "rxjs";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {GameService} from "../../../services/game.service";
import {SupportRequestService} from "../../../services/support-request.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {ActiveUserService} from "../../../services/active-user.service";
import {MatExpansionModule} from "@angular/material/expansion";

@Component({
  selector: 'app-game-admin-side-nav',
  standalone: true,
  imports: [
    MatListModule,
    AsyncPipe,
    RouterLink,
    MatTooltip,
    MatIcon,
    HasPermissionDirective,
    UserHasCausableAdminAccessDirective,
    MatSlideToggle,
    MatBadge,
    SidebarModule,
    MatExpansionModule
  ],
  templateUrl: './game-admin-side-nav.component.html',
  styleUrl: './game-admin-side-nav.component.scss'
})
export class GameAdminSideNavComponent {
  public supportRequestEnabled = false;

  public activeGameId$ = this.gameService.selectActiveGameId().pipe(tap((gameIdP) => {
    if (gameIdP) {
      if (this.supportRequestEnabled) {
        this.supportRequestService.fetchMyAssignedRequests(this.activeUserService.activeUser().Id!, this.gameService.activeGame().Id).subscribe({
          error: () => {
            this.errorHandlingService.displayPageLevelErrorMessage('Failure to retrieve your support requests. Please refresh or contact a system admin')
          }
        })
      }
    }

  }));
  public gameDrawnManually$ = this.gameService.selectActiveGameDrawnManually();
  public availableGamesToUser$: Observable<IGameQueryResult[]> = this.gameService.selectAllAvailableGames();
  sideNavShouldOpen: boolean = true;
  helpDeskEnabled: boolean = false;
  public permissionTypes = PermissionTypes;
  public assignedSupportRequestsCount$ = this.supportRequestService.mySupportRequests$;

  constructor(private gameService: GameService,
              private errorHandlingService: ErrorHandlingService,
              private activeUserService: ActiveUserService,
              private supportRequestService: SupportRequestService) {
  }

}
