import {Component} from "@angular/core";
import {FormControl} from "@angular/forms";
import {GridApi, GridReadyEvent} from "ag-grid-community";
import {AuditingService} from "../../services/auditing.service";
import {AuditEventTables} from "../../table-definitions/audit-event-tables";
import {IPagedResponse} from "../../interfaces/audit/IPagedResponse";
import {DateService} from "../../services/date.service";
import {IAuditEvent} from "../../interfaces/audit/IAuditEvent";
import {IAuditEventType} from "../../interfaces/audit/IAuditEventTypes";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {MatDialog} from "@angular/material/dialog";
import {IPaginationFilter} from "../../interfaces/IPaginationFilter";
import {UIStateEnum} from "../../enum/UIStateEnum";

@Component({
  selector: 'audit-table-base',
  template: 'base-template'
})
export abstract class AuditTablesBase {

  protected eventTypeFormControl: FormControl = new FormControl('');
  protected fromDateFormControl: FormControl = new FormControl(this.dateService.twoWeeksAgo());
  protected toDateFormControl: FormControl = new FormControl(this.dateService.tomorrow());
  protected genericContentSearchFormControl: FormControl = new FormControl('');
  protected isSuccessControl: FormControl<boolean | null> = new FormControl(null);
  protected fromNumber: number = 0;
  protected toNumber: number = 0;
  protected totalRecords: number = 0;
  protected pageNumber: number = 0;
  protected totalPages: number = 0;
  public pagedAuditResponse: IPagedResponse | null = null;
  public gridApi!: GridApi;
  public auditEventTypes: IAuditEventType[] = this.auditingService.getAuditEventTypes();
  protected defaultFilter: IPaginationFilter = {
    fromDate: this.dateService.twoWeeksAgo(),
    toDate: this.dateService.tomorrow(),
    eventTypeValue: null,
    pageSize: 50,
    pageNumber: 1,
    genericContentSearch: "",
    isSuccess: null
  };

  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public uiStateForTemplate = UIStateEnum;

  protected abstract getPaginatedResponse(pageNumberP: number): void;

  protected constructor(protected auditingService: AuditingService,
                        protected dateService: DateService,
                        protected matDialog: MatDialog,
                        protected errorHandlingService: ErrorHandlingService,
                        protected auditEventTables: AuditEventTables) {

  }

  public onGridReady(params: GridReadyEvent<IAuditEvent>) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit({
      defaultMinWidth: 90,
    });

    this.updatePaginator();
  }

  public onFormSubmit() {
    this.getPaginatedResponse(1);
  }

  protected onResetClick() {
    this.fromDateFormControl.setValue(this.dateService.twoWeeksAgo());
    this.toDateFormControl.setValue(this.dateService.tomorrow());
    this.eventTypeFormControl.setValue('');
    this.isSuccessControl.setValue(null);
    this.genericContentSearchFormControl.setValue('');
  }

  protected updatePaginator() {
    this.pageNumber = this.pagedAuditResponse!.PageNumber;
    this.totalPages = this.pagedAuditResponse!.TotalPages;
    this.totalRecords = this.pagedAuditResponse!.TotalRecords;
    let toNumber = this.pageNumber * this.pagedAuditResponse!.PageSize;
    if (toNumber > this.totalRecords) {
      this.toNumber = this.totalRecords;
      this.fromNumber = (toNumber - this.pagedAuditResponse!.PageSize) + 1;
    } else {
      this.toNumber = toNumber;
      this.fromNumber = (this.toNumber - this.pagedAuditResponse!.PageSize) + 1;
    }
  }

  onBtFirst() {
    if (this.pageNumber === 1) {
      return;
    }

    this.getPaginatedResponse(1);
  }

  onBtLast() {
    if ((this.pageNumber + 1) > this.totalPages) {
      return;
    }

    this.getPaginatedResponse(this.pagedAuditResponse?.TotalPages!);
  }

  onBtNext() {
    if ((this.pageNumber + 1) > this.totalPages) {
      return;
    }

    this.getPaginatedResponse(this.pageNumber + 1);
  }

  onBtPrevious() {
    if (this.pageNumber === 1) {
      return;
    }

    this.getPaginatedResponse(this.pageNumber - 1);
  }

}
