import {Component, Inject} from '@angular/core';
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {NgIf} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {GameService} from "../../../services/game.service";
import {ActiveUserService} from "../../../services/active-user.service";
import {IEditGameDescription} from "../../../interfaces/IEditGameDescription";
import {SnackbarService} from "../../../services/snackbar.service";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";

@Component({
  selector: 'app-edit-game-description',
  standalone: true,
  imports: [
    DialogBaseComponent,
    MatFormField,
    MatInput,
    MatLabel,
    MatError,
    ReactiveFormsModule,
    NgIf,
    MatButton
  ],
  templateUrl: './edit-game-description.component.html',
  styleUrl: './edit-game-description.component.css'
})
export class EditGameDescriptionComponent {

  public uiState = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;

  public descriptionControl = new FormControl<string | null>(null, Validators.required);

  constructor(private dialogRef: MatDialogRef<EditGameDescriptionComponent>,
              private gameService: GameService,
              private activeUserService: ActiveUserService,
              @Inject(MAT_DIALOG_DATA) public game: IGameQueryResult,
              private snackBarService: SnackbarService) {
    this.descriptionControl.setValue(game.GameDescription);
  }

  submit() {
    if (this.descriptionControl.errors) {
      this.descriptionControl.markAsTouched();
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;

    let command: IEditGameDescription = {
      GameId: this.game.Id,
      AdminId: this.activeUserService.activeUser().Id,
      Description: this.descriptionControl.value!,
    }

    this.gameService.updateGameDescription(command).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
        this.snackBarService.openSuccessfulSnackBar("Successfully updated game description");
        this.dialogRef.close(true);
      },
      error: err => {
        this.uiState = UIStateEnum.ShowData;
        this.snackBarService.openErrorSnackBar("Failed to update game description");
      }
    })
  }
}
