<app-dialog-base [showLoadingSpinner]="uiState == uiStateForTemplate.ShowLoading">
  <span dialog-title>Create Azure Resources For Game</span>

  <ng-container dialog-content>

    @if (creationCompleted) {

      <p>
        All Azure Resources have been created successfully!
      </p>

    }
    @else {

      <p>This will attempt to create all the required resources for a Game in Azure.
         It will uses resources that already exist and create resources that don't.
         Warning this will overwrite the CNAME record for a game so if this was manually modified it will change the value.
         The creation of the Azure Resources will take a few minutes, do not refresh this page.</p>

    }
  </ng-container>
  <ng-container actions>
    @if (!creationCompleted) {
      <button mat-button
              [disabled]="uiState === uiStateForTemplate.ShowLoading"
              class="primary-button"
              (click)="onCreateAzureResourcesClick()"
              data-testid="create-azure-resources-button">Create Azure Resources
      </button>
    }
  </ng-container>
</app-dialog-base>
