import {inject, Injectable} from "@angular/core";
import {APIService} from "./api.service";
import {concatMap, map, Observable} from "rxjs";
import {IAdminNotificationPreferences} from "../interfaces/notifications/IAdminNotificationPreferences";
import {INotificationType} from "../interfaces/notifications/INotificationType";
import {IUpdateAdminNotificationPreferences} from "../interfaces/notifications/IUpdateAdminNotificationPreferences";

@Injectable({
  providedIn: "root"
})
export class NotificationsService {

  public apiService: APIService = inject(APIService);

  public fetchAdminNotificationPreferences(adminIdP: string, gameIdP: string): Observable<IAdminNotificationPreferences[]> {
    return this.apiService.MakeGetRequest<IAdminNotificationPreferences[]>(`notification/admin-preferences/${adminIdP}/${gameIdP}`);
  }

  public fetchNotificationTypes(userIdP: string): Observable<INotificationType[]> {
    return this.apiService.MakeGetRequest<INotificationType[]>(`notification/notification-types/${userIdP}`);
  }

  public updateUserPreferences(preferencesP: IUpdateAdminNotificationPreferences): Observable<IAdminNotificationPreferences[]> {
    return this.apiService.MakePutRequest<IAdminNotificationPreferences[]>(`notification/preferences`, preferencesP).pipe(concatMap(() => {
      return this.fetchAdminNotificationPreferences(preferencesP.AdminId, preferencesP.GameId)
    }));
  }
}
