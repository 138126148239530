<c-container class="mt-5">
  <h2>Notification Preferences</h2>

  @if (isCrossGame) {
    <app-choose-your-game [message]="'Select a game to view your preferences'"
                          (gameChosenEvent)="onGameChosen($event)"></app-choose-your-game>
  }

  @if (game) {
    @if (uiState != uiStateForTemplate.ShowLoading) {
      <mat-selection-list (selectionChange)="onSelectionChange($event)">
        @for (notificationType of potentialNotificationTypes; track notificationType) {
          <mat-list-option [value]="notificationType.Value"
                           [selected]="isNotificationTypeSelected(notificationType.Value)">
            {{ notificationType.Name }}
          </mat-list-option>
        }
      </mat-selection-list>
    }

    <button mat-raised-button color="primary"
            [disabled]="uiState == uiStateForTemplate.ShowRequestProcessing"
            (click)="saveMyPreferences()">
      @if (uiState == uiStateForTemplate.ShowRequestProcessing) {
        <mat-spinner diameter="32" color="accent"></mat-spinner>
      } @else {
        Save my Notification Preferences
      }
    </button>
  }

</c-container>

