import {Component, inject, Input, OnInit} from '@angular/core';
import {AgGridAngular} from "ag-grid-angular";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {MatButton} from "@angular/material/button";
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {ICharityAdmin} from "../../../interfaces/ICharityAdmin";
import {ColDef, FirstDataRenderedEvent, GridSizeChangedEvent} from "ag-grid-community";
import {MatDialog} from "@angular/material/dialog";
import {AdminUserTables} from "../../../table-definitions/admin-user-tables";
import {ActiveUserService} from "../../../services/active-user.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {UsersService} from "../../../services/users.service";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {AddNewAdminComponent} from "../../dialogs/add-new-admin/add-new-admin.component";
import {AdminInviteType} from "../../../enum/AdminInviteType";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {GameService} from "../../../services/game.service";

@Component({
  selector: 'app-charity-admin-users-view',
  standalone: true,
  imports: [
    AgGridAngular,
    HasPermissionDirective,
    MatButton,
    MatCardContent,
    MatCardHeader,
    MatIcon,
    MatTooltip,
    MatCard,
    MatProgressSpinner
  ],
  templateUrl: './charity-admin-users-view.component.html',
  styleUrl: './charity-admin-users-view.component.css'
})
export class CharityAdminUsersViewComponent implements OnInit {
  private matDialog: MatDialog = inject(MatDialog);
  private adminUserTables: AdminUserTables = inject(AdminUserTables);
  private activeUserService: ActiveUserService = inject(ActiveUserService);
  private snackbarService: SnackbarService = inject(SnackbarService);
  private usersService: UsersService = inject(UsersService);
  private gameService: GameService = inject(GameService);
  public uiState = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;

  @Input() set gameSetter(gameP: IGameQueryResult) {
    this.chosenGame = gameP;
    // if the active game id is falsy, it is a cross game request
    let isCrossGame = !this.gameService.activeGame()?.Id;
    this.charityAdminColDefs = this.adminUserTables.charityAdminUserTableColDefs(this.chosenGame.Id, isCrossGame);
    this.fetchCharityGameAdmins();
  }

  private chosenGame: IGameQueryResult | null = null;
  public charityAdminUsers: ICharityAdmin[] = [];
  public charityAdminColDefs: ColDef[] = [];

  permissionTypes = PermissionTypes;

  ngOnInit(): void {
    this.usersService.getUpdatedAdminUsersBehaviorSubject.subscribe({
      next: (res) => {
        if(res) {
          this.fetchCharityGameAdmins();
        }
      }
    })
  }

  private fetchCharityGameAdmins() {
    this.uiState = UIStateEnum.ShowLoading;

    this.usersService.fetchCharityAdmins(this.activeUserService.activeUser().Id, this.chosenGame?.Id!).subscribe({
        next: (res) => {
          this.charityAdminUsers = res;
          this.uiState = UIStateEnum.ShowData;
        },
        error: () => {
          this.snackbarService.openErrorSnackBar('Failed to retrieve charity admins. Please try again or contact your system admin.');
          this.uiState = UIStateEnum.ShowData;
        }
      }
    )
  }

  onInviteUserClick() {
    let dialogRef = this.matDialog.open(AddNewAdminComponent, {
      data: {
        inviteType: AdminInviteType.CharityAdminInvite,
        game: this.chosenGame
      },
      width: '650px'
    });

    dialogRef.afterClosed().subscribe({
      next:(updateSuccess) => {
        if(updateSuccess) {
          this.fetchCharityGameAdmins();
        }
      }
    })

  }

  gridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }
}
