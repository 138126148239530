import {Component, inject} from '@angular/core';
import {MatTab, MatTabContent, MatTabGroup} from "@angular/material/tabs";
import {ReactiveFormsModule} from "@angular/forms";
import {AgGridAngular} from "ag-grid-angular";
import {AsyncPipe, DatePipe, NgClass} from "@angular/common";
import {MatButton, MatIconButton} from "@angular/material/button";
import {
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
  MatEndDate,
  MatStartDate
} from "@angular/material/datepicker";
import {MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {MatTooltip} from "@angular/material/tooltip";
import {BaseSupportDeskComponent} from "../base-support-desk-search.component";
import {UIStateEnum} from "../../../../enum/UIStateEnum";
import {UsersService} from "../../../../services/users.service";
import {ISupportRequest} from "../../../../interfaces/player/ISupportRequest";
import {DateTime} from "luxon";
import {
  ColComponent,
  ContainerComponent,
  ListGroupDirective,
  ListGroupItemDirective,
  RowComponent
} from "@coreui/angular";
import {FilterableDropdownComponent} from "../../../../components/filterable-dropdown/filterable-dropdown.component";
import {HasPermissionDirective} from "../../../../directives/permissions/has-permission";

@Component({
  selector: 'app-cross-game-support-desk',
  standalone: true,
  imports: [
    MatTabGroup,
    MatTab,
    MatTabContent,
    AgGridAngular,
    AsyncPipe,
    MatButton,
    MatDateRangeInput,
    MatDateRangePicker,
    MatDatepickerToggle,
    MatEndDate,
    MatFormField,
    MatHint,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MatStartDate,
    MatSuffix,
    MatTooltip,
    ReactiveFormsModule,
    NgClass,
    ListGroupDirective,
    ListGroupItemDirective,
    DatePipe,
    ContainerComponent,
    RowComponent,
    ColComponent,
    FilterableDropdownComponent,
    HasPermissionDirective
  ],
  templateUrl: '../shared-support-desk-search.html',
  styleUrl: './cross-game-support-desk-search.component.scss'
})
export class CrossGameSupportDeskSearchComponent extends BaseSupportDeskComponent {
  private userService: UsersService = inject(UsersService);

  override ngOnInit() {
    this.isCrossGameSearch = true;
    this.fromDateControl.setValue(DateTime.now().minus({week: 2}).toString())
    this.toDateControl.setValue(DateTime.now().toString())

    this.fetchAdmins();
    this.getPaginatedResponse(1);
  }

  onRowClicked(paramsP: ISupportRequest) {
    if (!paramsP) {
      this.snackBarService.openErrorSnackBar("Error retrieving data. Please try again.");
      return;
    }

    this.router.navigateByUrl(`cross-game-view-support-request/${paramsP.Id}`);
  }

  fetchAdmins() {
    this.uiState = UIStateEnum.ShowLoading;

    this.userService.getAllAdminsWithHandleEscalatedRequestPermissions().subscribe({
      next: (adminsP) => {
        this.uiState = UIStateEnum.ShowData;
        this.gameAdmins = adminsP;
        this.updateDropDownOptions();
      },
      error: () => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage("Failed to fetch admins");
      },
    });
  }

}
