<mat-toolbar>
  @if (appConfig$ | async; as appConfig) {
    <mat-toolbar-row class="game-banner-toolbar">
      <div>
        <button mat-icon-button (click)="onSideNavToggle()" data-testid="sidenav-button">
          <mat-icon [matBadge]="(assignedSupportRequestsCount$ | async)?.length"
                    matBadgeColor="warn"
                    [matBadgeHidden]="(assignedSupportRequestsCount$ | async)?.length == 0 || (sideNavState$ | async)">
            menu</mat-icon>
        </button>
      </div>

      @if (activeGame$ | async; as activeGame) {

        @if (appConfig && combinedLogo) {
          <img alt="game logo" [src]="combinedLogo">
        }
        <span data-testid="game-banner-game-name">{{ activeGame.Name }} Admin App</span>

        @if (currentEnvironment) {
          <span class="env-banner"
                data-testid="game-banner-current-environment">{{ currentEnvironment }}</span>
        }

        <span class="example-spacer"></span>

        @if (activeGameInstance$ | async; as activeGameInstance) {
          <div class="margin-right-med">
            <div class="banner-label">Next Draw</div>
            <div class="banner-value" data-testid="game-banner-next-draw">{{ endDateCountDown$ | async }}</div>
          </div>
          <div>
            <div class="banner-label">Current Pot</div>
            <div class="banner-value"
                 data-testid="game-banner-current-pot">{{ activeGameInstance.PotTotal | formatCurrency }}
            </div>
          </div>
          <app-user-profile-menu></app-user-profile-menu>
        }

      } @else {
        <h2 data-testid="no-game-header">Causable Admin App</h2>
        <span class="example-spacer"></span>
        <app-user-profile-menu></app-user-profile-menu>
      }
    </mat-toolbar-row>
  }

</mat-toolbar>
