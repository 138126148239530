@if (uiState == uiStateEnumForTemplate.ShowLoading) {
  <c-container>
    <h1 data-testid="charity-loading-message">Loading your charity. Please wait.</h1>
    <mat-spinner class="center-margin"></mat-spinner>
  </c-container>
} @else {
  @if (activeCharity$ | async; as activeCharity) {
    <!--    Insert warning message here if no stripe connect account is available-->
    <c-container class="mt-5">
      <c-row>
        <c-col>
          <h2 data-testid="charity-title">{{ activeCharity.Name }}
            @if (!activeCharity.Active) {
              <span class="inactive"> (inactive)</span>
            }
          </h2>
        </c-col>
      </c-row>
      <c-row>
        <c-col md="3" class="mb-3">
          <div cListGroup>
            <button cListGroupItem
                    data-testid="general-info-tab"
                    (click)="currentScreenState = screenStateEnum.GeneralInfo"
                    [active]="currentScreenState === screenStateEnum.GeneralInfo">
              <div>
                <h5 class="mb-1">General Charity Info</h5>
                <p>view general info on this charity</p>
              </div>
            </button>
          </div>
          <div cListGroup>
            <button cListGroupItem
                    data-testid="charity-qr-settings-tab"
                    (click)="currentScreenState = screenStateEnum.SupportersReport"
                    [active]="currentScreenState === screenStateEnum.SupportersReport">
              <div>
                <h5 class="mb-1">Charity Supporters Report</h5>
                <p>information on the number of supporters to your charity</p>
              </div>
            </button>
          </div>
          <div cListGroup>
            <button cListGroupItem
                    data-testid="charity-qr-settings-tab"
                    (click)="currentScreenState = screenStateEnum.DateRangeReporting"
                    [active]="currentScreenState === screenStateEnum.DateRangeReporting">
              <div>
                <h5 class="mb-1">Date Range Reporting</h5>
                <p>generate reports based off of a specified date range</p>
              </div>
            </button>
          </div>
          <div cListGroup>
            <button cListGroupItem
                    data-testid="charity-qr-settings-tab"
                    (click)="currentScreenState = screenStateEnum.DrawReporting"
                    [active]="currentScreenState === screenStateEnum.DrawReporting">
              <div>
                <h5 class="mb-1">Individual Draw Reporting</h5>
                <p>generate reports based off of a chosen draw</p>
              </div>
            </button>
          </div>
          <div cListGroup>
            <button cListGroupItem
                    data-testid="charity-qr-settings-tab"
                    (click)="currentScreenState = screenStateEnum.QRCodeSettings"
                    [active]="currentScreenState === screenStateEnum.QRCodeSettings">
              <div>
                <h5 class="mb-1">QR Code Settings</h5>
                <p>your sharable qr code and url</p>
              </div>
            </button>
          </div>
        </c-col>
        <c-col>
          @switch (currentScreenState) {
            @case (screenStateEnum.GeneralInfo) {
              <app-charity-general-settings></app-charity-general-settings>
            }
            @case (screenStateEnum.QRCodeSettings) {
              <app-charity-qr-code [gameSetter]="game!"></app-charity-qr-code>
            }
            @case (screenStateEnum.SupportersReport) {
              <app-charity-supporter-details></app-charity-supporter-details>
            }
            @case (screenStateEnum.DateRangeReporting) {
              <app-charity-date-range-reporting></app-charity-date-range-reporting>
            }
            @case (screenStateEnum.DrawReporting) {
              <app-charity-draw-reporting></app-charity-draw-reporting>
            }
            @case (screenStateEnum.StripeConnect) {
              <!--TODO: Implement Stripe Connect here-->
            }
          }
        </c-col>
      </c-row>
    </c-container>
  }
}
