<app-dialog-base>
  <span dialog-title>SendGrid Event Data</span>

  <ng-container dialog-content>
    <div class="flex-row-wrap justify-start gap-normal margin-bottom-med margin-top-med">
      <div class="detail-section">
        <p class="detail-label">Event Type</p>
        <p>{{event.Event}}</p>
      </div>

      <div class="detail-section">
        <div class="detail-label">Email</div>
        <div class="detail-value">{{event.Email}}</div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Subject</div>
        <div class="detail-value" data-testid="sendgrid-event-subject">{{event.Subject}}</div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Player Id</div>
        <div class="detail-value">{{event.PlayerId}}</div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Created On</div>
        <div class="detail-value">{{event.CreatedOn | date}}</div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Game Id</div>
        <div class="detail-value">{{event.GameId}}</div>
      </div>

      <div class="detail-section">
        <div class="detail-label">SendGrid Template</div>
        <div class="detail-value">{{event.SendGridTemplateId}}</div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Event Captured On</div>
        <div class="detail-value">{{event.EventCapturedOn | date}}</div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Ip Address</div>
        <div class="detail-value">{{event.IpAddress}}</div>
      </div>

      <div class="detail-section">
        <div class="detail-label">User Agent</div>
        <div class="detail-value">{{event.UserAgent}}</div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Template Version Id</div>
        <div class="detail-value">{{event.TemplateVersionId}}</div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Reason</div>
        <div class="detail-value">{{event.Reason}}</div>
      </div>
    </div>
  </ng-container>
  <ng-container actions></ng-container>
</app-dialog-base>
