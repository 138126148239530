import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {MatCardModule} from "@angular/material/card";
import {MatButton} from "@angular/material/button";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {
  UpdateBasicPlayerInfoComponent
} from "../../dialogs/update-basic-player-info/update-basic-player-info.component";
import {IDialogGenericData} from "../../../interfaces/IDialogGenericData";
import {
  ConfirmationActionDialogComponent
} from "../../dialogs/confirmation-action-dialog/confirmation-action-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {DialogFunctionService} from "../../../services/dialog-function.service";
import {Router} from "@angular/router";
import {IGamePlayerInputData} from "../../../interfaces/player/IGamePlayerInputData";
import {IGeneralPlayerInfo} from "../../../interfaces/player/IGeneralPlayerInfo";
import {PlayersService} from "../../../services/players.service";
import {DatePipe} from "@angular/common";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-manage-player-general-info',
  standalone: true,
  imports: [
    MatCardModule,
    MatButton,
    MatIcon,
    HasPermissionDirective,
    DatePipe
  ],
  templateUrl: './manage-player-general-info.component.html',
  styleUrl: './manage-player-general-info.component.css'
})
export class ManagePlayerGeneralInfoComponent {
  private matDialog: MatDialog = inject(MatDialog);
  private dialogFunctionService: DialogFunctionService = inject(DialogFunctionService);
  private playerService: PlayersService = inject(PlayersService);
  private router: Router = inject(Router);

  @Output() public getFreshPlayerInfoEvent: EventEmitter<boolean> = new EventEmitter();

  @Input()
  public set gamePlayerData(gamePlayerDataP: IGamePlayerInputData) {
    if (gamePlayerDataP.playerId && gamePlayerDataP.game) {
      this.playerId = gamePlayerDataP.playerId;
      this.playerInfo = gamePlayerDataP.playerInfo;
      this.gameId = gamePlayerDataP.game.Id;
    }
  }

  protected gameId: string = '';
  protected playerId: string = '';
  protected PermissionTypes = PermissionTypes;
  public playerInfo: IGeneralPlayerInfo | undefined = undefined;

  public onUpdateBasicInfoClick() {
    const dialog_ref = this.matDialog.open(UpdateBasicPlayerInfoComponent, {
      data: {
        playerData : this.playerInfo,
        gameId: this.gameId
      },
      width: '650px'
    });
    dialog_ref.afterClosed().subscribe({
      next: (res) => {
        if (res) {
          this.getFreshPlayerInfoEvent.emit(true);
        }
      }
    })
  }

  public onViewPlayerAuditsClick() {
    this.router.navigateByUrl(`/auditing?playerId=${this.playerInfo?.Id!}`);
  }

  public onDeactivatePlayerClick() {
    this.dialogFunctionService.setCallback(() => {
      return this.playerService.deactivatePlayer({
        playerId: this.playerInfo?.Id!,
        gameId: this.gameId
      })
    });

    if (this.playerInfo) {
      let deactivatePlayerDialogData: IDialogGenericData = {
        title: 'Confirm Player Deactivation',
        message: `Please confirm that you would like to deactivate the following player`,
        playerData: {
          firstName: this.playerInfo.FirstName,
          email: this.playerInfo.Email,
          lastName: this.playerInfo.LastName
        },
        dataCyTag: 'confirm-deactivate-player-button',
        successMessage: 'Successfully deactivated player',
        extraDetails: 'Deactivating a player will remove all of their numbers from all games, except any numbers already played for for any active games. The user will no longer have access to any games.'
      };

      const dialogRef = this.matDialog.open(ConfirmationActionDialogComponent, {
        data: deactivatePlayerDialogData,
        width: '650px'
      });

      dialogRef.afterClosed().subscribe(
        {
          next: (updateSuccessful: boolean) => {
            if (updateSuccessful) {
              this.getFreshPlayerInfoEvent.emit(true);
            }
          }
        })
    }
  }

  public onReactivatePlayerClick() {
    this.dialogFunctionService.setCallback(() => {
      return this.playerService.reactivatePlayer({
        playerId: this.playerInfo?.Id!,
        gameId: this.gameId
      })
    });

    if (this.playerInfo) {
      let reactivatePlayerDialogData: IDialogGenericData = {
        title: 'Confirm Player Reactivation',
        message: `Please confirm that you would like to reactivate the following player`,
        playerData: {
          firstName: this.playerInfo.FirstName,
          email: this.playerInfo.Email,
          lastName: this.playerInfo.LastName
        },
        dataCyTag: 'confirm-reactivate-player-button',
        successMessage: 'Successfully reactivated player',
        extraDetails: 'Reactivating a player allows them to log back into any game, while their numbers remain deactivated. You can selectively reactivate numbers per game or strip using the "Reactivate Strip" feature.'
      };

      const dialogRef = this.matDialog.open(ConfirmationActionDialogComponent, {
        data: reactivatePlayerDialogData,
        width: '650px'
      });

      dialogRef.afterClosed().subscribe(
        {
          next: (updateSuccessful: boolean) => {
            if (updateSuccessful) {
              this.getFreshPlayerInfoEvent.emit(true);
            }
          }
        })
    }
  }
}
