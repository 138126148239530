import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, tap} from 'rxjs';
import {APIService} from "./api.service";
import {IDrawNumberRequest} from "../interfaces/IDrawNumberRequest";
import {IDrawNumberResponse} from "../interfaces/IDrawNumberResponse";
import {IGameInstance} from "../interfaces/IGameInstance";
import {IActiveGameInstance} from "../interfaces/IActiveGameInstance";
import {createStore, select, setProp, withProps} from "@ngneat/elf";
import {updateRequestCache, withRequestsCache} from "@ngneat/elf-requests";
import {GameInstanceStatuses} from "../enum/GameInstanceStatusEnum";
import {GameTypeEnum} from "../enum/GameTypeEnum";
import {catchError} from "rxjs/operators";
import {ErrorHandlingService} from "./error-handling.service";
import {IComprehensiveGameInstanceData} from "../interfaces/IComprehensiveGameInstanceData";
import {IUpdateGameInstanceEndedOnCommand} from "../interfaces/IUpdateGameInstanceEndedOnCommand";
import {IActiveGameInstanceWithTotals} from "../interfaces/IActiveGameInstanceWithTotals";
import {IGameInstancePlayerEmailsGroupedByGameInstance} from "../interfaces/reporting/GetAllGameInstancePlayerEmails";

@Injectable({
  providedIn: 'root'
})
export class GameInstanceService {

  public gameInstanceHasEndedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public gameInstanceHasEnded$: Observable<boolean> = this.gameInstanceHasEndedSubject.asObservable();

  private defaultActiveGameInstance: IActiveGameInstance = {
    GameId: '',
    Name: '',
    Id: '',
    PotTotal: 0,
    State: GameInstanceStatuses.Active,
    Type: GameTypeEnum.GoldRush,
    EndedOn: new Date(),
    StartedOn: new Date()
  };

  private gameInstanceStore = createStore(
    {name: 'game-instance'},
    withProps<{ activeGameInstance: IActiveGameInstance }>({
      activeGameInstance: this.defaultActiveGameInstance
    }),
    withRequestsCache<'game-instance'>()
  );

  constructor(private apiService: APIService,
              private errorHandlingService: ErrorHandlingService) {
  }

  public selectActiveGameInstance(): Observable<IActiveGameInstance> {
    return this.gameInstanceStore.pipe(select((state) => state.activeGameInstance));
  }

  public activeGameInstance(): IActiveGameInstance {
    return this.gameInstanceStore.query(state => state.activeGameInstance);
  }

  public activeGameInstanceId(): string {
    return this.gameInstanceStore.query(state => state.activeGameInstance.Id);
  }

  public clearActiveGameInstance() {
    this.gameInstanceStore.reset();
  }

  public drawWinner(drawRequestP: IDrawNumberRequest): Observable<IDrawNumberResponse> {
    return this.apiService.MakePostRequest<IDrawNumberResponse>('game-instance/draw-winner', drawRequestP);
  }

  public getGameInstancesByGameId(gameIdP: string, adminIdP: string): Observable<IGameInstance[]> {
    return this.apiService.MakeGetRequest<IGameInstance[]>(`game-instance/for-game/${gameIdP}/${adminIdP}`)
      .pipe(catchError((error) => {
        this.errorHandlingService.displayPageLevelErrorMessage(error);
        return [];
      }));
  }

  public getGameInstancePlayerEmails(gameIdP: string, adminIdP: string): Observable<IGameInstancePlayerEmailsGroupedByGameInstance[]> {
    return this.apiService.MakeGetRequest<IGameInstancePlayerEmailsGroupedByGameInstance[]>(`game-instance/player-emails/${gameIdP}/${adminIdP}`)
      .pipe(catchError((error) => {
        this.errorHandlingService.displayPageLevelErrorMessage(error);
        return [];
      }));
  }

  public activeGameInstanceByGame(gameIdP: string, adminIdP: string): Observable<IActiveGameInstance> {
    return this.apiService.MakeGetRequest<IActiveGameInstance>(`game-instance/active-for-game/${gameIdP}/${adminIdP}`).pipe(tap((activeGameInstance) => this.gameInstanceStore.update(
      updateRequestCache('game-instance', {value: 'full'}),
      setProp('activeGameInstance', activeGameInstance))));
  }

  public fetchActiveGameInstanceByGameWithTotals(gameIdP: string): Observable<IActiveGameInstanceWithTotals> {
    return this.apiService.MakeGetRequest<IActiveGameInstanceWithTotals>(`game-instance/active-for-game-with-totals/${gameIdP}`);
  }

  public fetchComprehensiveGameInstance(gameInstanceIdP: string): Observable<IComprehensiveGameInstanceData> {
    return this.apiService.MakeGetRequest<IComprehensiveGameInstanceData>(`game-instance/${gameInstanceIdP}`);
  }

  public updateGameInstanceEndDate(updateCommandP: IUpdateGameInstanceEndedOnCommand): Observable<IComprehensiveGameInstanceData> {
    return this.apiService.MakePutRequest<IComprehensiveGameInstanceData>(`game-instance/ended-on`, updateCommandP).pipe(tap(() => {
      location.reload();
    }));
  }

}
