import {Component} from '@angular/core';
import {AsyncPipe} from "@angular/common";
import {
  DisplayActiveGameInstanceComponent
} from "../../components/display-active-game-instance/display-active-game-instance.component";
import {HasPermissionDirective} from "../../directives/permissions/has-permission";
import {MatIcon} from "@angular/material/icon";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatMiniFabButton} from "@angular/material/button";
import {MatTooltip} from "@angular/material/tooltip";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {IActiveGameInstance} from "../../interfaces/IActiveGameInstance";
import {GameInstanceService} from "../../services/game-instance.service";
import {MatDialog} from "@angular/material/dialog";
import {EndGameInstanceComponent} from "../../components/dialogs/end-game-instance/end-game-instance.component";
import {EmailBlastComponent} from "../../components/dialogs/email-blast/email-blast.component";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {
  UserHasCausableAdminAccessDirective
} from "../../directives/permissions/user-has-causable-admin-access.directive";
import {ColComponent, ContainerComponent, RowComponent} from "@coreui/angular";
import {MatCard, MatCardContent} from "@angular/material/card";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {FormsModule} from "@angular/forms";
import {ActiveUserService} from "../../services/active-user.service";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {SnackbarService} from "../../services/snackbar.service";
import {ChooseYourGameComponent} from "../../components/choose-your-game/choose-your-game.component";

@Component({
  selector: 'app-cross-game-active-draw-landing',
  standalone: true,
  imports: [
    AsyncPipe,
    DisplayActiveGameInstanceComponent,
    HasPermissionDirective,
    MatIcon,
    MatMenu,
    MatMenuItem,
    MatMiniFabButton,
    MatTooltip,
    UserHasCausableAdminAccessDirective,
    MatMenuTrigger,
    RowComponent,
    MatCard,
    ColComponent,
    MatCardContent,
    MatFormField,
    MatSelect,
    MatOption,
    ContainerComponent,
    FormsModule,
    MatLabel,
    ChooseYourGameComponent
  ],
  templateUrl: './cross-game-active-draw-landing.component.html',
  styleUrl: './cross-game-active-draw-landing.component.css'
})
export class CrossGameActiveDrawLandingComponent {
  protected readonly permissionTypes = PermissionTypes;
  public chosenGame: IGameQueryResult | null = null;
  public chosenGamesActiveDraw: IActiveGameInstance | null = null;
  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public uiStateForTemplate = UIStateEnum;

  constructor(private gameInstanceService: GameInstanceService,
              private matDialog: MatDialog,
              private activeUserService: ActiveUserService,
              private snackBarService: SnackbarService) {
  }

  onGameChange(chosenGame: IGameQueryResult) {
    this.chosenGamesActiveDraw = null;
    this.chosenGame = chosenGame;
    this.uiState = UIStateEnum.ShowLoading;

    this.gameInstanceService.activeGameInstanceByGame(chosenGame.Id, this.activeUserService.activeUser().Id).subscribe({
      next: (activeGameInstanceP: IActiveGameInstance) => {
        this.chosenGamesActiveDraw = activeGameInstanceP;
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        if (err?.Error?.status === 404) {
          this.uiState = UIStateEnum.ShowData;
          this.chosenGamesActiveDraw = null;
          this.snackBarService.openErrorSnackBar('Looks like there is no active game instance for this game');
        } else {
          this.uiState = UIStateEnum.ShowData;
          this.snackBarService.openErrorSnackBar('There was an error retrieving the active game instance for this game. Please try again or contact a system admin');
        }
      }
    })
  }

  openEndGameInstance() {
    this.matDialog.open(EndGameInstanceComponent, {
      data: this.chosenGamesActiveDraw,
      disableClose: true
    })
  }

  openEmailBlast() {
    this.matDialog.open(EmailBlastComponent);
  }

}
