<app-component-level-error-message [errorMessage]="errorMessage"></app-component-level-error-message>
<div class="ticket-type-header margin-top-large">
  <div class="justify-space-between flex-row align-start">
    <div>
      <div class="section-header">Lottery License Report</div>
      <div>Date Range:</div>
      {{ dateRanges.StartDate | date: 'MMM d, y HH:mm' }} -
      {{ dateRanges.EndDate | date: 'MMM d, y HH:mm' }}
    </div>
    <div>
      <button mat-raised-button color="primary" (click)="generatePDF()"
              data-testid="comprehensive-report-generate-pdf-button">Generate
        PDF
      </button>
    </div>
  </div>

</div>

<h3>Lottery License Report</h3>
@if (uiState === uiStateForTemplate.ShowLoading) {
  <app-loading-card-content></app-loading-card-content>
} @else if (uiState === uiStateForTemplate.ShowData) {

  <div class="margin-left-med flex-row-wrap justify-space-between align-start gap-normal">
    <div class="flex-report-item bottom-border">
      <div class="report-label">Game Name</div>
      <div class="report-value" data-testid="lottery-licence-report-game-name">{{ lotteryLicenseReport.GameName }}</div>
    </div>
    <div class="flex-report-item bottom-border">
      <div class="report-label">Game Type</div>
      <div class="report-value"
           data-testid="lottery-licence-report-game-type">{{ lotteryLicenseReport.GameType | gameType }}
      </div>
    </div>
    <div class="flex-report-item">
      <div class="report-label">Tickets Sold</div>
      <div class="report-value"
           data-testid="lottery-licence-report-tickets-sold">{{ lotteryLicenseReport.TicketsSold }}
      </div>
    </div>
    <div class="flex-report-item bottom-border">
      <div class="report-label">Total Gross Sales</div>
      <div class="report-value"
           data-testid="lottery-licence-report-total-gross-sales">{{ lotteryLicenseReport.TotalGrossSales | formatCurrency }}
      </div>
    </div>
    <div class="flex-report-item bottom-border">
      <div class="report-label">Total Expenses</div>
      <div class="report-value"
           data-testid="lottery-licence-report-total-expenses">{{ lotteryLicenseReport.TotalExpenses | formatCurrency }}
      </div>
    </div>
    <div class="flex-report-item bottom-border">
      <div class="report-label">Net Proceeds Raised for Charity</div>
      <div class="report-value"
           data-testid="lottery-licence-report-net-proceeds">{{ lotteryLicenseReport.NetProceedsForCharity | formatCurrency }}
      </div>
    </div>
    <div class="flex-report-item">
      <div class="flex-row justify-space-between align-center">
        <div class="report-label">Total Amount Awarded To Winners <small>(*includes any rollover funds in first winning
          pot of this date range.)</small></div>
        <div
          class="report-value"
          data-testid="lottery-licence-report-net-proceeds">{{ lotteryLicenseReport.TotalPrizesAwarded | formatCurrency }}
        </div>
      </div>
    </div>
    <div class="flex-report-item">
      <div class="flex-row justify-space-between align-center">
        <div class="report-label">Total Amount Allocated to Player Winnings <small>(*does not include rollover amount.
          This is 50% of gross sales in this date range)</small>
        </div>
        <div class="report-value"
             data-testid="lottery-licence-report-net-proceeds">{{ lotteryLicenseReport.AmountAllocatedToPlayerWinnings | formatCurrency }}
        </div>
      </div>
    </div>
  </div>

  <h3 class="winner-subtitle">Licence Number(s)</h3>
  <div>
    <mat-list data-testid="lottery-license-report-licence-list">
      @for (number of lotteryLicenseReport.LicenceNumbers; track number) {
        <mat-list-item class="padding-bottom-med padding-top-med">
          <span matListItemTitle>{{ number.LicenceNumber }}</span>
          <span matListItemLine>Status: {{ number.Active ? 'Active' : 'Expired' }}</span>
          <span matListItemLine>Start Date: {{ number.StartDate | date: 'yyyy-MM-dd' }}</span>
          <span
            matListItemLine>End Date: {{ number.EndDate ? (number.EndDate| date: 'yyyy-MM-dd') : 'No End Date' }}</span>
        </mat-list-item>
      }
    </mat-list>
  </div>

  <h3 class="winner-subtitle">Winners</h3>
  <div>
    <mat-list data-testid="lottery-license-report-winner-list">
      @for (winner of lotteryLicenseReport.Winners; track winner) {
        <mat-list-item class="padding-bottom-med padding-top-med">
          <span matListItemTitle>{{ winner.FirstName }} {{ winner.LastName }}</span>
          <span matListItemLine>Phone: {{ winner.Phone }}</span>
          <span matListItemLine>Winnings: {{ winner.PlayerWinnings | formatCurrency }}</span>
          <span matListItemLine>Draw date: {{ winner.EndedOn | date: 'yyyy-MM-dd' }}</span>
        </mat-list-item>
      }
    </mat-list>
  </div>
}

