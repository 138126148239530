import {Injectable} from "@angular/core";
import {createStore} from "@ngneat/elf";
import {withRequestsCache} from "@ngneat/elf-requests";
import {withActiveId, withEntities} from "@ngneat/elf-entities";
import {ICountry} from "../interfaces/ICountry";
import {APIService} from "./api.service";
import {map, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  private countryStore = createStore(
    {name: 'country-state'},
    withEntities<ICountry, 'Id'>({idKey: 'Id'}),
    withRequestsCache<'countries'>()
  )

  constructor(public apiService: APIService) {
  }

  public allCountries(): Observable<ICountry[]> {
    return this.apiService.MakeGetRequest<ICountry[]>(`countries`);
  }
}
