import {Component, inject, Input, ViewChild} from '@angular/core';
import {MatCardModule} from "@angular/material/card";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {CdkCopyToClipboard} from "@angular/cdk/clipboard";
import {QRCodeComponent, QRCodeModule} from "angularx-qrcode";
import {MatButton} from "@angular/material/button";
import {ICharityWithContributionTotals} from "../../../interfaces/charity/ICharityWithContributionTotals";
import {CharityService} from "../../../services/charity.service";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";

@Component({
  selector: 'app-charity-qr-code',
  standalone: true,
  imports: [
    MatCardModule,
    MatIcon,
    MatTooltip,
    CdkCopyToClipboard,
    QRCodeModule,
    MatButton
  ],
  templateUrl: './charity-qr-code.component.html',
  styleUrl: './charity-qr-code.component.css'
})
export class CharityQrCodeComponent {
  private charityService: CharityService = inject(CharityService);

  @ViewChild('qrcode', {static: false}) qrcode!: QRCodeComponent;

  @Input() set gameSetter(gameP: IGameQueryResult) {
    this.charityQrUrl = this.charityService.generateQrCodeUrl(this.charity!.Id, gameP);
  }

  protected charity: ICharityWithContributionTotals | undefined = this.charityService.activeCharity();

  protected charityQrUrl: string = '';

  downloadQRCode(charityNameP: string): void {
    // Access the canvas element inside the qrcode component
    const canvas = this.qrcode.qrcElement.nativeElement.querySelector('canvas') as HTMLCanvasElement;
    let cleanedCharityName = charityNameP
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-'); // Replace spaces with hyphens

    if (canvas) {
      // Convert the canvas to a data URL
      const dataUrl = canvas.toDataURL('image/png');

      // Trigger download
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = `${cleanedCharityName}-qrcode.png`;
      link.click();
    }
  }

}
