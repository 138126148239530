import {Directive, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {IPlayer} from "../../interfaces/player/IPlayer";
import {PlayersService} from "../../services/players.service";
import {PhoneFormatPipe} from "../../pipes/phone-format.pipe";
import {Router} from "@angular/router";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {IPlayerSearchParams} from "../../interfaces/player/IPlayerSearchParams";
import {UIStateEnum} from "../../enum/UIStateEnum";

@Directive()
export abstract class BasePlayerSearchComponent implements OnInit {
  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public uiStateEnumForTemplate = UIStateEnum;
  public phoneNumberControl: FormControl<string | null> = new FormControl<string | null>('');
  private guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  public playerIdControl: FormControl<string | null> = new FormControl('', Validators.pattern(this.guidRegex));

  public playerSearchForm = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    email: new FormControl(''),
    phoneNumber: this.phoneNumberControl,
    playerId: this.playerIdControl
  });

  public players: IPlayer[] = [];
  public columnDefs: ColDef[] = [
    {headerName: 'Id', field: 'Id', resizable: true, sortable: true},
    {headerName: 'First Name', field: 'FirstName', resizable: true, sortable: true},
    {headerName: 'Last Name', field: 'LastName', resizable: true, sortable: true},
    {headerName: 'Email', field: 'Email', resizable: true, sortable: true},
    {
      headerName: 'Phone',
      field: 'Phone',
      valueFormatter: params => this.phoneFormatPipe.transform(params.value),
      resizable: true,
      sortable: true
    },
  ];

  private gridApi!: GridApi<IPlayer>;

  constructor(
    protected playerService: PlayersService,
    protected phoneFormatPipe: PhoneFormatPipe,
    protected errorHandlingService: ErrorHandlingService,
    protected router: Router
  ) {
    this.phoneNumberControl.valueChanges.subscribe((value: string | null) => {
      if (value) {
        const formattedValue = this.phoneFormatPipe.transform(value);
        this.phoneNumberControl.setValue(formattedValue, {emitEvent: false});
      }
    });
  }

  ngOnInit(): void {
    this.retrieveUsersWithSearchParams(this.getSearchParams());
  }

  private retrieveUsersWithSearchParams(paramsP?: IPlayerSearchParams) {
    this.playerService.getPlayersBySearchParams(paramsP, this.isGlobalSearch()).subscribe({
      next: res => this.players = res,
      error: error => this.errorHandlingService.displayPageLevelErrorMessage(error)
    });
  }

  onResetClick() {
    this.playerSearchForm.reset();
    this.retrieveUsersWithSearchParams(this.getSearchParams());
  }

  onFormSubmit() {
    if (!this.playerSearchForm.valid) {
      this.playerSearchForm.markAllAsTouched();
      return;
    }

    this.retrieveUsersWithSearchParams(this.getSearchParams());
  }

  onGridReady(params: GridReadyEvent<IPlayer>) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit({defaultMinWidth: 100});
  }

  onRowSelected(chosenPlayerP: any) {
    let player: IPlayer = chosenPlayerP.data;
    this.router.navigateByUrl(this.getPlayerManagementUrl(player.Id));
  }

  protected abstract getSearchParams(): IPlayerSearchParams | undefined;

  protected abstract isGlobalSearch(): boolean;

  protected abstract getPlayerManagementUrl(playerId: string): string;
}
