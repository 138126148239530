import {Component} from '@angular/core';
import {
  ColComponent,
  ContainerComponent,
  ListGroupDirective,
  ListGroupItemDirective,
  RowComponent
} from "@coreui/angular";
import {DatePipe, NgClass} from "@angular/common";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatCardModule} from "@angular/material/card";
import {MatTableModule} from "@angular/material/table";
import {MatTooltip} from "@angular/material/tooltip";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {ChooseYourGameComponent} from "../../../components/choose-your-game/choose-your-game.component";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatListOption, MatSelectionList} from "@angular/material/list";
import {UserDetailsComponent} from "../user-details.component";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {DialogFunctionService} from "../../../services/dialog-function.service";
import {PermissionsService} from "../../../services/permissions.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {UsersService} from "../../../services/users.service";

@Component({
  selector: 'app-cross-game-admin-user-details',
  standalone: true,
  imports: [
    MatIcon,
    ContainerComponent,
    MatButton,
    RowComponent,
    ColComponent,
    ListGroupDirective,
    ListGroupItemDirective,
    DatePipe,
    NgClass,
    MatCardModule,
    MatTableModule,
    MatIconButton,
    MatTooltip,
    HasPermissionDirective,
    ChooseYourGameComponent,
    MatProgressSpinner,
    MatSelectionList,
    MatListOption
  ],
  templateUrl: '../user-details.component.html',
  styleUrl: './cross-game-admin-user-details.component.scss'
})
export class CrossGameAdminUserDetailsComponent extends UserDetailsComponent {

  override ngOnInit() {
    this.enableChoosingGame = true;
    super.ngOnInit();
  }

  onGameChange(gameP: IGameQueryResult) {
    this.selectedRoles = [];
    this.chosenGame = gameP;
    this.updateUiForChosenGame();
  }

  onBackToUsersClick() {
    this.router.navigateByUrl(`manage-cross-game-users`);
  }

}
