<mat-nav-list data-testid="top-level-side-nav-container">
  @if ((userIsLoggedIn$ | async)) {
    @if ((activeUser$ | async); as activeUser) {
      @if (activeUser.CausableAdmin || (activeUser.CrossGamePermissions && activeUser.CrossGamePermissions.length > 0)) {
        <app-cross-game-admin-side-nav></app-cross-game-admin-side-nav>
      }@else {
        <app-game-admin-side-nav></app-game-admin-side-nav>
      }
    }
  } @else {
    <mat-list-item routerLink="/login">
      <mat-icon matListItemIcon>login</mat-icon>
      <div matListItemLine data-testid="sidenav-login">Login</div>
    </mat-list-item>
  }
</mat-nav-list>
