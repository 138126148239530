import {Directive} from "@angular/core";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {ManageUsersScreenUIState} from "../../enum/ManageUsersScreenUIState";


@Directive()
export abstract class BaseManageUsers {
  public permissionTypes = PermissionTypes;
  public chosenGame: IGameQueryResult | null = null;
  public enableGameSearch: boolean = false;
  protected manageUsersScreenUIState: ManageUsersScreenUIState = ManageUsersScreenUIState.GameAdmins;
  protected readonly manageUsersScreenUIStateForTemplate = ManageUsersScreenUIState;


  onGameChange(chosenGame: IGameQueryResult) {
    this.chosenGame = chosenGame;
  }

}
